import {
  computed, onMounted, onUnmounted, ref
} from '@vue/composition-api'
import throttle from 'lodash-es/throttle';

export function isInViewport(el: HTMLElement): boolean {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)

  );
}

export function useBreakpoints() {
  const windowWidth = ref(window.innerWidth)

  const onWidthChange = () => windowWidth.value = window.innerWidth
  onMounted(() => window.addEventListener('resize', throttle(onWidthChange)))
  onUnmounted(() => window.removeEventListener('resize', onWidthChange))

  const type = computed(() => {
    if (windowWidth.value < 576) return 'sm'
    if (windowWidth.value > 576 && windowWidth.value < 1200) return 'md'
    if (windowWidth.value > 1199) return 'lg'
  })

  const width = computed(() => windowWidth.value)

  const isSm = computed(() => type.value === 'sm')

  return { width, type, isSm }
}

export function iOS(): boolean {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 && !window.MSStream)
}

export function isAndroid(): boolean {
  // return navigator.userAgent.match(/Android/i)
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('android') > -1;
}
