<template>
  <button
    class="btn btn-loading"
    :class="btnClass"
    :type="type"
    :disabled="disabled || loading"
    @click="clicked"
    :id="idTarget"
  >
    <span
      class="btn-text"
      :class="{
        'd-flex align-items-center justify-content-center': !!icon,
        'text-uppercase': textUppercase,
      }"
    >
      <span v-if="icon && !iconAppend">
        <pt-icon
          :icon="icon"
          :class="{ 'mr-10': !onlyIcon && !marginNone}"
          :height="iconHeight"
          :width="iconWidth"
          :variant="iconStyle"
        />
      </span>
      <slot v-if="!onlyIcon">
        {{ $t(btnName) }}
      </slot>
      <span v-if="icon && iconAppend">
        <pt-icon
          :icon="icon"
          :class="{ 'ml-10': !onlyIcon && !marginNone}"
          :height="iconHeight"
          :width="iconWidth"
          :variant="iconStyle"
        />
      </span>
    </span>
    <b-spinner class="btn-spinner" small label="Spinning"></b-spinner>
    <b-tooltip
      :target="idTarget"
      triggers="hover"
      v-if="tooltipText"
      :placement="tooltipPlacement"
    >
      {{ tooltipText }}
    </b-tooltip>
  </button>
</template>

<script>
import { getRandomInt } from 'common/utils/randomFunctions.ts';
import { useBreakpoints } from 'common/utils/viewPort.ts';
import PtIcon from '../icon/PtIcon.vue';

export default {
  name: 'PtButton',
  components: { PtIcon },
  data() {
    return {
      idTarget: `BtnLoadingSpinner${getRandomInt(0, 500)}`,
    };
  },
  props: {
    btnName: {
      type: String,
      default: 'btnApp.save',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    type: {
      type: String,
      default: 'submit',
    },
    white: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
    iconStyle: {
      type: String,
      default: 'svg-light',
    },
    iconHeight: {
      type: String,
      default: '12px',
    },
    iconWidth: {
      type: String,
      default: '12px',
    },
    onlyIcon: Boolean,
    tooltipText: {
      type: String,
    },
    block: Boolean,
    blockSm: Boolean,
    lg: Boolean,
    sm: Boolean,
    focus: Boolean,
    iconAppend: Boolean,
    flat: Boolean,
    round: Boolean,
    tooltipPlacement: {
      type: String,
      default: 'top',
    },
    textUppercase: {
      type: Boolean,
    },
    contentCenter: {
      type: Boolean,
    },
    fixedMd: {
      type: Boolean,
    },
    xl: {
      type: Boolean,
    },
    fixedXl: {
      type: Boolean,
    },
    marginNone: {
      type: Boolean,
    },
  },
  computed: {
    blockComp() {
      return this.block || (this.blockSm && this.isSm);
    },
    btnClass() {
      const btn = this.outline
        ? `btn-outline-${this.variant}`
        : `btn-${this.variant}`;
      return [
        { loading: this.loading && !this.blockComp },
        { 'loading-block': this.loading && this.blockComp },
        btn,
        { 'bg-white': this.white },
        { 'btn-only-icon': this.onlyIcon },
        { 'btn-block': this.blockComp },
        { 'btn-lg': this.lg },
        { 'btn-sm': this.sm },
        { 'btn-focus': this.focus },
        { 'text-light': this.variant === 'warning' && !this.outline },
        { 'p-0 border-none': this.flat },
        { 'btn-round': this.round },
        {
          'd-flex align-items-center justify-content-center': this
            .contentCenter,
        },
        { 'btn-fixed-md': this.fixedMd },
        { 'btn-fixed-xl': this.fixedXl },
        { 'btn-xl': this.xl },
      ];
    },
  },
  methods: {
    clicked() {
      if (!this.loading || !this.disabled) {
        this.$emit('click');
      }
    },
  },
  setup() {
    const { isSm } = useBreakpoints();

    return { isSm };
  },
};
</script>

<style scoped>
.btn-focus {
  -webkit-box-shadow: 0 0 0 4px #c8e3ff !important;
  box-shadow: 0 0 0 4px #c8e3ff !important;
  border: 1px solid var(--primary);
}
.btn-round {
  border-radius: 50%;
}
.btn-xl {
  height: 60px;
  font-size: 18px;
}
.btn-fixed-md {
  width: 36px;
  height: 36px;
  padding: 0;
}
.btn-fixed-xl {
  width: 60px;
  height: 60px;
  padding: 23px;
}
</style>
