import {
  ActionTree, GetterTree, Module, MutationTree
} from 'vuex';
import SharingService from '@/services/passTemplate/sharingService.ts';
import sharingOptDefault from '@/store/modules/sharingOpt/initialState.ts';
import { CommonConfine, SharingOptions } from '@/models/confines/SharingOptions.ts';
import CommonConfineClass from '@/models/confines/CommonConfineClass.ts';

export interface SharingOptState {
  loading: boolean;
  sharingOpt: SharingOptions;
  currentLanguage: string;
}

export enum actionTypes {
  GET_SHARING_OP = '[sharingOpt] getSharingOpt',
  SAVE_SHARING_OP = '[sharingOpt] saveSharingOpt',
}

export enum mutationTypes {
  SET_SHARING_OP = '[sharingOpt] setSharingOpt',
  SET_LOADING = '[sharingOpt] setLoading',
  SET_LANGUAGE = '[sharingOpt] setLang',
}

const actions: ActionTree<SharingOptState, string> = {
  async [actionTypes.GET_SHARING_OP]({ commit, rootGetters, state }, templateId = rootGetters['company/currentTemplateId']) {
    commit(mutationTypes.SET_LOADING, true)
    const data = await SharingService.getSharingOptions();
    commit(mutationTypes.SET_SHARING_OP, data);
    commit(mutationTypes.SET_LANGUAGE, data.languages[0]);
    commit(mutationTypes.SET_LOADING, false)
  },

  async [actionTypes.SAVE_SHARING_OP]({ commit, rootGetters, state }, sharingUpdate: CommonConfine) {
    const templateId = rootGetters['company/currentTemplateId'] || '5fe2f17a552fd121ed3ac553'
    const sharingOptions = {
      ...state.sharingOpt,
      ...sharingUpdate
    }
    const data = await SharingService.saveSharingOptions(templateId, sharingOptions);
    commit(mutationTypes.SET_SHARING_OP, {
      ...state.sharingOpt,
      ...data,
    });
  },
};

const mutations: MutationTree<SharingOptState> = {
  [mutationTypes.SET_SHARING_OP](state, payload: SharingOptions) {
    state.sharingOpt = {
      ...payload,
      alreadyInstalledCard: new CommonConfineClass(payload.alreadyInstalledCard),
      deletedCard: new CommonConfineClass(payload.deletedCard)
    };
  },

  [mutationTypes.SET_LOADING](state, payload: boolean) {
    state.loading = payload;
  },
  [mutationTypes.SET_LANGUAGE](state, payload: string) {
    state.currentLanguage = payload;
  },
};

const getters: GetterTree<SharingOptState, string> = {

}
const state: SharingOptState = {
  loading: true,
  sharingOpt: sharingOptDefault,
  currentLanguage: ''
};

export default {
  state,
  actions,
  mutations,
  getters
} as Module<SharingOptState, string>;
