export enum languagesEnum {
  RU = 'ru',
  EN = 'en',
  UA = 'ua',
  ES = 'es',
  SK = 'sk',
  DE = 'de',
  FR = 'fr',
  IT = 'it',
  KA = 'ka',
  PT = 'pt',
  SR = 'sr',
}

export type langAllowed = 'ru' | 'en'

export type publicLangAllowed = 'ru' | 'en' | 'ua' | 'es' | 'sk' | 'de' | 'fr' | 'it' | 'ka' | 'pt' | 'sr'

export interface LanguageRoot {
  lang: languagesEnum;
  name: string;
  link: string;
  privacyLink: string;
  termLink: string;
}

export const languages: { value: languagesEnum; label: string }[] = [
  { value: languagesEnum.RU, label: 'Русский' },
  { value: languagesEnum.EN, label: 'English' },
];

export const createLanguage = (lang: publicLangAllowed, name = '') => ({
  lang,
  name
})
