<template>
  <div class="d-flex justify-content-center mt-20 text-14">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'bottomLinks',
};
</script>

<style scoped></style>
