import { Module } from 'vuex';
import { PlanAdmin, PlansFiltersParams } from '@/models/plans/types.ts';
import PlansAdminService from '@/services/plans/PlansAdminService.ts';

interface RootState {
  version: string;
}

interface PlansState {
  plans: PlanAdmin[];
  loading: boolean;
  currentPlan: PlanAdmin | null;
  params: PlansFiltersParams;
  counts: number;
}

export default {
  namespaced: true,
  actions: {
    async updateParams({ commit, dispatch }, payload: PlansFiltersParams) {
      commit('SET_PARAMS', payload);
      dispatch('getPlans');
    },
  },
  mutations: {
    SET_LOADING(state: PlansState, loading: boolean) {
      state.loading = loading;
    },
    SET_CURRENT_PLAN(state: PlansState, plan: PlanAdmin) {
      state.currentPlan = plan;
    },
    SET_PLANS(state: PlansState, plans: PlanAdmin[]) {
      state.plans = plans;
    },
    SET_PARAMS(state: PlansState, params: PlansFiltersParams) {
      state.params = { ...state.params, ...params };
    },
    SET_COUNT(state: PlansState, counts: number) {
      state.counts = counts;
    },
  },
  state: {
    plans: [],
    loading: false,
    currentPlan: null,
    params: {
      page: 1,
      perPage: 25,
      search: '',
    },
    counts: 0,
  },
} as Module<PlansState, RootState>;
