import { RouteConfig } from 'vue-router';

export default {
  path: 'design',
  name: 'DesignCard',
  meta: {
    requiresAuth: true,
    pageTitle: 'Common.CardDesign',
    defaultTitle: false,
  },
  component: {
    components: {
      WrapCompanyLoading: () => import('@/components/WrapCompanyLoading.vue'),
      DesignCardIndex: () =>
        import(
          /* webpackChunkName: "design" */ '@/views/design/DesignCardIndex.vue'
        ),
    },
    render: (h) => h('WrapCompanyLoading', [h('DesignCardIndex')]),
  },
  children: [],
} as RouteConfig;
