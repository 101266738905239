import ApiWrap from '@/services/ApiWrap.ts';
import { IBackendResponse } from '@/services/types.ts';
import { PublicForm } from '@/models/form/PublicForm.ts';
import {
  RequestCardEnrichment,
  RecoveryCard,
  RecoveryCardSms,
  ResponseCheckCardCd,
  ResponseCheckCardCdCpt,
  ResponseCreateCard,
  ResponseSendCode,
  ResponseValues,
} from '@/services/publicForm/types.ts';
import { useErrors } from '@/composition/errorsComposition';
import { callCaptchaError } from '@/helpers/captchaError.ts';

const { handlerErrorsAxiosPromise, handlerErrorsAxiosReturn } = useErrors();

class PublicFormService extends ApiWrap {
  private static get templateId(): string {
    return localStorage.getItem('templateId') ?? '';
  }

  private static get insertHeaders() {
    const templateId = localStorage.getItem('templateId');
    return {
      errorSuppression: true,
      noAuthorization: true,
      headers: {
        'X-Templateid': templateId,
      },
    };
  }

  /**
   * Возвращает данные для формы регистрации.
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/116752392/getPublicForm
   * @param templateId
   */
  static async getPublicForm(templateId: string): Promise<PublicForm> {
    const { data } = await this.post<
      IBackendResponse<PublicForm>,
      { templateId: string }
    >('/form/getPublicForm', { templateId }, this.insertHeaders).then(
      (res) => res.data
    );
    return data;
  }

  /**
   * Позволяет отправить код для проверки email в форме.
   * Запрос должен отправляться с url /form-registration/templateId.
   * Отправка на почту
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/290193409/sendCode
   * @param recaptchaToken
   * @param email
   * @param templateId
   */
  static async sendCodeEmail(
    recaptchaToken: string,
    email: string,
    templateId: string,
    version = 'v3'
  ): Promise<ResponseSendCode> {
    const { data } = await this.post<
      IBackendResponse<ResponseSendCode>,
      {
        recaptchaToken: string;
        email: string;
        templateId: string;
        version: string;
      }
    >(
      '/form/sendCode',
      { recaptchaToken, email, templateId, version },
      this.insertHeaders
    )
      .then((res) => res.data)
      .catch(handlerErrorsAxiosPromise);
    return data;
  }

  /**
   * Позволяет отправить код для проверки номера телефона в форме.
   * Запрос должен отправляться с url /form-registration/templateId.
   * Отправка на телефон
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/290193409/sendCode
   * @param recaptchaToken
   * @param phone
   * @param templateId
   * @return {Promise<ResponseSendCode>}
   */
  static async sendCodeSms(
    recaptchaToken: string,
    phone: string,
    templateId: string,
    version = 'v3'
  ): Promise<ResponseSendCode> {
    const data = await this.post<
      IBackendResponse<ResponseSendCode>,
      {
        recaptchaToken: string;
        phone: string;
        templateId: string;
        version: string;
      }
    >(
      '/form/sendCode',
      { recaptchaToken, phone, templateId, version },
      this.insertHeaders
    ).then((res) => res.data.data);
    return data;
  }

  /**
   * Проверка введенного с формы кода и поиск карты по email.
   * Запрос должен отправляться с url /form-registration/templateId
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/291962898/CheckCardCd
   * @param code
   * @param email
   * @param templateId
   * @return {Promise<ResponseCheckCardCd>}
   */
  static async checkCardCdEmail(
    code: string,
    email: string,
    templateId: string
  ): Promise<ResponseCheckCardCd> {
    const { data } = await this.post<
      IBackendResponse<ResponseCheckCardCd>,
      { code: string; email: string; templateId: string }
    >(
      '/form/checkCardCd',
      { code, email, templateId },
      this.insertHeaders
    ).then((res) => res.data);
    return data;
  }

  /**
   * Проверка введенного с формы кода и поиск карты по phone.
   * Запрос должен отправляться с url /form-registration/templateId
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/291962898/CheckCardCd
   * @param code
   * @param phone
   * @param templateId
   * @return {Promise<ResponseCheckCardCd>}
   */
  static async checkCardCdPhone(
    code: string,
    phone: string,
    templateId: string
  ): Promise<ResponseCheckCardCd> {
    const { data } = await this.post<
      IBackendResponse<ResponseCheckCardCd>,
      { code: string; phone: string; templateId: string }
    >(
      '/form/checkCardCd',
      { code, phone, templateId },
      this.insertHeaders
    ).then((res) => res.data);
    return data;
  }

  /**
   * Проверка капчи и поиск карты по phone.
   * Запрос должен отправляться с url /form-registration/templateId
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/291962905/CheckCardCpt
   * @param recaptchaToken
   * @param phone
   * @param templateId
   * @param version
   * @return {Promise<ResponseCheckCardCdCpt>}
   */
  static async checkCardCdCptPhone(
    recaptchaToken: string,
    phone: string,
    templateId: string,
    version = 'v3'
  ): Promise<ResponseCheckCardCdCpt> {
    const data = await this.post<
      IBackendResponse<ResponseCheckCardCdCpt>,
      {
        recaptchaToken: string;
        phone: string;
        templateId: string;
        version: string;
      }
    >(
      '/form/checkCardCpt',
      { recaptchaToken, phone, templateId, version },
      this.insertHeaders
    )
      .then((res) => res.data.data)
      .catch(callCaptchaError);
    return data;
  }

  /**
   * Проверка капчи и поиск карты по email.
   * Запрос должен отправляться с url /form-registration/templateId
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/291962905/CheckCardCpt
   * @param recaptchaToken
   * @param email
   * @param templateId
   * @param version
   * @return {Promise<ResponseCheckCardCdCpt>}
   */
  static async checkCardCdCptEmail(
    recaptchaToken: string,
    email: string,
    templateId: string,
    version = 'v3'
  ): Promise<ResponseCheckCardCdCpt> {
    const data = await this.post<
      IBackendResponse<ResponseCheckCardCdCpt>,
      {
        recaptchaToken: string;
        email: string;
        templateId: string;
        version: string;
      }
    >(
      '/form/checkCardCpt',
      { recaptchaToken, email, templateId, version },
      this.insertHeaders
    )
      .then((res) => res.data.data)
      .catch(callCaptchaError);
    return data;
  }

  /**
   * Создание карты
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/291831843/CreateCard
   * @param form
   * @param distributionAgreements
   * @param templateId
   */
  static async createCard(
    form: {},
    distributionAgreements: {},
    templateId: string
  ): Promise<ResponseCreateCard> {
    const { data } = await this.post<
      IBackendResponse<ResponseCreateCard>,
      { form: object; templateId: string }
    >('/form/createCard', { form, distributionAgreements, templateId }, this.insertHeaders)
      .then((res) => res.data)
      .catch(handlerErrorsAxiosReturn);
    return data;
  }

  /**
   * Восстанавливает карту для установки пользователем
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/774864897/SendCodeToRecovery
   * @constructor
   */
  static async sendCodeToRecovery(dto: RecoveryCardSms): Promise<string> {
    // return Promise.reject({response: { status: 425 }})
    // return Promise.resolve('5')
    const { data } = await this.post<
      IBackendResponse<string>,
      RecoveryCardSms & { templateId: string }
    >(
      '/cards/SendCodeToRecovery',
      { ...dto, templateId: this.templateId },
      this.insertHeaders
    ).then((res) => res.data);
    return data;
  }

  /**
   * Восстанавливает карту для установки пользователем
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/770342913/cardRecovery
   * @constructor
   */
  static async recoveryCard(dto: RecoveryCard) {
    const { data } = await this.post<
      IBackendResponse<string>,
      RecoveryCard & { templateId: string }
    >(
      '/cards/recoveryCard',
      { ...dto, templateId: this.templateId },
      this.insertHeaders
    ).then((res) => res.data);
    return data;
  }

  /**
   * Позволяет получить данные о карте для enrichment
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/762609665/getCardToEnrichment
   * @param id
   */
  static async getCardToEnrichment(id: string): Promise<ResponseValues> {
    const { data } = await this.get<IBackendResponse<ResponseValues>>(
      `/form/getCardToEnrichment/${id}`,
      this.insertHeaders
    ).then((res) => res.data);
    return data;
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/762707974/CardEnrichment
   * @param upd
   * @param templateId
   */
  static async CardEnrichment(
    upd: RequestCardEnrichment,
    templateId: string
  ): Promise<string> {
    const { data } = await this.put<
      IBackendResponse<string>,
      RequestCardEnrichment & { templateId: string }
    >('/form/CardEnrichment', { ...upd, templateId }, this.insertHeaders).then(
      (res) => res.data
    );
    return data;
  }

  static async checkRecoveryCard(cardId: string) {
    const data = await this.post<IBackendResponse<string>, { cardId: string }>(
      '/cards/checkRecoveryCardToken',
      { cardId },
      { errorSuppression: true, noAuthorization: true }
    ).then((res) => res.data.data);
    return data;
  }
}

export default PublicFormService;
