import * as qs from 'qs';
import { useErrors } from '@/composition/errorsComposition';
import ApiWrap from '@/services/ApiWrap.ts';
import {
  Company,
  EmailsParams,
  companyPathSave,
} from '@/models/company/Company.ts';
import { IBackendResponse } from '@/services/types.ts';
import { getAvailableInPlan } from '@/models/billing/Billing.ts';

const { handlerErrorsAxios } = useErrors();

class CompanyService extends ApiWrap {
  static async getCompany(): Promise<Company> {
    const {
      data: { data },
    } = await this.get<IBackendResponse<Company>>('/vue/getCurrentCompany');
    return data;
  }

  /**
   * @deprecated [Устарел]
   * @param id
   * @param company
   * @return {Promise<Company>}
   */
  static async changeCompanyInfo({
    id,
    company,
  }: {
    id: string;
    company: Company;
  }): Promise<Company> {
    const {
      data: { data },
    } = await this.post<IBackendResponse<Company>, string>(
      `/companies/changeCompanyInfo/${id}`,
      qs.stringify(company)
    ).catch(handlerErrorsAxios);
    return data;
  }

  static async doneOnboardingStep(body: {
    companyId: string;
    onboardingStep: number;
  }) {
    const {
      data: { data },
    } = await this.post<IBackendResponse<string>, string>(
      '/companies/UpdateOnboardingStep',
      qs.stringify(body)
    ).catch(handlerErrorsAxios);
    return data;
  }

  /**
   * Позволяет сохранять объект компании из JSON
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/452952078/saveCompanyNew
   * @param company
   */
  static async saveCompanyNew(company: Company) {
    const { data } = await this.post<IBackendResponse<Company>, Company>(
      '/companies/saveCompanyNew',
      company
    ).then((res) => res.data);
    return data;
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/452952078/PATCH+Company
   * Позволяет сохранять объект компании из JSON
   */
  static async company(company: companyPathSave) {
    const { data } = await this.patch<
      IBackendResponse<Company>,
      companyPathSave
    >('/company', company).then((res) => res.data);
    return data;
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/32505917/SaveAutoInc
   * Сохранения флага автоувеличение устройств.
   * @param autoInc
   */
  static async saveAutoInc(autoInc: boolean) {
    const { data } = await this.post<
      IBackendResponse<Company>,
      { autoInc: boolean }
    >('/companies/saveAutoInc', { autoInc }).then((res) => res.data);
    return data;
  }

  /**
   * Метод позволяет полностью удалить текущую компанию
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/626262034/deleteCurrentCompany
   */
  static async deleteCurrentCompany() {
    const { data } = await this.post<IBackendResponse<string>, null>(
      '/companies/deleteCurrentCompany'
    ).then((res) => res.data);
    return data;
  }

  /**
   * Создание компании для текущего пользователя.
   * Если у пользователя есть выбранная компания,
   * то настройки countryCode берутся оттуда. Если нет,
   * то проставляются по умолчанию (RU).
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/679772167/createCompany
   */
  static async createCompany(name: string): Promise<Company> {
    const { data } = await this.post<
      IBackendResponse<Company>,
      { name: string }
    >('/companies/createCompany', { name }).then((res) => res.data);
    return data;
  }

  /**
   * Выбор текущей компании.
   * Если передать компанию, за которой пользователь не закреплен,
   * то будет ошибка 403.
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/680132620/chooseCompany
   */
  static async chooseCompany(id: string): Promise<string> {
    const { data } = await this.post<IBackendResponse<string>, { _id: string }>(
      '/companies/chooseCompany',
      { _id: id }
    ).then((res) => res.data);
    return data;
  }

  /**
   * Метод позволяет деактивировать компанию
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/827752449/deactivateCompany
   */
  static async deleteCompany(id: string): Promise<string> {
    const { data } = await this.post<IBackendResponse<string>>(
      `/companies/deactivateCompany/${id}`
    ).then((res) => res.data);
    return data;
  }

  /**
   * Метод позволяет получить список доступных и отправленных ( созданных)
   * инструментов тарифа: walletPush,customers,webPush,emails
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/838795265/getAvailableInPlan
   */
  static async getAvailableInPlan(): Promise<getAvailableInPlan> {
    const { data } = await this.get<IBackendResponse<getAvailableInPlan>>(
      '/companies/getAvailableInPlan'
    ).then((res) => res.data);
    return data;
  }

  /**
   * Отправить запрос на подключение карт лояльности к менеджерам на почту support@passteam.io
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/846725121/sendUseLoyaltyCardsRequest
   */
  static async sendUseLoyaltyCardsRequest(): Promise<string> {
    const { data } = await this.post<IBackendResponse<string>>(
      '/companies/sendUseLoyaltyCardsRequest'
    ).then((res) => res.data);
    return data;
  }

  /**
   * Метод позволяет добавить “Расскажите о своих интересах” при регистрации пользователя. По итогу проставляется шаг анбординга -> addPurposes
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/848134145/AddCompanyReview
   */
  static async addCompanyReview(
    companyReview: Record<string, string>
  ): Promise<string> {
    const { data } = await this.post<IBackendResponse<string>>(
      '/companies/AddCompanyReview',
      { companyReview }
    ).then((res) => res.data);
    return data;
  }

  /**
   * Метод позволяет добавить “Расскажите о своих интересах” при регистрации пользователя. По итогу проставляется шаг анбординга -> done
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/847773697/AddCompanyPurposes
   */
  static async addCompanyPurposes(
    purposes: Record<string, string>
  ): Promise<Company> {
    const { data } = await this.post<IBackendResponse<Company>>(
      '/companies/addCompanyPurposes',
      { purposes }
    ).then((res) => res.data);
    return data;
  }

  /**
   * Метод позволяет получить причины отмены подписки пользователя.
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/864911361/AddHubspotFields
   */
  static async addCompanyFields(
    fields: Record<string, string>
  ): Promise<Company> {
    const { data } = await this.post<IBackendResponse<Company>>(
      '/companies/AddHubspotFields',
      { fields }
    ).then((res) => res.data);
    return data;
  }

  /**
   * Метод позволяет приглашать юзера в выбранную компанию.
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/852721665/inviteToCompany
   */
  static async inviteToCompany(email: Record<string, string>): Promise<string> {
    const { code } = await this.post<IBackendResponse<string>>(
      '/companies/inviteToCompany',
      { email }
    ).then((res) => res.data);
    return code;
  }

  /**
   * Позволяет получить приглашения в компанию.
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/872087553/GetInvitationsByCompany
   */
  static async getInvitationsByCompany(): Promise<Company> {
    const { data } = await this.post<IBackendResponse<Company>>(
      `/companies/GetInvitationsByCompany`
    ).then((res) => res.data);
    return data;
  }

  /**
   * Метод позволяет получить приглашение по id.
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/872022021/GetInviteById
   */
  static async getInviteById(id: string): Promise<Company> {
    const { data } = await this.post<IBackendResponse<Company>>(
      `/companies/GetInviteById/${id}`
    ).then((res) => res.data);
    return data;
  }

  /**
   * Метод позволяет отменить приглашение для юзера в компанию.
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/853639169/DeleteInvitation
   */
  static async deleteInvitation(
    email: Record<string, string>
  ): Promise<string> {
    const { code } = await this.post<IBackendResponse<string>>(
      '/companies/DeleteInvitation',
      { email }
    ).then((res) => res.data);
    return code;
  }

  /**
   * Удалить из компании приглашенного юзера.
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/853999617/DeleteInvitedUserFromCompany
   */
  static async deleteInvitedUserFromCompany(id: number): Promise<Company> {
    const { data } = await this.post<IBackendResponse<Company>>(
      `/companies/DeleteInvitedUserFromCompany/${id}`
    ).then((res) => res.data);
    return data;
  }

  /**
   * Получить настройки email для кампейна
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/898465793/getEmailsParams
   */
  static async getEmailsParams(id: string): Promise<EmailsParams> {
    const { data } = await this.get<IBackendResponse<EmailsParams>>(
      `/companies/getEmailsParams/${id}`
    ).then((res) => res.data);
    return data;
  }
}

export default CompanyService;
