import Tracker from '@openreplay/tracker';
import { useSentry } from '@/plugins/sentry';
import { isProd } from '@/helpers/config';
import { ASAYER_ENABLED } from '@/config/common.ts';

const { sentryTags } = useSentry();
// eslint-disable-next-line import/prefer-default-export

const tracker = new Tracker({
  projectKey: "6129218086785751",
});

const trackerInit = (email: string) => {
  tracker.start();
  tracker.userID(email);
  if (tracker.sessionID()) {
    sentryTags(tracker.sessionID());
  }
};
//предыдущие PEdXAtmwXntDFWBb6R61, Cbx4tw6HPmmrEKX2cb9P'
const publicTracker = new Tracker({
  projectKey: '6129218086785751',
});

const publicTrackerInit = (email = 'anonymous@passteam.ru') => {
  if (isProd && ASAYER_ENABLED) {
    publicTracker.start();
    publicTracker.userID(email);
    if (publicTracker.sessionID()) {
      sentryTags(publicTracker.sessionID());
    }
  }
};

export { trackerInit, tracker, publicTracker, publicTrackerInit };
