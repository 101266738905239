import { RouteConfig } from 'vue-router';

type Meta = 'requiresAuth' | 'rootAccess';

interface MetaObj {
  requiresAuth: boolean;
  rootAccess?: boolean;
}

export default {
  path: '/confines',
  component: () => import(/* webpackChunkName: "confines" */ '@/views/confines/index.vue'),
  name: 'confineIndex',
  redirect: '/confines/installed-card',
  meta: {
    requiresAuth: true,
    // rootAccess: true
  } as MetaObj,
  children: [
    {
      path: 'installed-card',
      name: 'confineInstalledCard',
      component: () => import(/* webpackChunkName: "confines" */ '@/views/confines/installedCard.vue'),
      meta: {
        defaultTitle: false,
        pageTitle: 'Common.Limits'
      }
    },
    {
      path: 'deleted-card',
      name: 'confineDeleteCard',
      component: () => import(/* webpackChunkName: "confines" */ '@/views/confines/deletedCard.vue'),
      meta: {
        defaultTitle: false,
        pageTitle: 'Common.Limits'
      }
    },
    {
      path: 'subscription-ended',
      name: 'confineSubscriptionEnded',
      component: () => import(/* webpackChunkName: "confines" */ '@/views/confines/subscriptionEnded.vue'),
      meta: {
        defaultTitle: false,
        pageTitle: 'Common.Limits'
      }
    },
  ],
} as RouteConfig
