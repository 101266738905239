import { RouteConfig } from 'vue-router';
import CompanySettingsRoutes from '@/routes/settings/CompanySettingsRoutes.ts';
import ProfileSettingRoutes from '@/routes/settings/ProfileSettingRoutes.ts';
import SmsSettingsRoutes from '@/routes/settings/SmsSettingsRoutes.ts';
import EmailSettingsRoutes from '@/routes/settings/EmailSettingsRoutes.ts';
import IntegrationsSettingsRoutes from '@/routes/settings/IntegrationsSettingsRoutes';

export default {
  path: '/settings',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  redirect: '/settings/main',
  children: [
    {
      path: 'main',
      name: 'SettingsMain',
      component: () =>
        import(
          /* webpackChunkName: "logsClients" */ '@/views/settings/index.vue'
        ),
      meta: {
        pageTitle: 'Настройки',
        defaultTitle: false,
      },
    },
    {
      path: 'push-settings',
      name: 'pushSettings',
      component: () =>
        import(
          /* webpackChunkName: "settings" */ '@/views/settings/push/index.vue'
        ),
      meta: {
        pageTitle: 'Common.pushSettings',
        defaultTitle: false,
      },
    },
    {
      path: 'whats-app',
      name: 'WhatsAppSettings',
      component: () =>
        import(
          /* webpackChunkName: "settings" */ '@/views/settings/whatsapp/WhatsAppSettings.vue'
        ),
      meta: {
        pageTitle: 'Common.whatsAppSettings',
        defaultTitle: false,
      },
    },
    {
      path: 'email',
      name: 'EmailSettings',
      component: () =>
        import(
          /* webpackChunkName: "settings" */ '@/views/settings/email/EmailSettings.vue'
        ),
      meta: {
        pageTitle: 'companySettings.settingsEmail',
        defaultTitle: false,
      },
    },
    // {
    //   path: 'integration/logs',
    //   name: 'LogsIntegration',
    //   component: () => import(/* webpackChunkName: "logsClients" */ '@/views/logsClients/index.vue'),
    //   meta: {
    //     pageTitle: 'logsClients.Title',
    //     defaultTitle: false,
    //     parent: 'logs',
    //   }
    // },
    CompanySettingsRoutes,
    ProfileSettingRoutes,
    {
      path: 'connecttoapp',
      name: 'ConnectToApp',
      component: () =>
        import(
          /* webpackChunkName: "settings" */ '@/views/settings/ConnectToApp.vue'
        ),
      meta: {
        pageTitle: 'connectToApp.pageTitle',
        defaultTitle: false,
      },
    },
    {
      path: 'generate-numbers',
      name: 'GenerateNumbers',
      component: () =>
        import(
          /* webpackChunkName: "settings" */ '@/views/settings/GenerateNumbers.vue'
        ),
      meta: {
        pageTitle: 'generateNumbers.pageTitle',
        defaultTitle: false,
      },
    },
    {
      path: 'deduplication',
      name: 'Deduplication',
      component: () =>
        import(
          /* webpackChunkName: "settings" */ '@/views/settings/DeduplicationPage.vue'
        ),
      meta: {
        pageTitle: 'Common.deduplication',
        defaultTitle: false,
      },
    },
    // {
    //   path: 'webhooks',
    //   name: 'WebhooksSettingsPage',
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "settings" */ '@/views/settings/WebhooksSettings.vue'
    //     ),
    //   meta: {
    //     pageTitle: 'Webhooks',
    //     defaultTitle: false,
    //   },
    // },
    {
      path: 'virtual-card',
      name: 'VirtualCardSettings',
      component: () =>
        import(
          /* webpackChunkName: "settings" */ '@/views/settings/VirtualCardSettings.vue'
        ),
      meta: {
        pageTitle: 'loyaltyCards.LoyaltyCards',
        defaultTitle: false,
      },
    },
    {
      path: 'variables',
      name: 'VariablesPage',
      component: () =>
        import(
          /* webpackChunkName: "settings" */ '@/views/settings/VariablesSettings.vue'
        ),
      meta: {
        pageTitle: 'variables.Variables',
        defaultTitle: false,
      },
    },
    {
      path: 'languages',
      name: 'CompanySettingsLanguages',
      component: () =>
        import(
          /* webpackChunkName: "settings" */ '@/views/settings/company/CompanySettingsLanguages.vue'
        ),
      meta: {
        defaultTitle: false,
      },
    },
    SmsSettingsRoutes,
    EmailSettingsRoutes,
    IntegrationsSettingsRoutes,
  ],
} as RouteConfig;
