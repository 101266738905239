import { IIntegrationLog } from '@/models/logs/IntegrationLog';

export const initialLogIntegration: IIntegrationLog = {
  _id: {
    $oid: '5fb43728eb60911be40c6445',
  },
  templateId: {
    $oid: '5f6b3e205a622429e25bf3a2',
  },
  url: 'https://api.yclients.com/api/v1/records/39528?&client_id=88322682',
  status: 'Ok',
  code: 200,
  type: 'GET',
  time: 0.324864,
  bodyResponse: '{"count":0,"data":[]}',
  bodyRequest: '',
  created: 1605646120,
  updated: 1605646120,
};

export const initialDataLogs: Array<IIntegrationLog> = [
  {
    _id: {
      $oid: '5fb43728eb60911be40c6445',
    },
    templateId: {
      $oid: '5f6b3e205a622429e25bf3a2',
    },
    url: 'https://api.yclients.com/api/v1/records/39528?&client_id=88322682',
    status: 'Ok',
    code: 200,
    type: 'GET',
    time: 0.324864,
    bodyResponse: '{"count":0,"data":[]}',
    bodyRequest: '',
    created: 1605646120,
    updated: 1605646120,
  },
  {
    _id: {
      $oid: '5fb43728eb60911be40c6444',
    },
    templateId: {
      $oid: '5f6b3e205a622429e25bf3a2',
    },
    url: 'https://api.yclients.com/api/v1/loyalty/client_cards/88322682',
    status: 'Ok',
    code: 200,
    type: 'GET',
    time: 0.255827,
    bodyResponse:
      '[{"id":18936911,"balance":0,"points":0,"paid_amount":0,"sold_amount":0,"visits_count":0,"number":"0001694418936911","type_id":22935,"salon_group_id":16944,"max_discount_percent":0,"max_discount_amount":0,"type":{"id":22935,"title":"Gtype","salon_group_id":16944},"salon_group":{"id":16944,"title":"Сеть Yclients - PassTeam"},"programs":[]}]',
    bodyRequest: '',
    created: 1605646120,
    updated: 1605646120,
  },
  {
    _id: {
      $oid: '5fb43728eb60911be40c6443',
    },
    templateId: {
      $oid: '5f6b3e205a622429e25bf3a2',
    },
    url: 'https://api.yclients.com/api/v1/client/39528/88322682',
    status: 'Ok',
    code: 200,
    type: 'GET',
    time: 0.330498,
    bodyResponse:
      '{"id":88322682,"name":"Андрей","phone":"+79884847154","email":"bizmol31@gmail.com","card":"5fb43725acc1702ccb6279e6","birth_date":"1990-01-01","comment":"","discount":0,"visits":0,"sex_id":1,"sex":"Мужской","sms_check":0,"sms_bot":0,"spent":0,"paid":0,"balance":0,"importance_id":0,"importance":"Без класса важности","categories":[],"last_change_date":"2020-11-18T00:48:39+0400","custom_fields":{}}',
    bodyRequest: '',
    created: 1605646120,
    updated: 1605646120,
  },
  {
    _id: {
      $oid: '5fb3c84a9dd91a43e751dae5',
    },
    templateId: {
      $oid: '5f6b3e205a622429e25bf3a2',
    },
    url: 'https://api.yclients.com/api/v1/clients/39528',
    status: 'Error',
    code: 422,
    type: 'POST',
    time: 0.319893,
    bodyResponse: '{"errors":{"code":422,"message":"Не указано имя клиента"}}',
    bodyRequest: '{"name":"","phone":"+79511307245","email":"","birth_date":"","sex_id":"","card":"5fb3c84a2afbc303093b3326"}',
    created: 1605617738,
    updated: 1605617738,
  },
  {
    _id: {
      $oid: '5fb3c84a2afbc303093b3327',
    },
    templateId: {
      $oid: '5f6b3e205a622429e25bf3a2',
    },
    url: 'https://api.yclients.com/api/v1/loyalty/client_cards/88287782',
    status: 'Ok',
    code: 200,
    type: 'GET',
    time: 0.293336,
    bodyResponse:
      '[{"id":18916645,"balance":0,"points":0,"paid_amount":0,"sold_amount":0,"visits_count":0,"number":"0001694418916645","type_id":22935,"salon_group_id":16944,"max_discount_percent":0,"max_discount_amount":0,"type":{"id":22935,"title":"Gtype","salon_group_id":16944},"salon_group":{"id":16944,"title":"Сеть Yclients - PassTeam"},"programs":[]}]',
    bodyRequest: '',
    created: 1605617738,
    updated: 1605617738,
  },
];
