export const stripTrailingSlash = (str: string) => (str.endsWith('/') ? str.slice(0, -1) : str);

export function isValidHttpUrl(string: string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}
