import { logout } from '@/utils/auth.ts';
import { redirectToLogout } from '@/helpers/config';
import { RouteConfig } from 'vue-router';

export default {
  path: '/logout',
  name: 'Logout',
  beforeEnter(to, from, next) {
    logout();
    // redirectToLogout();
    next({ name: 'Login' });
  },
} as RouteConfig;
