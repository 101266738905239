import { integrationNames } from '@/models/Integration.ts';

export interface Auth {
  email: string;
  password: string;
  totp?: string;
}

export interface AddCrm {
  nameCrm: integrationNames;
  selectLoyalty?: boolean;
  countCustomers?: number;
}

export enum RegisterSteps {
  REQUEST = 'companyReview',
  PURPOSES = 'addPurposes',
  // SELECT_CRM = 'addCrm',
  FINISH = 'done',
}

export type loginResponse = {
  jwt: string;
  refreshToken: string;
  totp: boolean;
};

export interface GooGleProfile {
  profile: {
    email: string;
    email_verified: boolean;
    family_name: string;
    given_name: string;
    locale: string;
    name: string;
    picture: string;
    sub: string;
  };
  token: string;
  tokenValue: string;
}

export interface AppleProfile {
  email: string;
  sub: string;
  firstName: string;
  lastName: string;
}

export interface SignInGoogle {
  type: 'SIGNUP' | 'LOGIN';
  data: loginResponse & GooGleProfile;
}

export interface AuthenticationRes<T> {
  type: 'SIGNUP' | 'LOGIN';
  data: T & loginResponse;
}

export interface JwtPayloadUserEntity {
  id: string;
  email: string;
  name: string;
  surname: string;
  selectedCompanyId: string;
  interfaceLanguage: string;
  role: string[];
  isTwoFactorEnable: boolean;
  isTwoFaAuthenticated: boolean;
}
