import { axiosServer } from '@/http-common.ts';
import { useErrors } from '@/composition/errorsComposition';
import { ImportOptions } from '@/models/cardsImport/cardsImport.ts';

const { handlerErrorsAxios } = useErrors();

class ImportCardsService {
  static async getOptions() {
    const { data: { data } } = await axiosServer
      .get(
        '/import/getOptions',
        { errorSuppression: true },
      )
      .catch(handlerErrorsAxios);

    return data;
  }

  static async saveOptions(options: Partial<ImportOptions>) {
    const { data: { data } } = await axiosServer
      .post(
        '/import/saveOptions',
        { options },
        { errorSuppression: true },
      )
      .catch(handlerErrorsAxios);

    return data;
  }

  static async uploadCSVFile(file: object) {
    const response = await axiosServer
      .post(
        '/import/uploadFile',
        file,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          errorSuppression: true,
        },
      )
      .catch(handlerErrorsAxios);

    return response;
  }

  static async getExampleRows(countRows = 5) {
    const { data: { data } } = await axiosServer
      .get(
        '/import/getExampleRows',
        { params: { countRows }, errorSuppression: true },
      )
      .catch(handlerErrorsAxios);

    return data;
  }

  static async preview(countRows = 5) {
    const { data: { data } } = await axiosServer
      .get(
        '/import/preview',
        { params: { countRows }, errorSuppression: true },
      )
      .catch(handlerErrorsAxios);

    return data;
  }

  static async import() {
    const { data: { data } } = await axiosServer
      .post(
        '/import/createTask',
        {},
        { errorSuppression: true },
      )
      .catch(handlerErrorsAxios);

    return data;
  }

  static async getTask(taskId: string) {
    const { data: { data } } = await axiosServer
      .get(
        '/import/getTask',
        { params: { taskId }, errorSuppression: true },
      )
      .catch(handlerErrorsAxios);

    return data;
  }
}

export default ImportCardsService;
