import { User, UserNotifications} from '@/models/User.ts';

const defaultNotifications: UserNotifications = [
  {
    class: "emptyPhone",
    active: false,
  },
  {
    class: "LowBalance",
    active: false,
    params: {
      limit: 0,
    }
  },
]

// const defaultNotifications: UserNotifications = {
//   /* Уведомлять меня о клиентах без номера телефона */
//   emptyPhoneNotifier: false,
//   /* Системные письма */
//   systemMails: false,
//   /*  Подписка на Блог */
//   blogSubscribe: false,
//   /* Маркетинговые предложения */
//   marketingOffers: false,
//   /* Еженедельная отчет о статистике */
//   statisticReport: false,
//   /* Сообщение о попытках скачиваниях карт */
//   downloadCardErrors: false,
//   /* Необходимость пополнить баланс */
//   balanceSms:  false,
//   /* Статус рассылки Push-уведомлений  */
//   pushStatus:  false,
// }

const defaultUser: User = {
  companyIds: [],
  email: '',
  name: '',
  _id: { $oid: '' },
  accessApi: false,
  accessCreateTemplate: false,
  accessDistribution: false,
  accessDistributionEmail: false,
  accessDuplicateSearch: false,
  accessToken: '',
  accessTriggers: false,
  accountNotificationEnabled: false,
  accountNotificationLimit: 0,
  accountNotificationType: '',
  accountNotifictionSent: 0,
  apiRequestLimitDay: 0,
  apiRequestLimitHour: 0,
  created: 0,
  distributionSmsPrice: 0,
  distributionSmsSender: false,
  domains: [],
  hubSpotId: '',
  interfaceLanguage: '',
  isEmailVerified: true,
  nickname: '',
  notVerifiedEmail: '',
  parentId: { $oid: '' },
  password: '',
  phone: '',
  purposes: [],
  refreshToken: '',
  role: [],
  selectedCompanyId: '',
  simpleGetTemplates: false,
  stripeCustomerId: '',
  surname: '',
  title: '',
  updated: 0,
  notifications: defaultNotifications,
  registerStep: '',
  versionNotification:  {id: '', looked: false }
};
// eslint-disable-next-line import/prefer-default-export
export { defaultUser, defaultNotifications };
