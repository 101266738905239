import ApiWrap from '@/services/ApiWrap.ts';
import { IBackendResponse } from '@/services/types.ts';
import {
  IIntegrationLog,
  IRequestIntegration,
  IRequestIntegrationLog,
} from '@/models/logs/IntegrationLog.ts';
import { useErrors } from '@/composition/errorsComposition';

const { handlerErrorsAxios } = useErrors();

class LogsClientsService extends ApiWrap {
  /**
   * Возвращает список логов по идентификатору шаблона и фильтру.
   * Метод возвращает логи в порядке последний созданный - первый созданный.
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/248217605/GetLogs
   * @param requestIntegration
   */
  static async getLogs(
    requestIntegration: IRequestIntegration
  ): Promise<Array<IIntegrationLog>> {
    const {
      data: { data },
    } = await this.post<
      IBackendResponse<Array<IIntegrationLog>>,
      IRequestIntegration
    >('/integrationLogs/getLogs', requestIntegration).catch(handlerErrorsAxios);
    return data;
  }

  /**
   *
   * @param _id
   * @param templateId
   */
  static async getLog({
    _id,
    templateId,
  }: IRequestIntegrationLog): Promise<IIntegrationLog> {
    const { data } = await this.post<
      IBackendResponse<IIntegrationLog>,
      IRequestIntegrationLog
    >('/integrationLogs/get', { _id, templateId })
      .then((res) => res.data)
      .catch(handlerErrorsAxios);
    return data;
  }
}

export default LogsClientsService;
