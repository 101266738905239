import {
  ActionTree, GetterTree, Module, MutationTree
} from 'vuex';
import { ShortIntegration } from '@/models/integrations/Integration.ts';
import IntegrationsService from '@/services/integrations/integrationsService.ts';

interface RootState {
  version: string;
}

interface PassteamStore {
  integrationsList: Array<ShortIntegration>;
  process: boolean;
}

const actions: ActionTree<PassteamStore, RootState> = {
  async getIntegrations({ commit }) {
    const data = await IntegrationsService.getIntegrationsNames();
    commit('SET_INTEGRATIONS_LIST', data);
  },
};
const mutations: MutationTree<PassteamStore> = {
  SET_INTEGRATIONS_LIST(state, data: Array<ShortIntegration>) {
    state.integrationsList = data;
  },
  ADD_PROCESS(state, data) {
    state.process = data;
  },
};

const getters: GetterTree<PassteamStore, RootState> = {
  process: (state) => state.process,
};
const state: PassteamStore = {
  integrationsList: [],
  process: false,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
} as Module<PassteamStore, RootState>;
