import { RouteConfig } from 'vue-router';

export default {
  path: '/logs',
  component: () => import(/* webpackChunkName: "logsClients" */ '@/layouts/main/PassteamMain.vue'),
  redirect: '/logs/index',
  children: [
    {
      path: 'index',
      name: 'logs',
      component: () => import(/* webpackChunkName: "logsClients" */ '@/views/logsClients/index.vue'),
      meta: {
        pageTitle: 'logsClients.Title',
        defaultTitle: false,
        parent: 'logs',
      }
    },
  ],
} as RouteConfig;
