class AbstractFilterClass {
  constructor(
    {
      search = "",
      sorting = [],
      perPage = 25,
      page = 1,
    }) {
    this.search = search;
    this.sorting = sorting;
    this.perPage = perPage;
    this.page = page;
  }
}

export default AbstractFilterClass