import ApiWrap from '@/services/ApiWrap.ts';
import { IBackendResponse } from '@/services/types.ts';
import {
  UserAdmin, UsersFiltersParams, UsersResponse, UserId
} from '@/models/users/types.ts';
import { User } from '@/models/User.ts';

class UsersService extends ApiWrap {
  /**
   * Получение списка пользователей
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/388628495/GET+List+Users
   */
  static async getUsers(
    paramsFilters: UsersFiltersParams
  ): Promise<UsersResponse> {
    const users = await this.get<IBackendResponse<UsersResponse>>('/users', {
      params: { ...paramsFilters },
    }).then((res) => res.data.data);
    return users;
  }

  /**
   * Получение пользователя
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/388169738/GET+Retrieve+User
   * @param id
   */
  static async getUserById(id: string): Promise<UserAdmin> {
    const { data } = await this.get<IBackendResponse<UserAdmin>>(
      `/users/${id}`
    ).then((res) => res.data);
    return data;
  }

  /**
   * Получение пользователя по Id
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/212336663/getUserById
   * @param id
   */
  static async getCompanyUserById(id: string): Promise<User> {
    const { data } = await this.post<IBackendResponse<UserAdmin>, UserId>(
      `/users/getUserById`,
      { id }
    ).then((res) => res.data);
    return data;
  }

  /**
   * Создание пользователя
   * @param user
   */
  static async createUser(user: UserAdmin): Promise<User> {
    const { data } = await this.post<IBackendResponse<UserAdmin>, UserAdmin>(
      '/users',
      user,
      { errorSuppression: true }
    ).then((res) => res.data);
    return data;
  }

  /**
   * Обновление пользователя
   * @param user
   */
  static async updateUser(id: string, user: UserAdmin): Promise<User> {
    const { data } = await this.put<IBackendResponse<UserAdmin>, UserAdmin>(
      `/users/${id}`,
      user,
      { errorSuppression: true }
    ).then((res) => res.data);
    return data;
  }

  /**
   * удаление пользователя
   * @param user
   */
  static async deleteUser(id: string): Promise<User> {
    const { data } = await this.delete<IBackendResponse<UserAdmin>>(
      `/users/${id}`,
      { errorSuppression: true }
    ).then((res) => res.data);
    return data;
  }
}

export default UsersService;
