interface AllLanguages {
  lang: string;
  name: string;
}

const AllLanguages: Array<AllLanguages> = [
  { lang: 'de', name: 'Deutsch' },
  { lang: 'en', name: 'English' },
  { lang: 'es', name: 'Español' },
  { lang: 'fr', name: 'Français' },
  { lang: 'it', name: 'Italiano' },
  { lang: 'ka', name: 'ქართული' },
  { lang: 'pt', name: 'Português' },
  { lang: 'ru', name: 'Русский' },
  { lang: 'sk', name: 'Slovenský' },
  { lang: 'sr', name: 'Српски' },
  { lang: 'ua', name: 'Український' },
];

export default AllLanguages;

export const allLanguagesShort: Array<string> = [
  'de',
  'en',
  'es',
  'fr',
  'it',
  'ka',
  'pt',
  'ru',
  'sk',
  'sr',
  'ua',
];
