import { rootSegment } from '@/store/modules/cards/initialState';
import AbstractSegmentClass from '@/models/AbstractSegmentClass';
import AbstractFilterClass from '@/models/AbstractFilterClass';
import AbstractOneCardClass from '@/models/AbstractOneCardClass';
import CardsServiceTS from '@/services/cards/cardsServiceTS.ts';

export default {
  namespaced: true,
  state: {
    cards: [],
    currentCard: {},
    cardsCount: 0,

    cardsUI: {
      loadingColumn: false,
      loadingActions: true,
      loadingFilters: false,
      showFilters: false,
      showSegments: true,
    },
    loadingCards: true,
    loadingCurrentCard: true,
    loadingSystemSegments: true,
    loadingUserSegments: true,
    updateFiltersLocal: false,
    firstStart: true,

    systemsSegments: [],
    userSegments: [],

    currentSelectSegment: new AbstractSegmentClass(rootSegment),
    currentFilter: new AbstractFilterClass({}),
    currentIntegration: [],
    lastSegmentFiltersFromDb: [],
    typeCurSelect: 'user',
    segments: {
      filters: {},
    },
    cardsBeta: false
  },
  actions: {

    async getCardInfo({ commit }, payload) {
      commit('SET_LOADING_CURRENT_CARD', true);
      const data = await CardsServiceTS.getCardInfo(payload);
      commit('SET_CURRENT_CARD', data);
      commit('SET_LOADING_CURRENT_CARD', false);
      return data
    },

  },
  mutations: {
    SET_FIRST_START(state, value) {
      state.firstStart = value
    },
    SET_CARDS_UI_LOADING_COLUMNS(state, value) {
      state.cardsUI.loadingColumn = value
    },
    UPDATE_FILTER_LOCAL(state, value) {
      state.updateFiltersLocal = value
    },
    SET_CARDS_UI_LOADING_ACTIONS(state, value) {
      state.cardsUI.loadingActions = value
    },
    SET_SHOW_FILTERS(state, value) {
      state.cardsUI.showFilters = value
    },
    SET_SHOW_SEGMENTS(state, value) {
      state.cardsUI.showSegments = value
    },
    SET_LOADING_USER_SEGMENTS(state, value) {
      state.loadingUserSegments = value;
    },

    SET_CURRENT_INTEGRATION(state, integration) {
      state.currentIntegration = integration;
    },

    SET_USER_SEGMENTS(state, payload) {
      state.userSegments = payload.map((segment) => new AbstractSegmentClass(segment));
    },


    SET_LOADING_CURRENT_CARD(state, value) {
      state.loadingCurrentCard = value;
    },


    SET_CURRENT_CARD(state, card) {
      state.currentCard = new AbstractOneCardClass(card)
    },

    SET_CURRENT_SELECT_SEGMENT(state, payload) {
      state.currentSelectSegment = payload;
    },
  },
  getters: {
    curSelect: state => state.typeCurSelect,
    firstStart: state => state.firstStart,
    systemsSegments: state => state.systemsSegments,
    currentSelectSegment: state => state.currentSelectSegment,
    loadingCards: state => state.loadingCards,
    loadingActions: state => state.cardsUI.loadingActions,
    loadingFilters: state => state.cardsUI.loadingFilters,
    currentSelectSegmentClone: state => JSON.parse(JSON.stringify(state.currentSelectSegment)),
    currentSelectSegmentId: state => state.currentSelectSegment.id,
    activeColumns: state => state.currentSelectSegment.columns.filter((item) => item.visible)
  },
};
