import {
  CommonConfine,
  TranslateField,
} from '@/models/confines/SharingOptions.ts';

class CommonConfineClass implements CommonConfine {
  backgroundButtonColor: string;

  backgroundColor: string;

  body: Array<TranslateField>;

  formButton: boolean;

  formButtonUrl: string;

  header: Array<TranslateField>;

  imageUrl: string;

  useFormLink: boolean;

  useImage: boolean;

  useCardRecovery: boolean;

  constructor(commonConfine: CommonConfine) {
    this.backgroundButtonColor = commonConfine.backgroundButtonColor || '#329eff';
    this.backgroundColor = commonConfine.backgroundColor || '#F8F8F8';
    this.body = commonConfine.body;
    this.formButton = commonConfine.formButton;
    this.formButtonUrl = commonConfine.formButtonUrl;
    this.header = commonConfine.header;
    this.imageUrl = commonConfine.imageUrl;
    this.useFormLink = commonConfine.useFormLink;
    this.useImage = commonConfine.useImage;
    this.useCardRecovery = commonConfine.useCardRecovery ?? false;
  }
}
export default CommonConfineClass;
