import ApiWrap from '@/services/ApiWrap.ts';
import { IBackendResponse } from '@/services/types.ts';
import { Card, CardDevicesRes } from '@/models/cards/Card.ts';
import {
  ActionsRequestCards, ExportCardsParams,
  GetCards, GetCardsFromSegmentParams,
  GetCardsParams, ProviderParameters,
  SavingCard,
} from '@/models/cards/CardsClients.ts';
import { SendReportParameters } from '@/models/Config.ts';

class CardsServiceTS extends ApiWrap {
  /**
   *
   * @param cardId
   */
  static async getCardInfo(cardId: string) {
    const { data } = await this.post<
      IBackendResponse<Card>,
      { cardId: string }
    >('/cards/getCardInfo', { cardId }).then((res) => res.data);
    return data;
  }

  /**
   *
   * @param params
   * @param beta
   */
  static async getCards(
    params: GetCardsParams,
    beta = false
  ): Promise<GetCards> {
    const { data } = await this.post<
      IBackendResponse<GetCards>,
      GetCardsParams
    >(`/cards/${beta ? 'getCardsBeta' : 'getCards'}`, params).then(
      (res) => res.data
    );
    return data;
  }

  /**
   *https://passteam.atlassian.net/wiki/spaces/IN/pages/184614941/GetCards+segments
   * Получение списка карт по сегменту.
   * При использовании метода у сегмента обновляется поле countCards.
   * @param id
   * @param params
   */
  static async getCardsFromSegment(id: string, params: ProviderParameters): Promise<GetCards> {
    const { data } = await this.post<
      IBackendResponse<GetCards>,
      GetCardsFromSegmentParams
    >('/segments/getCards', { _id: { $oid: id }, ...params }).then((res) => res.data);
    return data;
  }

  /**
   *
   * @param card
   */
  static async saveCard(card: SavingCard): Promise<string> {
    const { data } = await this.post<IBackendResponse<string>, SavingCard>(
      '/cards/saveCard',
      card,
      { errorSuppression: true }
    ).then((res) => res.data);
    return data;
  }

  /**
   * Посчитать карты
   * @param params
   */
  static async getCardsCount(
    params: GetCardsParams
  ): Promise<{ cards: Array<Card>; cardsCount: number }> {
    const { data } = await this.post<
      IBackendResponse<{ cards: Array<Card>; cardsCount: number }>,
      GetCardsParams
    >('/cards/getCards?onlyCountCards=true', params).then((res) => res.data);
    return data;
  }

  /**
   * Получение девайсов
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/656474115/Retrieve+devices
   * @param id
   */
  static async getDevices(id: string): Promise<CardDevicesRes> {
    const { data } = await this.get<IBackendResponse<CardDevicesRes>>(
      `/devices/get?cardId=${id}`
    ).then((res) => res.data);
    return data;
  }

  /**
   * /cards/deleteCards
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/161021965/DeleteCards
   * В данной документации метод для удаления карт
   */
  static async deleteCards(actions: ActionsRequestCards): Promise<string> {
    const { data } = await this.post<
      IBackendResponse<string>,
      ActionsRequestCards
    >('/cards/deleteCards', actions).then((res) => res.data);
    return data;
  }

  /**
   * /cards/deactivateCardByFilter
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/826736641/deactivateCardByFilter
   * Метод позволяет деактивировать карту, без удаления клиента
   */
  static async deactivateCardByFilter(actions: ActionsRequestCards): Promise<string> {
    const { data } = await this.post<
      IBackendResponse<string>,
      ActionsRequestCards
    >('/cards/deactivateCardByFilter', actions).then((res) => res.data);
    return data;
  }

  /**
   * /cards/deactivateCardById/id
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/826736648/DeactivateCardById
   * Позволяет деактивировать карту по идентификатору
   */
  static async deactivateCardById(id: string): Promise<string> {
    const { data } = await this.post<
      IBackendResponse<string>
    >(`/cards/deactivateCardById/${id}`).then((res) => res.data);
    return data;
  }

  /**
   * /cards/sendSms
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/163643393/SendSms+SendEmail
   * В данной странице представлены два метода : отправки смс и email
   */
  static async sendSms(actions: ActionsRequestCards) {
    const { data } = await this.post<
      IBackendResponse<string>,
      ActionsRequestCards
    >('/cards/sendSms', actions).then((res) => res.data);
    return data;
  }

  /**
   * /cards/sendEmail
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/163643393/SendSms+SendEmail
   * В данной странице представлены два метода : отправки смс и email
   */
  static async sendEmail(actions: ActionsRequestCards) {
    const { data } = await this.post<
      IBackendResponse<string>,
      ActionsRequestCards
    >('/cards/sendEmail', actions).then((res) => res.data);
    return data;
  }

  /**
   * /cards/exportCsv
   * /cards/exportXlsx
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/160956417/exportCsv+exportXlsx+cards
   * В данной документации методы для экспорта в CSV и XLSX
   */
  static async exportCards(actions: ExportCardsParams, csv: boolean) {
    const { data } = await this.post<
      IBackendResponse<string>,
      ExportCardsParams
      >(`/cards/${csv ? 'exportCsv' : 'exportXlsx'}`, actions).then((res) => res.data);
    return data;
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/762642455/SendErrorReportEmail
   */
  static async sendErrorReportEmail(sendErrorReportEmailParams: SendReportParameters) {
    const { data } = await this.post<
      IBackendResponse<string>,
      SendReportParameters
      >('/cards/sendErrorReportEmail', sendErrorReportEmailParams).then((res) => res.data);
    return data;
  }
}

export default CardsServiceTS;
