import axios, { AxiosInstance, AxiosResponse } from 'axios';
import Router, { Route } from 'vue-router';
import { useErrors } from '@/composition/errorsComposition';
import { addAccessToken, logout } from '@/utils/auth.ts';
import AssayerLogInterceptor from '@/http/axios/AssayerLog.interceptor.ts';

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
});

const axiosServer: AxiosInstance = axios.create({ 
  baseURL: process.env.VUE_APP_PROXY_DEV
    ? ''
    : process.env.VUE_APP_PASSTEAM_API,
});

const { addGlobalError } = useErrors();
axiosServer.interceptors.request.use(addAccessToken);

axiosServer.interceptors.response.use(
  AssayerLogInterceptor.onFulfilled,
  AssayerLogInterceptor.onRejected
);

// axiosServer.interceptors.response.use(
//   (response) => response,
//   refreshToken
// );
let refreshTokenRequest: Promise<void | Route> | null = null;

axiosServer.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    if (refreshTokenRequest === null) {
      refreshTokenRequest = router.push('/login').catch(() => {});
    }

    await refreshTokenRequest;
    refreshTokenRequest = null;
    logout();
    throw (error)
  }
);

axiosServer.interceptors.response.use(
  (response) => response,
  (error: AxiosResponse) => {
    if (error.config && 'errorSuppression' in error.config) {
      return Promise.reject(error);
    }
    addGlobalError(error);
    return Promise.reject(error); // this is the important part
  }
);

export { axiosServer };
