import { RouteConfig } from 'vue-router'

export default {
  path: '/products',
  name: 'Products',
  component: () => import(/* webpackChunkName: "products" */ '@/views/Products.vue'),
  meta: {
    pageTitle: 'Common.Products',
    defaultTitle: false,
  },
} as RouteConfig
