import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import {
  YclientsIntegrationData,
  YclientsSynchronizationData,
} from '@/models/integrations/Yclients.ts';
import {
  yclientsInitialData,
  yclientsSyncInitialData,
} from '@/services/integrations/initialState.ts';
import YclientsService from '@/services/integrations/YclientsService.ts';
import { getStatusYclients } from '@/helpers/Yclients.ts';
import { IntegrationStatusYclients } from '@/components/modules/settings/integration/types.ts';
import pusher from '@/plugins/pusher.ts';

interface RootState {
  version: string;
}

interface YclientsStore {
  integrationData: YclientsIntegrationData;
  syncData: YclientsSynchronizationData;
  loading: boolean;
  blockProcess: boolean;
}

const mutations: MutationTree<YclientsStore> = {
  SET_INTEGRATION_DATA(state, payload) {
    state.integrationData = { ...state.integrationData, ...payload };
  },
  SET_SYNC_INTEGRATION_DATA(state, payload: YclientsSynchronizationData) {
    state.syncData = payload;
  },
  SET_LOADING(state, payload: boolean) {
    state.loading = payload;
  },

  SET_PROCESS(state, payload: boolean) {
    state.blockProcess = payload;
  },
};

const actions: ActionTree<YclientsStore, RootState> = {
  async getIntegrationData({ commit, dispatch }, setLoading = true) {
    commit('SET_LOADING', setLoading);
    const data = await YclientsService.getIntegrationData();
    commit('SET_INTEGRATION_DATA', data);
    await dispatch('integration/getSynchronizationData', '', { root: true });
    dispatch('integration/subscribeSocket', '', { root: true });
    commit('SET_LOADING', false);
  },
  async getSyncIntegrationData({ commit }) {
    const syncData = await YclientsService.getSynchronizationData();
    commit('SET_SYNC_INTEGRATION_DATA', syncData);
    return syncData
  },
  subscribeSocket({ commit, dispatch, rootGetters }) {
    const companyId = rootGetters['company/companyId']
    const channel = pusher.subscribe(`company_${companyId}`);
    channel.bind('synchronization', (data: YclientsSynchronizationData) => {
      commit('SET_SYNC_INTEGRATION_DATA', data);
      if (!data.synchronization) {
        dispatch('reloadIntegration');
      }
    });
  },
  async startSyncIntegrationData({ commit, dispatch }) {
    const syncData = await YclientsService.startSynchronization();
    commit('integration/SET_SYNCH_DATA', syncData, { root: true });
    dispatch('integration/subscribeSocket', '', { root: true });
    return syncData;
  },
  saveIntegrationData(
    { state, commit },
    payload: Partial<YclientsIntegrationData>
  ): Promise<YclientsIntegrationData> {
    commit('SET_PROCESS', true);
    const update = { ...state.integrationData, ...payload };
    return new Promise<YclientsIntegrationData>((resolve) => {
      YclientsService.saveIntegrationData(update)
        .then((res) => {
          commit('SET_INTEGRATION_DATA', res);
          resolve(res);
        })
        .finally(() => {
          commit('SET_PROCESS', false);
        });
    });
  },
  async connectAndSync(
    { dispatch },
    payload: Partial<YclientsIntegrationData>
  ) {
    await dispatch('startSyncIntegrationData');
    await dispatch('saveIntegrationData', payload);
    return Promise.resolve('Success');
  },
  async reloadIntegration({ dispatch }) {
    dispatch('getIntegrationData', false);
    dispatch('passTemplate/getTemplateObject', '', { root: true })
  },
  clearIntegrationData({ commit }) {
    commit('SET_INTEGRATION_DATA', yclientsInitialData);
  },
};

const getters: GetterTree<YclientsStore, RootState> = {
  getStatus: (state) => getStatusYclients(state.integrationData),
  isWarningStatusSync: (state, gettersThis) =>
    gettersThis.getStatus === IntegrationStatusYclients.WARNING_SYNC &&
    !state.syncData.synchronization,
  isDangerStatusSync: (state, gettersThis) =>
    gettersThis.getStatus === IntegrationStatusYclients.DANGER_SYNC &&
    !state.syncData.synchronization,
  isDangerStatus: (state, gettersThis) =>
    gettersThis.getStatus === IntegrationStatusYclients.DANGER_INT &&
    !state.syncData.synchronization,
  isHas406: (state) => state.integrationData?.errors.some((e) => e.code === 406),
  isHas403: (state) => state.integrationData?.errors.some((e) => e.code === 403),
  isHas407: (state) => state.integrationData?.errors.some((e) => e.code === 407),
  isHas401: (state) => state.integrationData?.errors.some((e) => e.code === 401),
  isHas408: (state) => state.integrationData?.errors.some((e) => e.code === 408),
  isHas409: (state) => state.integrationData?.errors.some((e) => e.code === 409),
};
const state: YclientsStore = {
  integrationData: yclientsInitialData,
  loading: true,
  blockProcess: false,
  syncData: yclientsSyncInitialData,
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
} as Module<YclientsStore, RootState>;
