import { RouteConfig } from 'vue-router';

export default {
  path: '/customers',
  name: 'Customers',
  meta: {
    pageTitle: 'Customers',
    defaultTitle: false,
  },
  redirect: {
    name: 'CustomersAll',
  },
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  children: [
    {
      path: '/',
      alias: 'all',
      name: 'CustomersAll',
      component: () =>
        import(
          /* webpackChunkName: "customers" */ '@/views/customers/index.vue'
        ),
      meta: {
        pageTitle: 'Common.CardList',
        defaultTitle: false,
      },
    },
    {
      path: 'experimental',
      name: 'CustomersExperimental',
      component: () =>
        import(
          /* webpackChunkName: "customers" */ '@/views/customers/Experimental.vue'
        ),
      meta: {
        pageTitle: 'Common.CardList',
        defaultTitle: false,
      },
    },
  ],
} as RouteConfig;
