import { computed, ref } from '@vue/composition-api';

export function usePasswordInput() {
  const typeInput = ref('password');
  const eyeValue = computed(() => typeInput.value === 'password');

  const changeType = () => {
    typeInput.value = eyeValue.value ? 'text' : 'password';
  };
  return {
    typeInput,
    eyeValue,
    changeType,
  };
}
