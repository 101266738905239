export const hideMainLoader = () => {
  const loader = document.querySelector('#loader__passteam') as HTMLElement;
  if (loader) {
    loader.style.opacity = '0';
    setTimeout(() => {
      loader.style.visibility = 'hidden';
      loader.style.display = 'none';
    }, 300);
  }
};

