const initialSegment = {
  name: '',
  filter: {
    filters: [],
    search: '',
    sorting: {},
    perPage: 25,
    page: 1
  },
  columns: [],
  order: 0
}

const rootSegment = {
  ...initialSegment,
  _id: {
    $oid: '1142535647547568568567'
  },
  name: 'Все клиенты',
  root: true,
  countCards: 0,
  translations: [
    { language: 'ru', value: 'Все клиенты' },
    { language: 'en', value: 'All customers' },
  ]
}

export { initialSegment, rootSegment };
