import { RouteConfig } from 'vue-router';

export default {
  path: 'passlink',
  name: 'PasslinkSettings',
  meta: {
    defaultTitle: false,
    pageTitle: 'Common.FormSettings',
  },
  component: () =>
    import(
      /* webpackChunkName: "PasslinkSettings" */ '@/views/passlink/PasslinkIndexWrap.vue'
    ),
} as RouteConfig;
