import FilterClass from '@/models/FilterClass';
import {
  checkOperatorDevice,
  componentFilterList,
  filtersList, filterUtms, findAndSetComponent, findAndSetIsComponent, findAngGetOperators,
  getUniqueFilterDevice
} from '@/components/modules/cards/data/operatorsFilter';

class DevFiltersClass extends FilterClass {
  constructor(filter) {
    super(filter);
    this.valueCLass = filter.value

    this.operatorsList = this.setOperatorList(filter.property, filter.type)
    this.componentName = findAndSetComponent(filter.operator, this.operatorsList)
    this.isComponent = findAndSetIsComponent(filter.operator, this.operatorsList)
    this.arrValue = null;
    this.validSelect = this.validOperator = this.validValue = null
  }

  setOperatorList(property, type) {
    if (property === 'utm') {
      return filterUtms
    }
    return checkOperatorDevice(property) ? getUniqueFilterDevice() : findAngGetOperators(type)
  }
}

export default DevFiltersClass
