import { ActionTree, Module, MutationTree } from 'vuex';
import LogsClientsService from '@/services/logs/logsClientsService.ts';
import { IIntegrationLog, IRequestIntegration } from '@/models/logs/IntegrationLog.ts';
import LogClass from '@/models/logs/LogClass.ts';
import { initialDataLogs, initialLogIntegration } from '@/store/modules/logsClients/initalState.ts';
import LogsGroupCollection from '@/models/logs/LogsGroupCollection.ts';

export interface RootState {
  version: string;
}

export interface LogsClientState {
  currentLog: LogClass;
  currentLogId: string;
  logsClients: LogsGroupCollection;
  loadingLogs: boolean;
  loadingCurrentLog: boolean;
  loadingLogsMore: boolean;
  currentFilter: Partial<IRequestIntegration>;
}

const actions: ActionTree<LogsClientState, RootState> = {
  async getLogsClient({ commit, rootGetters, dispatch }, payload: Partial<IRequestIntegration>) {
    commit('SET_LOADINGS_LOGS', true)
    const templateId = rootGetters['passTemplate/currentTemplateId'];
    const data = await LogsClientsService.getLogs({ templateId, ...payload });
    commit('SET_LOGS_CLIENTS', data);
    commit('SET_LOADINGS_LOGS', false)
    commit('SET_LOADINGS_LOGS_MORE', false)
    if (data.length > 0) {
      dispatch('getCurrentLog', new LogClass(data[0]).id)
    }
  },
  async getLogsClientMore({ commit, rootGetters }, payload: Partial<IRequestIntegration>) {
    commit('SET_LOADINGS_LOGS_MORE', true)
    const templateId = rootGetters['passTemplate/currentTemplateId'];
    const data = await LogsClientsService.getLogs({ templateId, ...payload });
    commit('SET_LOGS_CLIENTS_MORE', data);
    commit('SET_LOADINGS_LOGS_MORE', false)
  },
  async getCurrentLog({ commit, rootGetters }, _id: string) {
    commit('SET_LOADING_CUR_LOGS', true)
    const templateId = rootGetters['passTemplate/currentTemplateId'];
    const data = await LogsClientsService.getLog({ _id, templateId });
    const log = new LogClass(data)
    commit('SET_CUR_LOG_CLIENTS', log);
    commit('SET_CUR_LOG_ID', log.id);
    commit('SET_LOADING_CUR_LOGS', false)
  },
};

const mutations: MutationTree<LogsClientState> = {
  SET_LOGS_CLIENTS(state: LogsClientState, payload: Array<IIntegrationLog>) {
    state.logsClients = new LogsGroupCollection(payload);
  },
  SET_LOGS_CLIENTS_MORE(state: LogsClientState, payload: Array<IIntegrationLog>) {
    const extendsArr: Array<IIntegrationLog> = [
      ...state.logsClients.integrationArrayOriginal, ...payload
    ]
    state.logsClients = new LogsGroupCollection(extendsArr);
  },
  SET_CUR_LOG_CLIENTS(state: LogsClientState, payload: LogClass) {
    state.currentLog = payload
  },
  SET_CUR_LOG_ID(state, payload: string) {
    state.currentLogId = payload;
  },
  SET_LOADINGS_LOGS(state, payload: boolean) {
    state.loadingLogs = payload;
  },
  SET_LOADINGS_LOGS_MORE(state, payload: boolean) {
    state.loadingLogsMore = payload;
  },
  SET_LOADING_CUR_LOGS(state, payload: boolean) {
    state.loadingCurrentLog = payload;
  },
};

const state: LogsClientState = {
  currentLog: new LogClass(initialLogIntegration),
  currentLogId: '',
  logsClients: new LogsGroupCollection(initialDataLogs),
  loadingLogs: true,
  loadingCurrentLog: true,
  loadingLogsMore: false,
  currentFilter: {}
};

export const logsClients: Module<LogsClientState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
};
