import { RouteConfig } from 'vue-router';

export default {
  path: 'tasks',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  redirect: {
    name: 'TasksList',
  },
  children: [
    {
      path: '/',
      alias: 'list',
      name: 'TasksList',
      component: () =>
        import(
          /* webpackChunkName: "tasks" */ '@/views/admin/tasks/TasksIndex.vue'
        ),
      meta: {
        pageTitle: 'layoutMain.sidebarLinkQueue',
        defaultTitle: false,
      },
    },
    {
      path: 'list/:id',
      name: 'ViewTasks',
      component: () =>
        import(
          /* webpackChunkName: "tasks" */ '@/views/admin/tasks/ViewTask.vue'
        ),
      meta: {
        defaultTitle: false,
      },
    },
    {
      path: 'workers',
      name: 'Workers',
      component: {
        render: (h) => h('div', [h('router-view')]),
      },
      redirect: {
        name: 'PhpWorkers',
      },
      meta: {
        defaultTitle: false,
      },
      children: [
        {
          path: 'php',
          name: 'PhpWorkers',
          component: () =>
            import(
              /* webpackChunkName: "tasks" */ '@/views/admin/tasks/workers/PhpWorkers.vue'
            ),
          meta: {
            defaultTitle: false,
          },
        },
      ],
    },
  ],
} as RouteConfig;
