import { Store } from 'vuex'
import { computed, Ref } from '@vue/composition-api'
import store from '@/store'
import { getRuntimeVM } from '@/utils/runtime.ts';

export function useStore() {
  return store as Store<any>
}

export function useStoreRef<TState>(): Ref<Store<TState>> {
  const vm = getRuntimeVM();
  const storeRef = computed(() => vm.$store as Store<TState>);
  return storeRef;
}

const storeD = useStore()

export const currentTemplateId =
  storeD.getters['passTemplate/currentTemplateId']

export function useStoreState() {
  const stores = useStore()
  const user = computed(() => stores.state.user.currentUser)
  const companyId = computed(() => stores.getters['company/companyId'])
  return {
    user,
    companyId
  }
}
