import { axiosServer } from '@/http-common.ts';

export default {
  state: {
    loading: false,
    isLoggedIn: false,
    error: null,
    popupOpen: false,
    languages: {},
    timeZones: {},
  },

  mutations: {
    setLoading(state, payload) {
      state.loading = payload
    },
    SET_LANGUAGES(state, languages) {
      state.languages = languages
    },

    SET_TIME_ZONES(state, timeZones) {
      state.timeZones = timeZones
    },

    setError(state, payload) {
      state.error = payload
    },

    clearError(state) {
      state.error = null
    },

    setPopupOpen(state, payload) {
      state.popupOpen = payload
    },



    setLoginIN(state, payload) {
      state.isLoggedIn = payload
    }
  },

  actions: {
    setLoading({ commit }, payload) {
      commit('setLoading', payload)
    },

    setError({ commit }, payload) {
      commit('setError', payload)
    },
    async getLanguages({ commit }) {
      try {
        const { data } = await axiosServer({
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded; charset=UTF-8' },
          data: '',
          url: '/companies/getLanguages',
        })
        if (data.data.error !== true) {
          commit('SET_LANGUAGES', data.data)
        } else {
          console.error('error')
        }
      } catch (e) {
        console.error(e)
      }
    },
    async getTimeZones({ commit }) {
      try {
        const { data } = await axiosServer({
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded; charset=UTF-8' },
          data: '',
          url: '/companies/getTimezones',
        })
        commit('SET_TIME_ZONES', data)
      } catch (e) {
        console.error(e)
      }
    },

    clearError({ commit }) {
      commit('clearError')
    },
    setPopupOpen({ commit }, payload) {
      commit('setPopupOpen', payload)
    }
  },

  getters: {
    loading: state => state.loading,
    isLoggedIn: state => state.isLoggedIn,
    error: state => state.error,
  }
}
