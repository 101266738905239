import { LocalStorageAuthKeys } from '@/components/modules/auth/shared/shared.ts';
import { JwtPayloadUserEntity } from '@/models/Auth.ts';
import jwt_decode from './library/jwt-decode/index';

const LOCAL_ACCESS_NAME = LocalStorageAuthKeys.ACCESS_TOKEN;

type JwtPayload = {
  aud: string;
  data: JwtPayloadUserEntity;
  exp: number;
  iat: number;
  iss: string;
  nbf: number;
};

function setTokens(access: string): void {
  localStorage.setItem(LOCAL_ACCESS_NAME, access);
}

function cleanTokensData(): void {
  localStorage.removeItem(LOCAL_ACCESS_NAME);
}

function getAccessToken(): string {
  return localStorage.getItem(LOCAL_ACCESS_NAME)!;
}

function getJWTPayload(token: string): JwtPayload {
  return jwt_decode(token);
}

function getCurrentJwtPayload(): JwtPayloadUserEntity {
  const accessToken = getAccessToken();
  const { data } = getJWTPayload(accessToken);
  return data;
}

export { setTokens, cleanTokensData, getJWTPayload, getAccessToken, getCurrentJwtPayload };
