import { RouteConfig } from 'vue-router';

export default {
  path: 'versions',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  children: [
    {
      path: '/',
      alias: 'list',
      name: 'VersionsList',
      component: () => import(/* webpackChunkName: "VersionNotifications" */ '@/views/admin/VersionNotifications/index.vue'),
      meta: {
        pageTitle: 'layoutMain.sidebarLinkVersions',
        defaultTitle: false
      }
    },
    {
      path: '/list/:id',
      name: 'EditNotification',
      component: () => import(/* webpackChunkName: "VersionNotifications" */ '@/views/admin/VersionNotifications/EditNotification.vue'),
      meta: {
        defaultTitle: false
      }
    },
    {
      path: 'add',
      name: 'AddNotification',
      component: () => import(/* webpackChunkName: "VersionNotifications" */ '@/views/admin/VersionNotifications/AddNotification.vue'),
      meta: {
        defaultTitle: false
      }
    },
  ],
} as RouteConfig;
