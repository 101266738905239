<template>
  <div class="">
    <email-send-layout :email="email" email-text="AuthPage.weSend" />
    <bottom-links>
      <p class="text-gray-850 mr-10 mb-0">{{ $t('AuthPage.emailQuestion') }}</p>
      <bottom-btn
        :loading="loading"
        :sendStatus="sendStatus"
        @click.native="sendRegisterDouble"
      />
    </bottom-links>
  </div>
</template>

<script>
import EmailSendLayout from '@/components/modules/auth/layouts/emailSendLayout.vue';
import BottomLinks from '@/components/modules/auth/layouts/bottomLinks.vue';
import {
  useRegisterEmailSend,
  checkStorageExist,
} from '@/components/modules/auth/composition/registerEmailSend';
import { redirectToRoot } from '@/helpers/config';
import BottomBtn from '@/components/modules/auth/form/bottomBtn.vue';

export default {
  name: 'emailSendRegister',
  components: { BottomBtn, BottomLinks, EmailSendLayout },
  beforeRouteEnter(to, from, next) {
    checkStorageExist() ? next() : redirectToRoot();
  },
  setup() {
    const {
      email,
      loading,
      sendRegisterDouble,
      sendStatus,
    } = useRegisterEmailSend();
    return {
      email,
      loading,
      sendRegisterDouble,
      sendStatus,
    };
  },
};
</script>

<style scoped></style>
