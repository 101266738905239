import { LocaleMessageObject } from 'vue-i18n';

import admin from '@/i18n/modules/admin.en.json';
import AuthPage from '@/i18n/modules/AuthPage.en.json';
import backendErrors from '@/i18n/modules/backendErrors.en.json';
import barcode from '@/i18n/modules/barcode.en.json';
import Billing from '@/i18n/modules/Billing.en.json';
import btnApp from '@/i18n/modules/btnApp.en.json';
import CardsImport from '@/i18n/modules/CardsImport.en.json';
import cardDesign from '@/i18n/modules/cardDesign.en.json';
import cards from '@/i18n/modules/cards.en.json';
import Common from '@/i18n/modules/Common.en.json';
import PlaceHolder from '@/i18n/modules/PlaceHolder.en.json';
import companies from '@/i18n/modules/companies.en.json';
import companySettings from '@/i18n/modules/companySettings.en.json';
import confinesCard from '@/i18n/modules/confinesCard.en.json';
import connectToApp from '@/i18n/modules/connectToApp.en.json';
import convertFields from '@/i18n/modules/convertFields.en.json';
import crm from '@/i18n/modules/crm.en.json';
import currency from '@/i18n/modules/currency.en.json';
import dashboard from '@/i18n/modules/dashboard.en.json';
import errorsApp from '@/i18n/modules/errorsApp.en.json';
import filters from '@/i18n/modules/filters.en.json';
import form from '@/i18n/modules/form.en.json';
import formDistribution from '@/i18n/modules/formDistribution.en.json';
import generateNumbers from '@/i18n/modules/generateNumbers.en.json';
import integrationSettings from '@/i18n/modules/integrationSettings.en.json';
import integrationsList from '@/i18n/modules/integrationsList.en.json';
import layoutMain from '@/i18n/modules/layoutMain.en.json';
import integrationsTranslate from '@/i18n/modules/integrationsTranslate.en.json';
import logsClients from '@/i18n/modules/logsClients.en.json';
import marketplace from '@/i18n/modules/marketplace.en.json';
import onBoarding from '@/i18n/modules/onBoarding.en.json';
import plans from '@/i18n/modules/plans.en.json';
import PopupReload from '@/i18n/modules/PopupReload.en.json';
import PosterErrors from '@/i18n/modules/PosterErrors.en.json';
import profileSettings from '@/i18n/modules/profileSettings.en.json';
import promo from '@/i18n/modules/promo.en.json';
import promotionalMaterials from '@/i18n/modules/promotionalMaterials.en.json';
import Toasts from '@/i18n/modules/Toasts.en.json';
import integration from '@/i18n/modules/integration.en.json';
import pushSettings from '@/i18n/modules/pushSettings.en.json';
import usersList from '@/i18n/modules/usersList.en.json';
import userSettings from '@/i18n/modules/userSettings.en.json';
import variables from '@/i18n/modules/variables.en.json';
import versions from '@/i18n/modules/versions.en.json';
import formsNames from '@/i18n/modules/formsNames.en.json';
import registrationCard from '@/i18n/modules/registrationCard.en.json';
import downloadCard from '@/i18n/modules/downloadCard.en.json';
import campaign from '@/i18n/modules/campaign.en.json';
import sms from '@/i18n/modules/sms.en.json';
import transactions from '@/i18n/modules/transactions.en.json';
import passlink from '@/i18n/modules/passlink.en.json';
import automation from '@/i18n/modules/automation.en.json';
import loyaltyCards from '@/i18n/modules/loyaltyCards.en.json';
import settings from '@/i18n/modules/settings.en.json';
import purposes from '@/i18n/modules/purposes.en.json';
import products from '@/i18n/modules/products.en.json';
import sales from '@/i18n/modules/sales.en.json';
import whatsAppSettings from '@/i18n/modules/whatsAppSettings.en.json';

export default {
  admin,
  AuthPage,
  backendErrors,
  barcode,
  Billing,
  btnApp,
  cardDesign,
  CardsImport,
  cards,
  Common,
  PlaceHolder,
  companies,
  companySettings,
  confinesCard,
  connectToApp,
  crm,
  convertFields,
  currency,
  dashboard,
  errorsApp,
  filters,
  form,
  formDistribution,
  formsNames,
  generateNumbers,
  integration,
  integrationSettings,
  integrationsList,
  pushSettings,
  layoutMain,
  integrationsTranslate,
  logsClients,
  marketplace,
  onBoarding,
  plans,
  PopupReload,
  PosterErrors,
  promo,
  profileSettings,
  promotionalMaterials,
  Toasts,
  usersList,
  userSettings,
  variables,
  versions,
  registrationCard,
  downloadCard,
  campaign,
  sms,
  transactions,
  passlink,
  automation,
  loyaltyCards,
  settings,
  purposes,
  products,
  sales,
  whatsAppSettings,
} as unknown as LocaleMessageObject;
