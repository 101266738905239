
import state from './moduleBillingState.js'
import mutations from './moduleBillingMutations.js'
import actions from './moduleBillingActions.js'

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: {}
}
