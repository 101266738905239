import { RouteConfig } from 'vue-router';

export default {
  path: 'templates',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  children: [
    {
      path: '/',
      alias: 'list',
      name: 'TemplatesList',
      component: () => import(/* webpackChunkName: "templates" */ '@/views/admin/templates/index.vue'),
      meta: {
        pageTitle: 'layoutMain.sidebarLinkSolution',
        defaultTitle: false
      }
    },
    {
      path: 'add',
      name: 'AddTemplate',
      component: () => import(/* webpackChunkName: "templates" */ '@/views/admin/templates/AddTemplate.vue'),
      meta: {
        defaultTitle: false
      }
    },
    {
      path: ':id',
      name: 'EditTemplate',
      component: () => import(/* webpackChunkName: "templates" */ '@/views/admin/templates/EditTemplate.vue'),
      meta: {
        defaultTitle: false
      }
    },
  ],
} as RouteConfig;
