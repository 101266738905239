import { RouteConfig } from 'vue-router';

export default {
  path: 'integrations',
  name: 'integrationSettings',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  redirect: {
    name: 'integrationList',
  },
  children: [
    {
      path: 'all',
      name: 'integrationList',
      component: () =>
        import(
          /* webpackChunkName: "settings" */ '@/views/settings/integration/all.vue'
        ),
      meta: {
        pageTitle: 'Список интеграций',
        defaultTitle: false,
      },
    },
    {
      path: 'integration/:integration',
      name: 'integrationId',
      component: () =>
        import(
          /* webpackChunkName: "settings" */ '@/views/settings/integration/integrationNew.vue'
        ),
      meta: {
        pageTitle: 'Интеграция',
        defaultTitle: false,
      },
    },
		{
      path: 'settingsOld',
      name: 'integrationSettingsOld',
      component: () =>
        import(
          /* webpackChunkName: "settings" */ '@/views/settings/integration/integrationOld.vue'
        ),
      meta: {
        pageTitle: 'Common.IntegrationCRM',
        defaultTitle: false,
      },
    },
    {
      path: 'logs',
      name: 'LogsIntegration',
      component: () => import(/* webpackChunkName: "logsClients" */ '@/views/logsClients/index.vue'),
      meta: {
        pageTitle: 'logsClients.Title',
        defaultTitle: false,
        parent: 'logs',
      }
    },
    {
      path: 'webhooks',
      name: 'WebhooksSettingsPage',
      component: () =>
        import(
          /* webpackChunkName: "settings" */ '@/views/settings/WebhooksSettings.vue'
        ),
      meta: {
        pageTitle: 'Webhooks',
        defaultTitle: false,
      },
    },
  ],
} as RouteConfig;
