import { initialNewIntegration } from '@/views/pages/integrations/data/initialData';
import mutations from './moduleIntegrationsMutations.js';
import actions from './modulecIntegrationsActions.js';

export default {
  namespaced: true,
  state: {
    selectedIntegration: {
      ...initialNewIntegration,
      createCard: false,
      deleteCard: false,
      updateCard: false,
    },
  },
  actions,
  mutations,
  getters: {
    onboarding: (state) => state.selectedIntegration.onboarding,
  },
};
