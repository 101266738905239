import ApiWrap from '@/services/ApiWrap.ts';
import { IBackendResponse } from '@/services/types.ts';
import { Plan } from '@/models/plan/Plan.ts';
import { useErrors } from '@/composition/errorsComposition';

const { handlerErrorsAxios } = useErrors();

class PlanService extends ApiWrap {
  /**
   * Возвращает данные по тарифу
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/32505995/GetPlan
   * @param planId
   */
  static async getPlan(planId: string): Promise<Plan> {
    const formData: FormData = new FormData();
    formData.append('planId', planId);

    const {
      data: { data },
    } = await this.post<IBackendResponse<Plan>, FormData>(
      '/plans/getPlan',
      formData
    ).catch(handlerErrorsAxios);
    return data;
  }

  static async available(): Promise<{ plans: []; plansCount: number }> {
    const { data } = await this.post<
      IBackendResponse<{ plans: []; plansCount: number }>,
      null
    >('/plans/available').then((res) => res.data);
    return data;
  }

  /**
   * Получить текущий план
   */
  static async getCurrentPlan(): Promise<Plan> {
    const { data } = await this.get<IBackendResponse<Plan>>(
      '/plans/GetPlanJson'
    ).then((res) => res.data);
    return data;
  }

  /**
   * Отправляет нотификатор в телегам, если нет тарифа смс
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/819494913/sendNotifierByEmptySmsPlan
   */
  static async sendNotifierByEmptySmsPlan(): Promise<Plan> {
    const { data } = await this.get<IBackendResponse<Plan>>(
      '/plans/sendNotifierByEmptySmsPlan'
    ).then((res) => res.data);
    return data;
  }
}
export default PlanService;
