import { RouteConfig } from 'vue-router';

export default {
  path: 'profile',
  name: 'ProfileSettings',
  redirect: '/settings/profile/main',
  component: () =>
    import(
      /* webpackChunkName: "settings" */ '@/views/settings/user/index.vue'
    ),
  children: [
    {
      path: 'main',
      name: 'ProfileSettingsMain',
      component: () =>
        import(
          /* webpackChunkName: "settings" */ '@/views/settings/user/UserSettingsMain.vue'
        ),
      meta: {
        pageTitle: 'Common.ProfileSettings',
        defaultTitle: false,
      },
    },
    {
      path: 'security',
      name: 'ProfileSettingsSecurity',
      component: () =>
        import(
          /* webpackChunkName: "settings" */ '@/views/settings/user/UserSettingsSecurity.vue'
        ),
      meta: {
        defaultTitle: false,
      },
    },
    {
      path: 'developers',
      name: 'ProfileSettingsDevelopers',
      component: () =>
        import(
          /* webpackChunkName: "settings" */ '@/views/settings/user/UserSettingsDevelopers.vue'
        ),
      meta: {
        defaultTitle: false,
      },
    },
    {
      path: 'notification',
      name: 'ProfileSettingsNotification',
      component: () =>
        import(
          /* webpackChunkName: "settings" */ '@/views/settings/user/UserSettingsNotification.vue'
        ),
      meta: {
        defaultTitle: false,
      },
    },
  ],
} as RouteConfig;
