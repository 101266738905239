import DevFiltersClass from '@/models/DevFiltersClass';

class AbstractSegmentClass {
  constructor(segment) {
    Object.assign(this, segment);

    const filters = segment.filter.filters.map((filter) => (filter.length > 0
      ? filter.map((objFilter) => new DevFiltersClass(objFilter))
      : null));

    this.filter = {
      ...segment.filter,
      filters,
    };
  }

  get id() {
    // eslint-disable-next-line no-underscore-dangle
    return this._id.$oid;
  }

  get CloneObj() {
    return { ...this };
  }
}

export default AbstractSegmentClass;
