<template>
  <div class="position-relative">
    <input
      :type="typeInput"
      :placeholder="$t(placeholder)"
      class="form-control form-control-lg pr-50"
      :class="{ 'is-invalid': invalid }"
      :name="typeInput"
      :autocomplete="autocomplete"
      required
      :disabled="disabled"
      minlength="6"
      :value="value"
      @input="$emit('input', $event.target.value)"
      :id="inputId"
      :aria-describedby="inputId"
      :tabindex="tabIndex"
    />
    <div class="show-password position-absolute" @click="changeType" >
      <DynamicIcon
        v-b-tooltip.hover
        :icon="eyeValue ? 'eye' : 'eyeLine'"
        :title="$t(`AuthPage.${eyeValue ? 'showPassword' : 'hidePassword'}`)"
        delay="100"
      />
    </div>
  </div>
</template>

<script>
import DynamicIcon from '@/components/passteamUI/DynamicIcon.vue';
import { usePasswordInput } from '@/components/modules/auth/composition/form/input/passwordInput';

export default {
  name: 'passwordInput',
  props: {
    value: String,
    invalid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: [String, Boolean]
    },
    inputId: {
      type: String,
      default: 'inputPassword'
    },
    tabIndex: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'PlaceHolder.EnterPassword'
    }
  },
  components: { DynamicIcon },
  setup() {
    const { typeInput, eyeValue, changeType } = usePasswordInput();
    return {
      typeInput,
      eyeValue,
      changeType,
    };
  },
};
</script>

<style lang="scss" scoped>
.show-password {
  right: 0;
  top: 0;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 15px;
  cursor: pointer;
}
</style>
