import { integrationNames } from '@/models/Integration';

export interface UserForm {
  name: string;
  companyName: string;
  surname: string;
  phone: string;
  password: string;
  countryCode: string;
  email: string;
}

export interface UserFormPropose extends UserForm {
  purposes: Array<number>;
  email: string;
  tokenValue: string;
}

export interface IUserFormCustomCRM extends UserFormPropose {
  integrationName: integrationNames;
}

export type queryFromMail = {
  email: string;
  tokenValue: string;
};

export enum IntegrationEnum {
  API_INTEGRATION = 'API',
  ORDER_INTEGRATION = 'OTHER_INTEGRATION',
  WITHOUT_CRM = 'withoutCrm',
  INTEGRATION_1C = '1c',
  CFT = 'CFT',
  MINDBOX = 'Mindbox',
}

export enum stepsForm {
  MAIN,
  PURPOSES,
  CRM,
  CUSTOM_ORDER_CRM
}
