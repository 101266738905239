import { computed, ComputedRef } from '@vue/composition-api';
import { LanguageRoot, langAllowed, languagesEnum } from '@/models/i18n/Language.ts';
import { LocalStorageAuthKeys } from '@/components/modules/auth/shared/shared.ts';
import useI18nRoot from '@/composition/useI18n.ts';
import useRouter from '@/composition/useRouter.ts';

const languages: LanguageRoot[] = [
  {
    lang: languagesEnum.EN,
    name: 'English',
    link: 'https://www.passteam.io/',
    privacyLink: 'https://www.passteam.io/privacy',
    termLink: 'https://www.passteam.io/terms',
  },
  {
    lang: languagesEnum.RU,
    name: 'Русский',
    link: 'https://www.passteam.ru/',
    privacyLink: 'https://www.passteam.ru/privacy',
    termLink: 'https://www.passteam.ru/terms-and-conditions',
  },
];
type i18n = {
  $t: any;
  $i18n: {
    locale: string;
  };
  $route: {
    query: { lang?: string };
    meta: { pageTitle?: string };
  };
};

export function useI18n() {
  const i18n = useI18nRoot();
  const { route } = useRouter()
  const changeLocale = (lang: langAllowed) => {
    // eslint-disable-next-line no-param-reassign
    i18n.value.locale = lang;

    const pageTitle = route.value.meta?.pageTitle;
    if (pageTitle) {
      document.title = i18n.value.t(pageTitle) as string;
    }

    localStorage.setItem(LocalStorageAuthKeys.LANG_PASSTEAM, i18n.value.locale);
  };
  const checkIfLangQuery = () => {
    if (route.value.query.lang) {
      const findLocale = languages.find(
        (lang) => lang.lang === route.value.query.lang
      );
      if (findLocale) changeLocale(findLocale.lang as langAllowed);
    }
  };

  const checkLanguageInLocalStorage = () => {
    if (localStorage.getItem(LocalStorageAuthKeys.LANG_PASSTEAM)) {
      changeLocale(localStorage.getItem(LocalStorageAuthKeys.LANG_PASSTEAM) as langAllowed)
      return
    }
    changeLocale(languagesEnum.EN)
  }

  const getCurrentLang: ComputedRef<LanguageRoot> = computed(() => {
    const findLocale = languages.find(
      (lang) => lang.lang === i18n.value.locale
    );

    return findLocale || languages[0];
  });

  return {
    changeLocale,
    getCurrentLang,
    checkIfLangQuery,
    checkLanguageInLocalStorage,
    i18n
  };
}

export const getLanguageInLocalStorage = (): string => {
  if (localStorage.getItem(LocalStorageAuthKeys.LANG_PASSTEAM)) {
    return localStorage.getItem(LocalStorageAuthKeys.LANG_PASSTEAM) as string
  }
  return languages[0].lang
}
