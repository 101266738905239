<template>
  <div class="div">
    <transition name="fade">
      <attention v-if="invalidPasswords">
        {{ $t('AuthPage.passwordNotEqual') }}
      </attention>
    </transition>
    <form class="form" @submit.prevent="submitForm">
      <div class="form-group">
        <div class="d-flex justify-content-between">
          <label> {{ $t('formsNames.NewPassword') }} </label>
          <password-status
            class="align-self-center"
            :score="passwordCheckScore.score"
            :touch="!!password"
          />
        </div>
        <password-input
          v-model="password"
          :invalid="invalidPasswords"
          :disabled="process"
          placeholder="AuthPage.passwordPlaceholder1"
        />
      </div>
      <div class="form-group">
        <label> {{ $t('PlaceHolder.EnterDoublePassword') }} </label>
        <password-input
          v-model="passwordDouble"
          :invalid="invalidPasswords"
          :disabled="process"
          input-id="doublePass"
          placeholder="AuthPage.passwordPlaceholder2"
        />
      </div>
      <submit-btn
        :disabled="process || passwordCheckScore.score <= 1"
        :loading="process"
        btn-name="AuthPage.editPassword"
      />
    </form>
  </div>
</template>

<script>
import PasswordInput from '@/components/modules/auth/form/input/passwordInput';
import SubmitBtn from '@/components/modules/auth/form/submitBtn';
import { useChangePassword } from '@/components/modules/auth/composition/form/changePasswordByTokenLogic';
import Attention from '@/components/modules/auth/layouts/attention';
import PasswordStatus from '@/components/modules/auth/form/passwordStatus';
import { usePasswordValidator } from '@/composition/passwordValidator';

export default {
  name: 'changePasswordForm',
  components: { PasswordStatus, Attention, SubmitBtn, PasswordInput },
  setup(_, { root }) {
    const {
      password,
      passwordDouble,
      process,
      sendChangePassword,
    } = useChangePassword(root);

    const {
      passwordCheckScore,
      invalidPasswords,
      submitPasswordsForm,
    } = usePasswordValidator(password, passwordDouble);

    const submitForm = () => {
      submitPasswordsForm(sendChangePassword);
    };

    return {
      password,
      passwordDouble,
      invalidPasswords,
      process,
      passwordCheckScore,
      submitForm,
    };
  },
};
</script>

<style scoped></style>
