import {
  GroupState,
  OnboardingStep,
  StateStep,
} from '@/models/onboarding/Onboarding.ts';
import { systemNamesT } from '@/components/modules/onboarding/steps/stepCollection.ts';

class OnboardingStepClass implements OnboardingStep {
  group: GroupState;

  isDone: boolean;

  required: boolean;

  state: StateStep;

  systemName: systemNamesT;

  constructor(onboardingStep: OnboardingStep) {
    this.group = onboardingStep.group;
    this.isDone = onboardingStep.isDone;
    this.required = onboardingStep.required;
    this.state = onboardingStep.state;
    this.systemName = onboardingStep.systemName;
  }
}
export default OnboardingStepClass;
