import { axiosServer } from '@/http-common.ts';
import qs from 'qs';

const actions = {

    async getPlans({commit}, settings) {
        try {
            let data = await axiosServer({
                method: 'POST',
                headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
                data: qs.stringify(settings),
                url: '/plans/getPlans',
            })
            let plans = data.data.data.plans
            if (data.data.error !== true) {
                plans = plans.sort((a, b) => {
                    return a.order - b.order
                })
                commit('GET_PLANS', plans)
                return plans
            } else {
                console.error('error')
                return false
            }
        } catch (e) {
            console.error(e)
            return false
        }
    },

    async getActivePlan({commit}, companyId) {
        try {
            let {data} = await axiosServer({
                method: 'POST',
                headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
                data: qs.stringify({planId: companyId}),
                url: '/plans/getPlan',
            })
            const currentPlan = data.data
            if(data.data.error !== true){
                commit('GET_ACTIVE_PLAN', data.data)
                commit('billing/GET_CURRENT_TARIFF', data.data)
                return currentPlan
            } else {
                console.error(data)
                return false
            }
        } catch (e) {
            console.error(e)
        }
    },

    async getCurrentUser({commit}) {
        try {
            let {data} = await axiosServer.get('/vue/getCurrentUser')
            data.code !== 400 ? commit('setLoginIN', true, { root: true }) : null
            commit('GET_CURRENT_USER', data.data)
        } catch (e) {
            console.error(e)
        }
    },

    async getCurrentCompany({commit}) {
        try {
            let {data} = await axiosServer.get('/vue/getCurrentCompany')
            commit('GET_CURRENT_COMPANY', data.data)
            commit('GET_CURRENT_TEMPLATE', data.data.templates[0]?._id)
        } catch (e) {
            console.error(e)
        }
    },

    async getCurrentUserAccesses({commit}) {
        try {
            let {data} = await axiosServer.get('/vue/getCurrentUserAccesses')
            commit('GET_CURRENT_USE_ACCESSES', data.data)
        } catch (e) {
            console.error(e)
        }
    },

    async getIntegrations({commit}, settings) {
        try {
            let data = await axiosServer({
                method: 'POST',
                headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
                data: qs.stringify(settings),
                url: '/integrations/getIntegrations',
            })
            const integrations = data.data.data.data
            if (data.data.error !== true) {
                commit('GET_INTEGRATIONS', integrations)
                return integrations
            } else {
                console.error('error')
                return false
            }
        } catch (e) {
            console.error(e)
            return false
        }
    },

    async getCurrentIntegration({commit}, templateId) {
        try {
            let data = await axiosServer({
                method: 'POST',
                headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
                data: qs.stringify({templateId: templateId}),
                url: '/integrations/getIntegration',
            })
            commit('GET_CURRENT_INTEGRATION', data.data.data.integration)
        } catch (e) {
            console.error(e)
        }

    },

    async getLanguages({commit}) {
        try {
            let {data} = await axiosServer({
                method: 'POST',
                headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
                data: '',
                url: '/companies/getLanguages',
            })
            if (data.data.error !== true) {
                commit('GET_LANGUAGES', data.data)
            } else {
                console.error('error')
            }
        } catch (e) {
            console.error(e)
        }

    },

    async getTimeZones({commit}) {
        try {
            let {data} = await axiosServer({
                method: 'POST',
                headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
                data: '',
                url: '/companies/getTimezones',
            })
            commit('GET_TIME_ZONES', data)
        } catch (e) {
            console.error(e)
        }

    },

    async changeCurrentCompanyInfo({commit}, newCompanyInfo) {
        try {
            let {data} = await axiosServer({
                method: 'POST',
                headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
                data: qs.stringify(newCompanyInfo.company),
                url: `/companies/changeCompanyInfo/${newCompanyInfo.id}`,
            })
            if (data.data.error !== true) {
                commit('SET_ACTIVE_COMPANY', data.data)
                return true
            }
            return false
        } catch (e) {
            console.error(e)
            return false
        }
    },

    async getValuesGPayByTemplateId({commit}, templateId) {
        try {
            let {data} = await axiosServer({
                method: 'POST',
                headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
                data: qs.stringify({templateId: templateId}),
                url: '/vue/getValuesGPayByTemplateId',
            })
            if (data.data.error !== true) {
                commit('GET_VALUES_GPAY_BY_TEMPLATE_ID', data.data)
                return data.data
            } else {
                return false
            }
        } catch (e) {
            console.error(e)
        }
    },


    async getFields({commit}, templateId) {
        try {
            let data = await axiosServer({
        method: 'POST',
                headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
                data: qs.stringify(
                    {
                        templateId: templateId
                    }
                ),
                url: '/templates/getFields',
            })
            const fields = data.data.data
            if (data.data.error !== true) {
                commit('GET_TEMPLATE_FIELDS', fields)
                return fields
            } else {
                console.error('error')
                return  false
            }
        } catch (e) {
            console.error(e)
        }
    },
}

export default actions
