export default {
  options: {
    skipFirstLine: false,
    columnSetup: [],
    csvCols: 0,
    csvDelimiter: '',
    csvEnclosure: '',
    csvEscape: '',
    csvRows: 0,
    lastFile: '',
    taskId: '',
    uniqValue: '',
    variant: '',
  },
};
