export default {
  SET_SELECTED_INTEGRATION(state, integration) {
    const { onBoarding, ...other } = integration.accesses;
    const transform = {
      ...integration,
      accesses: { ...other, onBoarding },
    };
    state.selectedIntegration = transform;
  },

  SET_NAME(state, name) {
    state.selectedIntegration.name = name
  },

  SET_CREATE_CARD(state, createCard) {
    state.selectedIntegration.createCard = createCard
  },

  SET_DELETE_CARD(state, deleteCard) {
    state.selectedIntegration.deleteCard = deleteCard
  },

  SET_UPDATE_CARD(state, updateCard) {
    state.selectedIntegration.updateCard = updateCard
  },

  SET_ACCESS_PROMO(state, accessesPromo) {
    state.selectedIntegration.accesses.promotionalMaterials = accessesPromo
  },

  SET_ACCESS_FORM(state, accessesForm) {
    state.selectedIntegration.accesses.form = accessesForm
  },

  SET_SYSTEM_NAME(state, systemName) {
    state.selectedIntegration.systemName = systemName
  },

  SET_LANGUAGE(state, languages) {
    state.selectedIntegration.languages = languages
  },

  SET_FIELDS(state, fields) {
    state.selectedIntegration.fields = fields
  },

  SET_FIELDS_LAYOUTS(state, fieldsLayouts) {
    state.selectedIntegration.fieldsLayouts = fieldsLayouts
  },

  SET_PLANS(state, plans) {
    state.selectedIntegration.plans = plans
  },
  UPDATE_ACCESSES(state, accesses){
    state.selectedIntegration.accesses = accesses
  }
}
