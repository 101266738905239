import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'registrationForm',
    component: () =>
      import(
        /* webpackChunkName: "registration" */ '@/views/RegistrationForm.vue'
      ),
  },
  {
    path: ':templateId',
    name: 'registrationForm',
    component: () =>
      import(
        /* webpackChunkName: "registration" */ '@/views/RegistrationForm.vue'
      ),
  },
  {
    path: '/downloading/:cardId',
    name: 'DownLoadingCard',
    component: () =>
      import(
        /* webpackChunkName: "downloading" */ '@/views/DownLoadingCard.vue'
      ),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
