import { axiosServer } from '@/http-common.ts';
import * as qs from 'qs';
import { useErrors } from '@/composition/errorsComposition';

const { handlerErrorsAxios } = useErrors();

class PassTemplateService {
  static async getTemplateObject(templateId: string) {
    const {
      data: { data },
    } = await axiosServer.post(
      '/templates/getTemplateObject',
      qs.stringify({ templateId })
    );
    return data;
  }

  /**
   * @deprecated
   * @param templateId
   */
  static async getFields(templateId: string) {
    const {
      data: { data },
    } = await axiosServer.post(
      '/templates/getFields',
      qs.stringify({ templateId })
    );
    return data;
  }

  /**
   * Получить utm метки шаблона
   */
  static async getUtm() {
    const { data } = await axiosServer
      .get('/utm/getUtm')
      .then((res) => res.data)
      .catch(handlerErrorsAxios);
    return data;
  }
}

export default PassTemplateService;
