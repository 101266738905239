import { RouteConfig } from 'vue-router';

export default {
  path: '/form',
  component: () => import(/* webpackChunkName: "form" */ '@/views/form/index.vue'),
  name: 'FormSettingsAll',
  redirect: '/form/main',
  children: [
    {
      path: 'main',
      name: 'FormSettingsMain',
      component: () => import(/* webpackChunkName: "form" */ '@/views/form/Main.vue'),
      meta: {
        defaultTitle: false,
        pageTitle: 'Common.FormSettings',
      }
    },
    {
      path: 'settings',
      name: 'FormSettings',
      component: () => import(/* webpackChunkName: "form" */ '@/views/form/Settings.vue'),
      meta: {
        defaultTitle: false
      }
    },
    {
      path: 'action',
      name: 'FormAction',
      component: () => import(/* webpackChunkName: "form" */ '@/views/form/Action.vue'),
      meta: {
        defaultTitle: false
      }
    },
    {
      path: 'share',
      name: 'FormSettingsShare',
      component: () => import(/* webpackChunkName: "form" */ '@/views/form/Shared.vue'),
      meta: {
        defaultTitle: false
      }
    },
    {
      path: 'dev',
      name: 'Dev',
      component: () => import(/* webpackChunkName: "form" */ '@/views/dev/Icons.vue'),
      meta: {
        defaultTitle: false
      }
    },
  ],
} as RouteConfig;
