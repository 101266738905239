import { NavigationGuard } from 'vue-router';
import {
  logout,
  checkTwoFactorAuthenticated,
  checkTwoFactorIsEnable,
} from '@/utils/auth.ts';
import { rootRole } from '@/helpers/config';
import { getAccessToken } from '@/utils/token.ts';
import UserService from '@/services/user/userService.ts';
import UserNodeService from '@/services/node/UserNodeService.ts';
import useCompanyStore from '@/composition/useCompanyStore.ts';
import useUserStore from '@/composition/useUserStore.ts';
import {
  checkCompanyRoute,
  CheckCompanyParams,
} from '@/helpers/companySubscription.ts';
import { RegisterSteps } from '@/models/Auth.ts';

const routerMiddleware: NavigationGuard = async (to, from, next) => {
  const token = getAccessToken();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    await UserNodeService.isAuthorized()
      .then()
      .catch((e) => {
        logout();
        next({ name: 'Login', query: { from: to.fullPath } });
      });
    /**
     * Проверяем есть ли токен
     */
    if (!token) {
      logout();
      next({ name: 'Login', query: { from: to.fullPath } });
    }
    const isTwoFactorEnable = checkTwoFactorIsEnable();
    const isTwoFaAuthenticated = checkTwoFactorAuthenticated();
    if (isTwoFactorEnable && !isTwoFaAuthenticated) {
      next({ name: 'TwoAuthCode', query: { from: to.fullPath } });
    }
  }

  if (to.matched.some((route) => route.meta.rootAccess)) {
    await UserService.getUser()
      .then((res) => {
        if (!res.role.includes(rootRole)) {
          next({ name: 'page-error-500' });
        }
      })
      .catch((e) => {
        logout();
        next({ name: 'Login', query: { from: to.fullPath } });
      });
  }

  const { currentCompany, activePlan } = useCompanyStore();
  const { currentUser, rootUser } = useUserStore();
  if (
    to.matched.some((route) => route.meta.checkSubscription) &&
    !rootUser.value
  ) {
    const params: CheckCompanyParams = {
      subActive: currentCompany.value.isSubscriptionActive,
      isStandAlone: activePlan.value.isStandAlone,
    };
    checkCompanyRoute(params, to, next);
  }

  if (
    to.matched.some((route) => route.meta.checkRegisterStep) &&
    !rootUser.value
  ) {
    const registerStep = currentUser.value.registerStep;
    if (
      RegisterSteps.REQUEST === registerStep ||
      RegisterSteps.PURPOSES === registerStep
    ) {
      next({ name: 'Purposes' });
    }
  }

  return next();
};

export default routerMiddleware;
