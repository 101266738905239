import {
  FormValue,
  PublicForm,
  script,
  authenticationType,
  distributionAgreementsSettings,
} from '@/models/form/PublicForm.ts';
import { MongoOID } from '@/models/MongoTypes.ts';
import { publicLangAllowed } from '@/models/i18n/Language.ts';
import FormApperanceClass from '@/models/cards/FormApperanceClass.ts';
import { invertColor } from '@/helpers/colorContrast';
import { Field } from '@/models/Fields.ts';
import { Translation } from '@/models/Translation.ts';
import KeyActionsParamsClass from '@/models/cards/KeyActionsParamsClass.ts';
import { keyAction } from '@/models/cards/PublicCard.ts';
import { CommonAccesses } from '@/models/Accesses.ts';

class PublicFormClass implements PublicForm {
  _id: MongoOID;

  accesses: CommonAccesses;

  agreementUrl: string;

  authenticationType: authenticationType;

  blockForm: boolean;

  companyName: string;

  emailValue: string;

  formAppearance: FormApperanceClass;

  formValues: FormValue[];

  integration: string;

  isSubscriptionActive: boolean;

  languages: Array<publicLangAllowed>;

  logoUrl: string;

  multilanguages: boolean;

  phoneValue: string;

  restrictionUrl: string;

  script: script;

  templateId: MongoOID;

  timeOut: number;

  useAppleSign: boolean;

  useGoogleSign: boolean;

  whiteLabel: boolean;

  fields: Array<Field>;

  required: boolean;

  installedLimit: boolean;

  useCustomTermLink: boolean;

  useCustomNotFoundText: boolean;

  useEnrichment: boolean;

  countryCode: string;

  cardNotFoundText: Array<Translation>;

  title: Array<Translation>;

  distributionAgreementsSettings: distributionAgreementsSettings;

  keyAction: keyAction;

  keyActionsParams: KeyActionsParamsClass;

  createdLimit: boolean;

  isStandAlone: boolean;

  limitAgeClients: boolean;

  minAge: number | null;

  maxAge: number | null;

  isEnableAgreeTel: boolean;

  constructor(form: PublicForm) {
    // eslint-disable-next-line no-underscore-dangle
    this._id = form._id;
    this.accesses = form.accesses;
    this.agreementUrl = form.agreementUrl;
    this.authenticationType = form.authenticationType;
    this.blockForm = form.blockForm;
    this.companyName = form.companyName;
    this.emailValue = form.emailValue;
    this.formAppearance = new FormApperanceClass(form.formAppearance);
    this.integration = form.integration;
    this.isSubscriptionActive = form.isSubscriptionActive;
    this.languages = form.languages;
    this.logoUrl = form.logoUrl;
    this.multilanguages = form.multilanguages;
    this.phoneValue = form.phoneValue;
    this.restrictionUrl = form.restrictionUrl;
    this.script = form.script;
    this.templateId = form.templateId;
    this.timeOut = form.timeOut;
    this.useAppleSign = form.useAppleSign;
    this.useGoogleSign = form.useGoogleSign;
    this.whiteLabel = form.whiteLabel;
    this.formValues = form.formValues;
    this.fields = form.fields || [];
    this.required = form.required;
    this.installedLimit = form.installedLimit;
    this.useCustomTermLink = form.useCustomTermLink;
    this.cardNotFoundText = form.cardNotFoundText;
    this.useCustomNotFoundText = form.useCustomNotFoundText;
    this.useEnrichment = form.useEnrichment;
    this.title = form.title;
    this.distributionAgreementsSettings = form.distributionAgreementsSettings;
    this.countryCode = form.countryCode;
    this.keyAction = form.keyAction;
    this.keyActionsParams = form.keyActionsParams;
    this.createdLimit = form.createdLimit;
    this.isStandAlone = form.isStandAlone;
    this.limitAgeClients = form.limitAgeClients;
    this.minAge = form.minAge;
    this.maxAge = form.maxAge;
    this.isEnableAgreeTel = form.isEnableAgreeTel;
  }

  get activeForm() {
    return this.isSubscriptionActive && !this.blockForm;
  }

  get limitCardsReached() {
    return this.isStandAlone ? this.createdLimit : this.installedLimit;
  }

  get bgColor() {
    return this.formAppearance.background;
  }

  get checkMultiLanguages() {
    return this.languages.length > 1;
  }

  get firstLanguages() {
    return this.languages[0] ? this.languages[0] : 'ru';
  }

  get textColor() {
    return invertColor(this.bgColor, true);
  }

  get getAndCreate(): boolean {
    return this.script === 'getAndCreate';
  }

  get isGet() {
    return this.script === 'get';
  }

  get isEmailIdentification() {
    return this.authenticationType === 'email';
  }

  get isSmsIdentification() {
    return this.authenticationType === 'sms';
  }

  get isSmsSimpleIdentification() {
    return !this.authenticationType && this.phoneValue;
  }
}
export default PublicFormClass;
