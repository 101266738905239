import { RouteConfig } from 'vue-router';

export default {
  path: 'email',
  name: 'EmailSettings',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  meta: {
    pageTitle: 'Email',
    defaultTitle: false,
    rootAccess: true,
  },
  children: [
    {
      path: '/',
      name: 'EmailSettingsMain',
      component: () =>
        import(
          /* webpackChunkName: "settings" */ '@/views/settings/email/EmailSettingsIndex.vue'
        ),
      meta: {
        pageTitle: 'Email',
        defaultTitle: false,
      },
    },
    {
      path: ':id',
      name: 'EmailDomainId',
      component: () =>
        import(
          /* webpackChunkName: "settings" */ '@/views/settings/email/EmailDomainId.vue'
        ),
      meta: {
        pageTitle: 'Email',
        defaultTitle: false,
      },
    },
  ],
} as RouteConfig;
