import qs from 'qs';
import { axiosServer } from '@/http-common.ts';
import {
  ConfirmEmaiLParams,
  User,
  UserEdit,
  UserNotifications,
  UserRedirects,
} from '@/models/User.ts';
import { useErrors } from '@/composition/errorsComposition';
import ApiWrap from '@/services/ApiWrap.ts';
import { IBackendResponse } from '@/services/types.ts';
import { Company } from '@/models/company/Company.ts';
import { CommonAccesses } from '@/models/Accesses.ts';
import UserClass from '@/models/user/UserClass.ts';
import { loginResponse } from '@/models/Auth.ts';
import { RoleItem, SignupUserInvite } from '@/models/Roles';

const { handlerErrorsAxiosSimple } = useErrors();

class UserService extends ApiWrap {
  static async getUser(): Promise<User> {
    const { data } = await this.get<IBackendResponse<User>>(
      '/vue/getCurrentUser',
      { errorSuppression: true }
    )
      .then((res) => res.data)
      .catch(handlerErrorsAxiosSimple);
    return data;
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/212336663/getUserById
   * Возвращает юзера по его идентификатору
   * @param id
   */
  async getUserById(id: string): Promise<User> {
    const { data } = await this.post<IBackendResponse<User>>(
      '/users/getUserById',
      { id }
    ).then((res) => res.data);
    return data;
  }

  /**
   * Возвращает доступы пользователя
   */
  static async getUserAccess(): Promise<CommonAccesses> {
    const { data } = await this.get<IBackendResponse<CommonAccesses>>(
      '/vue/getCurrentUserAccesses'
    ).then((res) => res.data);
    return data;
  }

  /**
   *
   * @param userId
   */
  static async getUserCompanies(userId: string): Promise<Array<Company>> {
    const formData: FormData = new FormData();
    formData.append('userId', userId);
    const { data } = await this.post<
      IBackendResponse<Array<Company>>,
      FormData
    >('/users/getUsersCompanies', formData).then((res) => res.data);
    return data;
  }

  static async selectCompany(id: string): Promise<never> {
    const { data } = await this.get<never>('/companies/selectCompany', {
      params: { id },
    });
    return data;
  }

  async getUserByEmail(email: string) {
    const {
      data: { data },
    } = await axiosServer.post(' /users/getUserByEmail', { email });
    return data;
  }

  static async updateUser(user: UserClass) {
    const {
      data: { data },
    } = await axiosServer.post(
      `/users/changeUserInfo/${user.id}`,
      qs.stringify({ userData: user })
    );

    return data;
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/480935944/sendConfirmEmailInProfile
   * Метод позволяет отправить письмо с текcтом подтверждения email
   */
  static async sendConfirmEmailInProfile(email: string) {
    const { data } = await this.post<
      IBackendResponse<string>,
      { email: string }
    >('/users/sendConfirmEmailInProfile', { email }).then((res) => res.data);
    return data;
  }

  /**
   * Проверить email, который перешел по ссылке из почты
   * для подтверждения email из профиля пользователя
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/477233230/confirmEmailInProfile
   * @param params
   */
  static async confirmEmailInProfile(params: ConfirmEmaiLParams) {
    const { data } = await this.post<
      IBackendResponse<string>,
      ConfirmEmaiLParams
    >('/users/confirmEmailInProfile', params, { errorSuppression: true }).then(
      (res) => res.data
    );
    return data;
  }

  /**
   * Обновляет refresh и jwt токен пользователю если предыдущий jwt стал просроченным.
   * В ответ возвращается новая пара токенов.
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/192446465/refreshToken
   */
  static async refreshToken(): Promise<loginResponse> {
    const { data } = await this.post<IBackendResponse<loginResponse>, null>(
      '/users/refreshToken',
      null,
      {
        withCredentials: true,
        errorSuppression: true,
      }
    ).then((res) => res.data);
    return data;
  }

  /**
   * Cмена пароля
   * @param oldPassword
   * @param newPassword
   */
  static async changeUserPassword(oldPassword: string, newPassword: string) {
    const { data } = await this.post<
      IBackendResponse<loginResponse>,
      { oldPassword: string; newPassword: string }
    >('/users/changeUserPassword', { oldPassword, newPassword }).then(
      (res) => res.data
    );
    return data;
  }

  /**
   * Обновление пользователя
   * @param user
   */
  static async edit(user: UserEdit) {
    const { data } = await this.patch<
      IBackendResponse<loginResponse>,
      UserEdit
    >('/users/edit', user).then((res) => res.data);
    return data;
  }

  /**
   * Позволяет создать токен для productBoard
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/502693905/getProductToken
   */
  static async getProductToken() {
    const { data } = await this.get<IBackendResponse<string>>(
      '/JWT/getProductToken'
    ).then((res) => res.data);
    return data;
  }

  /**
   * Метод позволяет проверить авторизацию пользователя
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/516751361/IsAuthorized
   * @constructor
   */
  static async isAuthorized(): Promise<string> {
    const { data } = await this.get<IBackendResponse<string>>(
      '/site/isAuthorized',
      { errorSuppression: true }
    ).then((res) => res.data);
    return data;
  }

  static async updateNotifications(notifications: {
    notifications: UserNotifications;
  }) {
    const { data } = await this.post<
      IBackendResponse<User>,
      { notifications: UserNotifications }
    >('/users/updateNotifications', notifications).then((res) => res.data);
    return data;
  }

  /**
   * Получение адреса страницы для дальнейшего редиректа.
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/680067073/AfterLoginRedirect
   */
  static async afterLoginRedirect(): Promise<UserRedirects> {
    const { data } = await this.get<IBackendResponse<UserRedirects>>(
      '/users/afterLoginRedirect'
    ).then((res) => res.data);
    return data;
  }

  /**
   *
   */
  static async resetPasswordCurrent() {
    const { data } = await this.get<IBackendResponse<string>>(
      '/users/resetPassword'
    ).then((res) => res.data);
    return data;
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/852951041/registerByInvite
   * @param user
   */
  static async registerByInvite(user: SignupUserInvite) {
    const { data } = await this.post<
      IBackendResponse<UserClass>,
      SignupUserInvite
    >('/users/registerByInvite', user).then((res) => res.data);
    return data;
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/748584961/DeleteCompanyUserByOwner
   * @param user
   */
  static async deleteCompanyUserByOwner(user: string) {
    const { data } = await this.post<IBackendResponse<RoleItem>, null>(
      `/users/deleteCompanyUserByOwner/${user}`
    ).then((res) => res.data);
    return data;
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/755171329/ConfirmInviteToCompany
   * @param token
   */
  static async ConfirmInviteToCompany(token: string) {
    const { data } = await this.post<
      IBackendResponse<RoleItem>,
      { token: string }
    >('/users/ConfirmInviteToCompany', { token }).then((res) => res.data);
    return data;
  }

  /**
   * Метод позволяет завести инсайт
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/875659265/SendInsight
   * @param header
   * @param body
   */
  static async sendInsight(header: string, body: string) {
    const { data } = await this.post<
      IBackendResponse<string>,
      { header: string; body: string }
    >('/emails/SendInsight', { header, body }).then((res) => res.data);
    return data;
  }
}

export default UserService;
