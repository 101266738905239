<template>
    <integrationOrderSent>
      <p class="mb-20">
        {{ $t('AuthPage.crmOrderManagerCall') }}
      </p>
    </integrationOrderSent>
</template>

<script>
import IntegrationOrderSent from '@/components/modules/auth/layouts/integrationOrderSent.vue';

export default {
  name: 'orderCrmComplete',
  components: { IntegrationOrderSent },
};
</script>

<style scoped></style>
