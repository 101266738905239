import { Roles, User, UserNotifications, UserNotification, VersionNotifcationUser } from '@/models/User.ts'
import { defaultNotifications } from '@/store/modules/user/initialState.ts';
import { MongoOID } from '@/models/MongoTypes.ts'

class UserClass implements User {
  _id: MongoOID

  accessApi: boolean

  accessCreateTemplate: boolean

  accessDistribution: boolean

  accessDistributionEmail: boolean

  accessDuplicateSearch: boolean

  accessToken: string

  accessTriggers: boolean

  accountNotificationEnabled: boolean

  accountNotificationLimit: number

  accountNotificationType: string

  accountNotifictionSent: number

  apiRequestLimitDay: number

  apiRequestLimitHour: number

  companyIds: Array<MongoOID>

  created: number

  distributionSmsPrice: number

  distributionSmsSender: boolean

  domains: []

  email: string

  hubSpotId: string

  moreLogs: false

  interfaceLanguage: string

  isEmailVerified: boolean

  name: string

  nickname: string

  notVerifiedEmail: string

  parentId: MongoOID

  password: string

  phone: string

  purposes: []

  refreshToken: string

  role: Array<Roles>

  selectedCompanyId: string

  simpleGetTemplates: boolean

  stripeCustomerId: string

  surname: string

  title: string

  updated: number

  registerStep: string

  versionNotification: VersionNotifcationUser

  notifications: UserNotifications;

  constructor(UserObj: User) {
    // eslint-disable-next-line no-underscore-dangle
    this._id = UserObj._id
    this.accessApi = UserObj.accessApi
    this.accessCreateTemplate = UserObj.accessCreateTemplate
    this.accessDistribution = UserObj.accessDistribution
    this.accessDistributionEmail = UserObj.accessDistributionEmail
    this.accessDuplicateSearch = UserObj.accessDuplicateSearch
    this.accessToken = UserObj.accessToken
    this.accessTriggers = UserObj.accessTriggers
    this.accountNotificationEnabled = UserObj.accountNotificationEnabled
    this.accountNotificationLimit = UserObj.accountNotificationLimit
    this.accountNotificationType = UserObj.accountNotificationType
    this.accountNotifictionSent = UserObj.accountNotifictionSent
    this.apiRequestLimitDay = UserObj.apiRequestLimitDay
    this.companyIds = UserObj.companyIds
    this.created = UserObj.created
    this.distributionSmsPrice = UserObj.distributionSmsPrice
    this.apiRequestLimitHour = UserObj.apiRequestLimitHour
    this.distributionSmsSender = UserObj.distributionSmsSender
    this.domains = UserObj.domains
    this.email = UserObj.email
    this.hubSpotId = UserObj.hubSpotId
    this.nickname = UserObj.nickname
    this.notVerifiedEmail = UserObj.notVerifiedEmail
    this.interfaceLanguage = UserObj.interfaceLanguage
    this.parentId = UserObj.parentId
    this.password = UserObj.password
    this.phone = UserObj.phone
    this.purposes = UserObj.purposes
    this.refreshToken = UserObj.refreshToken
    this.role = UserObj.role
    this.selectedCompanyId = UserObj.selectedCompanyId
    this.simpleGetTemplates = UserObj.simpleGetTemplates
    this.stripeCustomerId = UserObj.stripeCustomerId
    this.title = UserObj.title
    this.updated = UserObj.updated
    this.isEmailVerified = UserObj.isEmailVerified
    this.moreLogs = UserObj.moreLogs

    this.name = UserObj.name || ''
    this.surname = UserObj.surname || ''
    this.registerStep = UserObj.registerStep
    this.versionNotification = UserObj.versionNotification || { id: '', looked: true }
    this.notifications = [
      UserObj.notifications.find(
        (userItem: UserNotification) => userItem.class === 'emptyPhone'
      ) || defaultNotifications[0],
      ...UserObj.notifications.filter(
        (userItem: UserNotification) => userItem.class === 'LowBalance'
      ),
      ...UserObj.notifications.filter(
        (userItem: UserNotification) => userItem.class === 'errorInCard'
      )
    ]
  }

  /**
   * Return fullname {userInfo.vue}
   * @public
   */
  get getFullName(): string {
    return this.checkEmptyFullName ? [this.name, this.surname].join(' ') : this.email
  }

  private get checkEmptyFullName() {
    return !!(this.name || this.surname)
  }

  get id() {
    // eslint-disable-next-line no-underscore-dangle
    return this._id.$oid
  }
}

export default UserClass
