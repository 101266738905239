import ApiWrap from '@/services/ApiWrap.ts';
import { AuthSubscription, UserEvents } from '@/models/User.ts';
import { RoleItem } from '@/models/Roles.ts';

class UserNodeService extends ApiWrap {
  /**
   *
   * @param authSubscription
   */
  static async authEvents(authSubscription: AuthSubscription) {
    const data = await this.post<AuthSubscription, AuthSubscription>(
      '/node/api/user/authEvents',
      authSubscription,
      {
        baseURL: process.env.VUE_APP_NODE_URL,
      }
    );
    return data.data.event;
  }

  static async logout() {
    const data = await this.get<string>('/node/api/user/logout', {
      baseURL: process.env.VUE_APP_NODE_URL,
    });
    return data;
  }

  static async isAuthorized() {
    const data = await this.get<string>('/node/api/user/isAuthorized', {
      baseURL: process.env.VUE_APP_NODE_URL,
      errorSuppression: true
    }).then((res) => res.data);
    return data;
  }

  static async getUserRoleAccess() {
    const data = await this.get<RoleItem>('/node/api/user/getUserRoleAccess', {
      baseURL: process.env.VUE_APP_NODE_URL,
      errorSuppression: true
    }).then((res) => res.data);
    return data;
  }
}

export default UserNodeService;
