import { RouteConfig } from 'vue-router'

export default {
  path: '/sales',
  name: 'Sales',
  component: () => import(/* webpackChunkName: "products" */ '@/views/Sales.vue'),
  meta: {
    pageTitle: 'Common.Sales',
    defaultTitle: false,
  },
} as RouteConfig
