import { axiosServer } from '@/http-common.ts';
import qs from 'qs';
import passTemplateService from '@/services/passTemplate/passTemplateService.ts';

export default {
  namespaced: true,
  state: {
    currentTemplateId: null,
    fields: [],
    templateObj: {},
    utms: [],
    loadingTemplateObj: false,
  },
  actions: {
    async getFields({ commit }, templateId) {
      try {
        let data = await axiosServer({
          method: "POST",
          headers: {
            "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          data: qs.stringify({
            templateId: templateId,
          }),
          url: '/templates/getFields',
        });
        const fields = data.data.data;
        if (data.data.error !== true) {
          commit("SET_TEMPLATE_FIELDS", fields);
          return fields;
        } else {
          console.error("error");
          return false;
        }
      } catch (e) {
        console.error(e);
      }
    },

    async getTemplateObject({ commit, getters }) {
      commit("SET_LOADING_TEMPLATE_OBJECT", true);
      const templateID = getters.currentTemplateId;
      const data = await passTemplateService.getTemplateObject(templateID);
      commit("SET_TEMPLATE_OBJ", data);
      commit("SET_LOADING_TEMPLATE_OBJECT", false);
    },

    async getUtms({ commit, getters }) {
      const data = await passTemplateService.getUtm();
      commit('SET_UTM_ARR', data);
    },
  },
  mutations: {
    SET_CURRENT_TEMPLATE(state, id) {
      state.currentTemplateId = id;
    },
    SET_TEMPLATE_FIELDS(state, fields) {
      state.fields = fields;
    },

    SET_TEMPLATE_OBJ(state, templateObj) {
      state.templateObj = templateObj;
    },

    SET_TEMPLATE_OBJ_PARTIAL(state, templateObjPartial) {
      state.templateObj = {
        ...state.templateObj,
        ...templateObjPartial
      };
    },

    SET_UTM_ARR(state, utms) {
      state.utms = utms;
    },
    SET_LOADING_TEMPLATE_OBJECT(state, load) {
      state.loadingTemplateObj = load;
    },
  },
  getters: {
    fields: (state) => state.fields,
    currentTemplateId: (state) => state.currentTemplateId,
    sync: state => ('synchronization' in state.templateObj ? state.templateObj.synchronization : false)
  },
};
