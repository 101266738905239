import { IntegrationStatusAltegio } from '@/components/modules/settings/integration/types.ts';
import { AltegioIntegrationData } from '@/models/integrations/Altegio.ts';
import { find } from 'lodash-es';

/**
 * Определения сценария ошибок altegio
 * Если есть фатальные ошибки, то это будет приоритет
 * чем синхронизация
 * @param Integration
 */
const getStatusAltegio = (Integration: AltegioIntegrationData) => {
  const { integrationError, errors } = Integration;

  /* Если нет ошибок = то все ок */
  if (!integrationError) {
    return IntegrationStatusAltegio.SUCCESS;
  }

  /** Ищем ошибку где fatal: true, inSync: false
   * То возращаем что интеграция с фатальными ошибками
   */
  if (find(errors, { fatal: true, inSync: false })) {
    return IntegrationStatusAltegio.DANGER_INT;
  }

  /** Ищем ошибку где fatal: true, inSync: true
   * То возращаем что статус синхры нарушен
   */
  if (find(errors, { fatal: true, inSync: true })) {
    return IntegrationStatusAltegio.DANGER_SYNC;
  }

  /** Ищем ошибку где fatal: false, inSync: true
   * То возращаем что синхронизация прошла с ошибками
   */
  if (find(errors, { fatal: false, inSync: true })) {
    return IntegrationStatusAltegio.WARNING_SYNC;
  }

  /* Если ошибки = и нет совпадений хз
   *  Сюда не дойдет вроде
   *  */
  if (integrationError) {
    return IntegrationStatusAltegio.UNKNOWN;
  }

  return IntegrationStatusAltegio.UNKNOWN;
};

export { getStatusAltegio };
