import { LocaleMessageObject } from 'vue-i18n';

import btnApp from '@/i18n/modules/btnApp.fr.json';
import confinesCard from '@/i18n/modules/confinesCard.fr.json';
import downloadCard from '@/i18n/modules/downloadCard.fr.json';
import PlaceHolder from '@/i18n/modules/PlaceHolder.fr.json';
import registrationCard from '@/i18n/modules/registrationCard.fr.json';

export default ({
  btnApp,
  confinesCard,
  PlaceHolder,
  downloadCard,
  registrationCard,
} as unknown) as LocaleMessageObject;
