import { Module } from 'vuex';
import {
  ExtendedOnboardingStep,
  Onboarding,
  OnboardingStep,
} from '@/models/onboarding/Onboarding.ts';
import OnboardingService from '@/services/onBoarding/OnboardingService.ts';
import Steps from '@/store/modules/onboarding/initialState.ts';
import ExtendedOnboardingStepClass from '@/models/onboarding/ExtendedOnboardingStepClass.ts';
import {
  getPercent,
  inCreaseStep,
  isCurrentStep,
  checkStepsComplete,
  completeStep as completeStepHelper
} from '@/components/modules/onboarding/helpers/OnboardingHelper.ts';

interface RootState {
  version: string;
}

export interface OnboardingState {
  onboarding: Onboarding;
  steps: Array<ExtendedOnboardingStep>;
  loading: boolean;
}

export default {
  namespaced: true,
  actions: {
    async getOnboarding({ commit, getters }) {
      commit('SET_LOADING', true);
      const data = await OnboardingService.getOnboarding();
      commit('SET_ONBOARDING', data);
      commit('SET_STEPS', data.steps);
      commit('FIND_SET_CURRENT', getters.currentStepIndex);
      commit('SET_LOADING', false);
    },
    async updateOnboarding({ commit, getters }, onboarding: Onboarding) {
      const data = await OnboardingService.saveToCompany(onboarding);
      commit('SET_ONBOARDING', data);
      commit('SET_STEPS', data.steps);
      commit('FIND_SET_CURRENT', getters.currentStepIndex);
      return data;
    },
    async nextStep(
      { getters, state, dispatch },
      index = getters.currentStepIndex
    ) {
      await dispatch(
        'updateOnboarding',
        inCreaseStep(state.steps, index, getters.stepsLength)
      );
      return Promise.resolve();
    },
    async skipStep(
      { getters, state, dispatch },
      index = getters.currentStepIndex
    ) {
      await dispatch(
        'updateOnboarding',
        inCreaseStep(state.steps, index, getters.stepsLength, false)
      );
      return Promise.resolve();
    },
    async completeStep(
      { getters, state, dispatch },
      index = getters.currentStepIndex
    ) {
      await dispatch('updateOnboarding', completeStepHelper(state.steps, index, getters.stepsLength));
      return Promise.resolve();
    },
    async connectStep({ dispatch }) {
      await dispatch('nextStep')
      await dispatch('company/getCurrentCompany', '', { root: true })
      return Promise.resolve()
    }
  },
  mutations: {
    SET_LOADING(state, loading: boolean) {
      state.loading = loading;
    },
    SET_ONBOARDING(state, onboarding) {
      state.onboarding = onboarding;
    },
    SET_STEPS(state, steps: Array<OnboardingStep>) {
      state.steps = steps.map((step) => new ExtendedOnboardingStepClass(step));
    },
    FIND_SET_CURRENT(state, indexCur) {
      state.steps = state.steps.map((step, index) => ({
        ...step,
        value: index === indexCur,
      }));
    },
    TOGGLE_STEP(state, systemName) {
      const index = state.steps.findIndex(
        (step) => step.systemName === systemName
      );
      state.steps[index].value = !state.steps[index].value;
    },
  },
  getters: {
    currentStepIndex: (state, getters) =>
      state.steps.findIndex(isCurrentStep) !== -1
        ? state.steps.findIndex(isCurrentStep)
        : getters.stepsLength,
    currentStep: (state) => state.steps.find(isCurrentStep),
    stepsLength: ({ steps }) => steps.length,
    countDoneSteps: ({ steps }) => steps.filter(st => st.isDone).length,
    checkAllStepsComplete: ({ steps }) => checkStepsComplete(steps),
    percent: (state, getters) =>
      getPercent(
        state.onboarding,
        getters.countDoneSteps,
        getters.stepsLength
      ),
  },
  state: {
    loading: false,
    onboarding: { finish: false, steps: Steps },
    steps: Steps.map((step) => new ExtendedOnboardingStepClass(step)),
  },
} as Module<OnboardingState, RootState>;
