import {initialCommonAccesses} from '@/data/intitialAccesses.ts';

const initialNewIntegration = {
  name: '',
  systemName: '',
  fields: [],
  languages: [],
  fieldsLayouts: [],
  plans: [],
  createCard: null,
  deleteCard: null,
  updateCard: null,
  accesses: initialCommonAccesses,
  onboarding: [],
};

export { initialNewIntegration };
