<template>
  <auth-card>
    <div class="text-center mb-15">
      <mailIcon />
    </div>
    <h1 class="text-center auth-card--title">
      {{ $t('AuthPage.checkEmail') }}
    </h1>
    <p class="auth-card--secondary">
      {{ $t(emailText, { email: email }) }}
    </p>
  </auth-card>
</template>

<script>
import mailIcon from '@/assets/svg/mailNew.svg?inline';
import AuthCard from '@/components/modules/auth/layouts/authCard';

export default {
  name: 'emailSendLayout',
  components: { AuthCard, mailIcon },
  props: {
    email: String,
    emailText: {
      type: String,
      default: 'AuthPage.weSend'
    }
  }
};
</script>

<style scoped></style>
