import MongoModelClass from '@/models/MongoModelClass';

class AbstractOneCardClass extends MongoModelClass {
  constructor(card) {
    super();
    Object.assign(this, card);
    this.valuesArr = Object.keys(card.values).map((v) => ({
      name: v,
      value: card.values[v],
    }));
  }

  getCardValuesWithFields(fields) {
    return fields.map((field) => ({
      ...field,
      cardValue: this.setCardValue(field.name),
    }));
  }

  getNotRegisterValues(fields) {
    return this.valuesArr
      .filter((key) => {
        return !fields.find((column) => column.name === key.name);
      })
      .map((key) => {
        return {
          name: key.name,
          value: key.value || "—",
        };
      });
  }

  setCardValue(name) {
    const findValue = this.valuesArr.find((val) => val.name === name);
    return findValue && (findValue.value || typeof findValue.value === 'boolean' || findValue.value === 0) ? findValue.value : "—";
  }

  get lastSyncFormatDate() {
    const date = new Date(this.lastSynchronize * 1000)
    return this.lastSynchronize
      ? `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
      : ''
  }
}

export default AbstractOneCardClass;
