import { RouteConfig } from 'vue-router';

export default {
  path: 'sms',
  name: 'SmsSettings',
  component: () =>
    import(
      /* webpackChunkName: "settings" */ '@/views/settings/sms/SmsSettings.vue'
    ),
  meta: {
    pageTitle: 'Sms',
    defaultTitle: false,
  },
} as RouteConfig;
