import { RouteConfig } from 'vue-router';

export default {
  path: '/cf/:templateId',
  component: () => import(/* webpackChunkName: "confines" */ '@/views/confines/public/index.vue'),
  redirect: '/cf/:templateId/installed-card',
  children: [
    {
      path: 'installed-card',
      name: 'confineInstalledCardPublic',
      component: () => import(/* webpackChunkName: "confines" */ '@/views/confines/public/installedCardPublic.vue'),
    },
    {
      path: 'deleted-card',
      name: 'confineDeletedCardPublic',
      component: () => import(/* webpackChunkName: "confines" */ '@/views/confines/public/deletedCardPublic.vue'),
    },
    {
      path: 'subscribe-end',
      name: 'confineSubscribePublic',
      component: () => import(/* webpackChunkName: "confines" */ '@/views/confines/public/subscribeEndPublic.vue'),
    },
  ],
} as RouteConfig
