import { LocalStorageAuthKeys } from '@/components/modules/auth/shared/shared';
import { ref } from '@vue/composition-api';
import authService from '@/services/auth/authService';

export const checkStorageExist = () => !!localStorage.getItem(LocalStorageAuthKeys.RESET_EMAIL)

export const checkEmailQuery = (routeQuery = {}) => {
  // eslint-disable-next-line no-prototype-builtins
  return routeQuery && routeQuery.hasOwnProperty('tokenValue') && routeQuery.hasOwnProperty('email')
}
/**
 * @deprecated
 * @param tokenValue
 * @param email
 * @return {Promise<T|boolean>}
 */
export const checkValidToken = async ({ tokenValue, email }) => {
  const data = await authService.CheckTokenFromEmail(email, tokenValue)
    .then(() => true)
    .catch(() => false)
  return data
}
export function useResetPasswordEmail() {
  const email = ref(localStorage.getItem(LocalStorageAuthKeys.RESET_EMAIL))
  const sendStatus = ref(false);
  const loading = ref(false);

  const sendEmailDouble = () => {
    loading.value = true;
    authService
      .resetPassword(email.value)
      .finally(() => {
        sendStatus.value = true;
        loading.value = false
      });
  };

  return {
    email,
    sendEmailDouble,
    loading,
    sendStatus
  }
}
