import ApiWrap from '@/services/ApiWrap.ts';
import {
  InvoicesItems,
  InvoiceId,
  InvoiceStatuses,
  CreateResponse,
  PayResponse,
  Invoice,
  DeleteResponse,
} from '@/models/Invoices.ts';
import { IBackendResponse } from '@/services/types.ts';

class InvoicesService extends ApiWrap {
  /**
   * Метод создаёт счёт на оплату в Stripe по продуктам items
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/499417098/Create
   * @param items
   */
  static async create(items: InvoicesItems): Promise<CreateResponse> {
    const { data } = await this.post<
      IBackendResponse<CreateResponse>,
      { items: InvoicesItems }
    >(
      '/invoices/create',
      { items },
      { errorSuppression: true }
    ).then((res) => res.data);

    return data;
  }

  /**
   * Метод оплачивает счёт в Stripe с идентификатором id
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/499482635/Pay
   * @param id
   */
  static async pay(id: InvoiceId): Promise<PayResponse> {
    const { data } = await this.get<IBackendResponse<PayResponse>>(
      '/invoices/pay',
      { params: { id } }
    ).then((res) => res.data);

    return data;
  }

  /**
   * Метод удаляет счёт на оплату в Stripe с идентификатором id
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/499417144/Delete
   * @param id
   */
  static async destroy(id: InvoiceId): Promise<DeleteResponse> {
    const { data } = await this.get<IBackendResponse<DeleteResponse>>(
      '/invoices/destroy',
      { params: { id } }
    ).then((res) => res.data);

    return data;
  }

  /**
   * Метод возвращает список счетов со статусом status начиная со счёта
   * с идентификатором startingAfter
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/499482642/List
   * @param status
   * @param startingAfter
   */
  static async list(status: InvoiceStatuses, startingAfter: InvoiceId): Promise<Array<Invoice>> {
    const { data } = await this.get<IBackendResponse<Array<Invoice>>>(
      '/invoices/list',
      { params: { status, startingAfter } }
    ).then((res) => res.data);

    return data;
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/748584970/verificationSmsPay
   * Метод позволяет купить смс по цене подтверждения номера телефона в почте
   * @param quantity
   */
  static async verificationSmsPay(quantity: number) {
    const { data } = await this.post<
      IBackendResponse<CreateResponse>,
      { quantity: number }
      >(
        '/invoices/verificationSmsPay',
        { quantity },
        { errorSuppression: true }
      ).then((res) => res.data);

    return data;
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/499417098/Create
   * Оплата счета
   * @param id
   */
  static async payById(id: string) {
    const { data } = await this.post<IBackendResponse<PayResponse>>(
      `/invoices/pay?id=${id}`
    ).then((res) => res.data);

    return data;
  }
}

export default InvoicesService;
