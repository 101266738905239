import { RouteConfig } from 'vue-router';

export default {
  path: 'segments',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  children: [
    {
      path: '/',
      alias: 'list',
      name: 'SegmentsList',
      component: () => import(/* webpackChunkName: "templatePattern" */ '@/views/admin/segments/SegmentsAdminIndex.vue'),
      meta: {
        pageTitle: 'layoutMain.segments',
        defaultTitle: false
      }
    },
    {
      path: 'list/:id',
      name: 'EditSegments',
      component: () => import(/* webpackChunkName: "templatePattern" */ '@/views/admin/segments/EditAdminSegments.vue'),
      meta: {
        defaultTitle: false
      }
    },
    {
      path: 'add',
      name: 'SegmentsAdd',
      component: () => import(/* webpackChunkName: "templatePattern" */ '@/views/admin/segments/AddSegmentsAdmin.vue'),
      meta: {
        defaultTitle: false
      }
    },
  ],
} as RouteConfig;
