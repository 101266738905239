import { addInfoCompanyToTools } from '@/helpers/user/UserAppliedTools.ts';
import PlanService from '@/services/plan/PlanService.ts';
import { access } from '@/store/modules/plan/initialState.ts';
import { has } from 'lodash-es';
import { IntegrationsNamesT } from '@/helpers/integrationsNamesT.ts';
import { Company, companyPathSave } from '@/models/company/Company.ts';
import { company as defaultCompany } from '@/models/company/initialState.ts';
import { plan as initialPlanData } from '@/models/plan/initialData.ts';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import companyService from '@/services/company/companyService.ts';
import { calculatePresentSync, getTemplateID } from '@/helpers/company';
import { Poster, YclientsNew } from '@/helpers/integrationNames';
import { Plan } from '@/models/plan/Plan.ts';
import CompanyClass from '@/models/company/CompanyClass.ts';
import pusher from '@/plugins/pusher.ts';
import {
  SynchronizationData,
  IntegrationData,
} from '@/models/integrations/Integration.ts';
import IntegrationsService from '@/services/integrations/integrationsService.ts';

export interface CompanyState {
  currentCompany: CompanyClass;
  loadingCompany: boolean;
  loadingPlan: boolean;
  activePlan: Plan | undefined;
}
interface RootState {
  version: string;
}
const actions: ActionTree<CompanyState, RootState> = {
  async getCurrentCompany({ dispatch, commit, getters }) {
    commit('SET_LOADING_COMPANY', true);
    const data = await companyService.getCompany();
    commit('SET_CURRENT_COMPANY', data);
    if (getters.integration) {
      await dispatch('integrationsDispatch');
    } else {
      dispatch('integration/clearDataIntegration', true, { root: true });
      dispatch('integration/yclients/clearIntegrationData', true, {
        root: true,
      });
      dispatch('integration/altegio/clearIntegrationData', true, {
        root: true,
      });
    }
    dispatch('getActivePlan', getters.planID);
    commit('passTemplate/SET_CURRENT_TEMPLATE', getters.currentTemplateId, {
      root: true,
    });
    if (getters.currentTemplateId) {
      await dispatch('passTemplate/getTemplateObject', true, { root: true });
    }
    addInfoCompanyToTools(data);
    commit('cards/SET_FIRST_START', true, { root: true });
    commit('SET_LOADING_COMPANY', false);
    return data;
  },
  async integrationsDispatch({ dispatch, getters }) {
    if (getters.integration === IntegrationsNamesT.YCLIENTS_NEW) {
      await dispatch('integration/yclients/getIntegrationData', true, {
        root: true,
      });
    }
    if (getters.integration === IntegrationsNamesT.ALTEGIO) {
      await dispatch('integration/altegio/getIntegrationData', true, {
        root: true,
      });
    }
    return Promise.all([
      dispatch('integration/getCurrentIntegration', getters.integration, {
        root: true,
      }),
      dispatch('integration/getCurrentIntegrationData', getters.companyId, {
        root: true,
      }),
    ]);
  },
  async saveIntegrationData(
    { commit, state },
    { integrationData, rewrite = false }
  ) {
    const data = rewrite
      ? integrationData
      : {
          ...state.currentCompany.integrationData,
          ...integrationData,
        };
    const res = await IntegrationsService.saveIntegrationData(data);
    commit('SET_INTEGRATION_DATA', res.integrationData);
  },
  // Нужно чтоб метод response возращал
  changeCurrentCompanyInfo(
    { commit },
    { id, companyParam }: { id: string; companyParam: Company }
  ) {
    return new Promise((resolve, reject) => {
      companyService
        .changeCompanyInfo({ id, company: companyParam })
        .then((data) => {
          if (typeof data !== 'string') {
            commit('SET_CURRENT_COMPANY', data);
            resolve(true);
          }
          reject();
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  async invisibleReloadCompany({ dispatch, commit, getters }) {
    const data = await companyService.getCompany();
    commit('SET_CURRENT_COMPANY', data);
    if (getters.integration) {
      await dispatch('integration/getCurrentIntegration', getters.integration, {
        root: true,
      });
    }
  },
  async getReloadPlan({ commit, getters }) {
    const companyData = await companyService.getCompany();
    commit('SET_CURRENT_COMPANY', companyData);
    const data = await PlanService.getPlan(getters.planID);
    commit('SET_ACTIVE_PLAN', data);
  },
  async getActivePlan({ commit }, companyId) {
    commit('SET_LOADING_PLAN', true);
    const data = await PlanService.getPlan(companyId);
    commit('SET_ACTIVE_PLAN', data);
    commit('SET_LOADING_PLAN', false);
  },
  async saveCompany({ commit, state }, payload: Partial<Company>) {
    const upd = { ...state.currentCompany, ...payload };
    const data = await companyService.saveCompanyNew(upd);
    commit('SET_CURRENT_COMPANY', data);
    return data;
  },
  async patchSaveCompany({ commit }, payload: companyPathSave) {
    const data = await companyService.company(payload);
    /* Временно */
    const upd = await companyService.getCompany();
    commit('SET_CURRENT_COMPANY', upd);
    return upd;
  },
  subscribeSocket({ commit, getters, dispatch }) {
    const { companyId } = getters;
    const channel = pusher.subscribe(`company_${companyId}`);
    channel.bind('synchronization', (data: SynchronizationData) => {
      commit('SET_UPDATE_COMPANY', { synchronizationData: data });
      if (!data.synchronization) {
        dispatch('getCurrentCompany');
      }
    });
    channel.bind('migrations', (data: SynchronizationData) => {
      commit('SET_UPDATE_COMPANY', { synchronizationData: data });
      dispatch('invisibleReloadCompany');
    });
  },
};
const mutations: MutationTree<CompanyState> = {
  SET_UPDATE_COMPANY(state, payload: Partial<Company>) {
    state.currentCompany = new CompanyClass({
      ...state.currentCompany,
      ...payload,
    });
  },
  SET_CURRENT_COMPANY(state, payload: Company) {
    state.currentCompany = new CompanyClass(payload);
  },
  SET_ACTIVE_PLAN(state, plan) {
    state.activePlan = plan;
  },
  SET_LOADING_COMPANY(state, val) {
    state.loadingCompany = val;
  },
  SET_LOADING_PLAN(state, val) {
    state.loadingPlan = val;
  },
  SET_ACCOUNT_SETUP_STEP(state, step) {
    state.currentCompany.onboardingStep = step;
  },
  SET_INTEGRATION_DATA(state, val) {
    state.currentCompany.integrationData = val;
  },
};
const getters: GetterTree<CompanyState, RootState> = {
  // eslint-disable-next-line no-underscore-dangle
  planID: (state) => state.currentCompany.plan._id,
  companyId: (state) => state.currentCompany?._id.$oid ?? null,
  accesses: (state) => state.activePlan?.accesses ?? access,
  onboardingStep: (state) => state.currentCompany?.onboardingStep ?? 0,
  company: (state) => state.currentCompany,
  companyName: (state) => state.currentCompany.name,
  integration: (state) => state.currentCompany.integration,
  currentTemplateId: (state) =>
    has(state.currentCompany, 'templates')
      ? getTemplateID(state.currentCompany.templates)
      : null,
  loadingCompany: (state) => state.loadingCompany,
  percentSync: (state) =>
    'integrationData' in state.currentCompany
      ? calculatePresentSync(state.currentCompany.integrationData)
      : 0,
  integrationStatusPoster: (state) =>
    state.currentCompany.integration === IntegrationsNamesT.POSTER &&
    state.currentCompany.blockForm,
  integrationError: (state) => state.currentCompany.integrationError,
  // Todo: Refactoring need
  integrationStatus: (state) => {
    if (
      state.currentCompany.integration === Poster ||
      state.currentCompany.integration === YclientsNew
    ) {
      if (state.currentCompany.blockForm) {
        return 'off';
      }
      return 'on';
    }
    return false;
  },
  isFromRussia: (state) => state.currentCompany.countryCode === 'RU',
  isStandalone: (state) =>
    state.currentCompany.registerForm.nameCrm === 'withoutCrm',
};
const state: CompanyState = {
  currentCompany: new CompanyClass(defaultCompany),
  loadingCompany: true,
  loadingPlan: true,
  activePlan: initialPlanData,
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
} as Module<CompanyState, RootState>;
