import { RouteConfig } from 'vue-router';

export default {
  path: 'automation',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  redirect: {
    name: 'AllAutomation',
  },
  meta: {
    requiredAuth: true,
  },
  children: [
    {
      path: 'all',
      name: 'AllAutomation',
      component: () => import(/* webpackChunkName: 'automation' */ '@/views/automation/all/index.vue'),
      meta: {
        pageTitle: 'layoutMain.automation',
        defaultTitle: false,
      },
    },
    {
      path: ':id',
      name: 'AutomationID',
      component: () => import(/* webpackChunkName: 'automation' */ '@/views/automation/id/AutomationID.vue'),
      meta: {
        pageTitle: 'layoutMain.automation',
        defaultTitle: false,
      },
    },
    {
      path: 'create',
      name: 'CreateAutomation',
      component: () => import(/* webpackChunkName: 'automation' */ '@/views/automation/create/CreateAutomation.vue'),
      meta: {
        pageTitle: 'layoutMain.automation',
        defaultTitle: false,
      },
    },
    {
      path: 'edit/:id',
      name: 'CreateAutomationID',
      component: () => import(/* webpackChunkName: 'automation' */ '@/views/automation/create/CreateAutomation.vue'),
      meta: {
        pageTitle: 'layoutMain.automation',
        defaultTitle: false,
      },
    },
  ],
} as RouteConfig;
