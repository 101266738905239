import { IntegrationStatusYclients } from '@/components/modules/settings/integration/types.ts';
import { YclientsIntegrationData } from '@/models/integrations/Yclients.ts';
import { find } from 'lodash-es';

/**
 * Определения сценария ошибок yclients
 * Если есть фатальные ошибки, то это будет приоритет
 * чем синхронизация
 * @param Integration
 */
const getStatusYclients = (Integration: YclientsIntegrationData) => {
  const { integrationError, errors } = Integration;

  /* Если нет ошибок = то все ок */
  if (!integrationError) {
    return IntegrationStatusYclients.SUCCESS;
  }

  /** Ищем ошибку где fatal: true, inSync: false
   * То возращаем что интеграция с фатальными ошибками
   */
  if (find(errors, { fatal: true, inSync: false })) {
    return IntegrationStatusYclients.DANGER_INT;
  }

  /** Ищем ошибку где fatal: true, inSync: true
   * То возращаем что статус синхры нарушен
   */
  if (find(errors, { fatal: true, inSync: true })) {
    return IntegrationStatusYclients.DANGER_SYNC;
  }

  /** Ищем ошибку где fatal: false, inSync: true
   * То возращаем что синхронизация прошла с ошибками
   */
  if (find(errors, { fatal: false, inSync: true })) {
    return IntegrationStatusYclients.WARNING_SYNC;
  }

  /* Если ошибки = и нет совпадений хз
   *  Сюда не дойдет вроде
   *  */
  if (integrationError) {
    return IntegrationStatusYclients.UNKNOWN;
  }

  return IntegrationStatusYclients.UNKNOWN;
};

export { getStatusYclients };
