import { RouteConfig } from 'vue-router';

export default {
  path: '/feedback',
  component: {
    render: (h) => h('div', { class: { containerFeedback: true } }, [h('router-view')]),
  },
  name: 'FeedbackPublic',
  children: [
    {
      path: 'cards',
      name: 'FeedbackPublicCards',
      component: () =>
        import(
          /* webpackChunkName: "form" */ '@/views/feedback/FeedbackCardErrors.vue'
        ),
      meta: {
        defaultTitle: false,
      },
    },
  ],
} as RouteConfig;
