import { axiosServer } from '@/http-common.ts';
import qs from "qs";
import InvoicesService from '@/services/invoices/InvoicesService.ts';

export default {

    async getPaymentMethods({commit}, companyId) {
        try {
            let data = await axiosServer({
                method: 'POST',
                headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
                data: qs.stringify(
                    {companyId: companyId}
                ),
                url: '/payments/getPaymentMethods',
            })
            if (data.data.error === false) {
                commit('GET_CURRENT_PAYMENT_METHODS', data.data.data)
            } else {
                commit('GET_CURRENT_PAYMENT_METHODS', false)
            }
        } catch (e) {
            console.error(e)
        }
    },

    async createStripePaymentMethod ({commit}, {stripe, cardElement, cardOwner}) {
        try {
            const data = await stripe.createPaymentMethod(
                {
                    type: 'card',
                    card: cardElement,
                    billing_details: {
                        name: cardOwner,
                    },
                }
            )
            commit('GET_STRIPE_PAYMENT_METHOD', data.paymentMethod)
        } catch (e) {
            console.error(e)
        }
    },

    async changeDefaultPaymentMethod ({commit}, {companyId, stripePaymentMethod, stripe, clientKey}) {
        try {
            let data = await axiosServer({
                method: 'POST',
                headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
                data: qs.stringify(
                    {
                        companyId: companyId,
                        paymentMethod: stripePaymentMethod
                    }
                ),
                url: '/payments/changeDefaultPaymentMethod',
            })
            if (data.data.error === false) {
                stripe.confirmCardSetup(clientKey, {
                    payment_method: stripePaymentMethod.id
                })
            }
            return data.data
        } catch (e) {
            console.error(e)
        }
    },

    async getInvoices({ commit }, invoicesInfo) {
        const invoices = await InvoicesService.list('paid').then((res) => res.data);

        commit('GET_CURRENT_INVOICES', invoices);

        return invoices;
    },

    async getStripeSubscription({commit}, subscriptionId) {
        try {
            let data = await axiosServer({
                method: 'POST',
                headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
                data: qs.stringify(
                    {subscriptionId:subscriptionId,}
                ),
                url: '/payments/getStripeSubscription',
            })
            if (data.data.error === false) {
                commit('GET_CURRENT_SUBSCRIPTION', data.data.data)
            } else {
            }
        } catch (e) {
            console.error(e)
        }

    },

    async checkStripeCoupon ({commit}, {companyId, coupon}) {
        try {
            let data = await axiosServer({
                method: 'POST',
                headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
                data: qs.stringify(
                    {
                        companyId:companyId,
                        coupon: coupon,
                    }
                ),
                url: '/payments/checkStripeCoupon',
            })
            return data.data
        } catch (e) {
            console.error(e)
        }
    },

    async  applyStripeCoupon ({commit}, {subscriptionId, coupon}) {
        try {
            let data = await axiosServer({
                method: 'POST',
                headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
                data: qs.stringify(
                    {
                        subscriptionId:subscriptionId,
                        coupon: coupon,
                    }
                ),
                url: '/payments/applyCoupon',
            })
            commit('APPLY_STRIPE_COUPON', data.data)
            return data.data
        } catch (e) {
            console.error(e)
        }
    },

    async getUpcomingInvoice ({commit}, subscriptionId) {
        try {
            let data = await axiosServer({
                method: 'POST',
                headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
                data: qs.stringify(
                    {
                        subscriptionId:subscriptionId,
                    }
                ),
                url: '/payments/getUpcomingInvoice',
            })
            commit('GET_UPCOMING_INVOICE', data.data.data)
            return data.data
        } catch (e) {
            console.error(e)
        }
    },



}
