const initialEditForm = {
  sendersNameList: [],
  script: '',
  templateId: '',
  phoneValue: '',
  emailValue: '',
  smsIntegrations: {
    senderName: '',
    userLogin: '',
    balance: 0,
    userPassword: '',
  },
  formValues: [
    {
      value: '',
      userValueName: '',
      required: false,
      type: '',
      data: [
        {
          label: '',
          value: '',
        },
      ],
    },
  ],
  agreementUrl: '',
  restrictionUrl: '',
  callbackUrl: '',
  authenticationType: '',
  formAppearance: {
    background: '#f8f8f8',
    buttonColor: '#329ef2',
    textColor: 'white',
    logoColor: '#808080',
  },
  useAppleSign: false,
  useGoogleSign: false,
  logoUrl: '',
  shortUrls: [],
};

export {initialEditForm}
