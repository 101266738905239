import { RouteConfig } from "vue-router";

export default {
  name: 'SubscriptionEnded',
  path: 'subscription-ended',
  meta: {
    defaultTitle: false
  }, 
  component: () => import(
    /* webpackChunkName: "SubscriptionEnded" */ '@/views/SubscriptionEnded.vue'
  )
} as RouteConfig;