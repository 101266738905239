import { RouteConfig } from 'vue-router';

export default {
  path: '/onboarding',
  name: 'OnboardingNew',
  meta: {
    pageTitle: 'Common.GettingStarted',
    defaultTitle: false,
  },
  component: () => import(/* webpackChunkName: "onboarding" */'@/views/Onboarding.vue'),
} as RouteConfig;
