import { axiosServer } from '@/http-common.ts';
import * as qs from 'qs';
import { useErrors } from '@/composition/errorsComposition';

const { handlerErrorsAxios } = useErrors();

class FormService {
  /**
   * @deprecated
   * @param templateId
   * @return {Promise<*>}
   */
  async getForm(templateId) {
    const {
      data: { data },
    } = await axiosServer
      .post('/form/getForm', qs.stringify({ templateId }), {
        errorSuppression: true,
      })
      .catch(handlerErrorsAxios);
    return data;
  }
  async SaveForm(form) {
    const {
      data: { data },
    } = await axiosServer
      .post('/form/saveForm', qs.stringify(form), {
        errorSuppression: true,
      })
      .catch(handlerErrorsAxios);
    return data;
  }
  async removeForm(formId) {
    const {
      data: { data },
    } = await axiosServer
      .post('/form/deleteForm', qs.stringify({ id: formId }), {
        errorSuppression: true,
      })
      .catch(handlerErrorsAxios);
    return data;
  }

  /**
   * Метод позволяет редактировать статус блокировки формы.
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/348815361/blockForm
   * @deprecated
   */
  async blockForm(formInfo) {
    const {
      data: { data },
    } = await axiosServer
      .post('/form/blockForm', formInfo, {
        errorSuppression: true,
      })
      .catch(handlerErrorsAxios);
    return data;
  }
}
export default new FormService();
