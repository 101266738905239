<template>
  <div
    :style="{
      width: size || width,
      height: size || height
    }"
    class="d-flex justify-content-center align-items-center"
    v-b-tooltip="{ disabled: !tooltipTextContainer, title: tooltipTextContainer }"
  >
    <slot>
      <pt-icon
        v-b-tooltip="{ disabled: !tooltipText, title: tooltipText }"
        :icon="icon"
        :width="iconSize || iconWidth"
        :height="iconSize || iconHeight"
        :class="iconClass"
        :variant="variant"
      />
    </slot>
  </div>
</template>

<script>
import PtIcon from './PtIcon.vue';

export default {
  name: 'PtIconContainer',
  components: { PtIcon },
  props: {
    icon: {
      type: String,
      default: 'smile',
    },
    iconWidth: {
      type: String,
      default: '14px',
    },
    iconHeight: {
      type: String,
      default: '14px',
    },
    iconClass: {
      type: String,
    },
    width: {
      type: String,
      default: '20px',
    },
    height: {
      type: String,
      default: '20px',
    },
    iconSize: {
      type: String,
    },
    size: {
      type: String,
    },
    variant: {
      type: String,
    },
    tooltipText: {
      type: String,
    },
    tooltipTextContainer: {
      type: String,
    },
  },
};
</script>
