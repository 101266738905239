import ApiWrap from '@/services/ApiWrap.ts';
import {
  BasicModelYclients,
  YclientsAuthResponse, YclientsCompany, YclientsGroups,
  YclientsIntegrationData,
  YclientsSynchronizationData
} from '@/models/integrations/Yclients.ts';
import { IBackendResponse } from '@/services/types.ts';
import {Company} from "@/models/company/Company";
import { ActionsRequestCards } from '@/models/cards/CardsClients.ts';

interface AuthYclients {
  login: string;
  password: string;
}

class YclientsService extends ApiWrap {
  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/371720196/Auth
   * @param login
   * @param password
   */
  static async auth(login: string, password: string): Promise<YclientsAuthResponse> {
    const data = await this.post<IBackendResponse<YclientsAuthResponse>, AuthYclients>('/integrations/yclients/auth', { login, password }, { errorSuppression: true })
      .then(res => res.data.data)
    return data;
  }

  /**
   * Метод позволяет вернуть все сохраненные раннее настройки интеграции
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/371916834/getIntegrationData
   * @return {Promise<YclientsIntegrationData>}
   */
  static async getIntegrationData(): Promise<YclientsIntegrationData> {
    const { data } = await this.get<IBackendResponse<YclientsIntegrationData>>('/integrations/yclients/getIntegrationData')
      .then(res => res.data)
    return data
  }

  static async saveIntegrationData(integration: YclientsIntegrationData): Promise<YclientsIntegrationData> {
    const { data } = await this.post<IBackendResponse<YclientsIntegrationData>, YclientsIntegrationData>('/integrations/yclients/saveIntegrationData', integration)
      .then(res => res.data)
    return data
  }

  /**
   * Метод позволяет получить список доступных для сети типов лояльности
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/371752982/getLoyaltyTypes
   */
  static async getLoyaltyTypes(): Promise<Array<BasicModelYclients>> {
    const { data } = await this.get<IBackendResponse<Array<BasicModelYclients>>>('/integrations/yclients/getLoyaltyTypes')
      .then(res => res.data)
    return data
  }

  /**
   * Метод позволяет получить список доступных для пользователя сетей в   Yclients
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/371916808/getGroups
   */
  static async getGroups(): Promise<Array<YclientsGroups>> {
    const { data } = await this.get<IBackendResponse<YclientsGroups>>('/integrations/yclients/getGroups')
      .then(res => res.data)
    return data
  }

  /**
   * Метод позволяет получить список доступных филиалов для выбранного типа карт
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/372244485/getCompanies
   */
  static async getCompanies(): Promise<Array<YclientsCompany>> {
    const { data } = await this.get<IBackendResponse<YclientsGroups>>('/integrations/yclients/getCompanies')
      .then(res => res.data);

    return data;
  }

  static async getSynchronizationData(): Promise<YclientsSynchronizationData> {
    const { data } = await this.get<IBackendResponse<YclientsGroups>>('/integrations/yclients/getSynchronizationData')
      .then(res => res.data)
    return data
  }

  /**
   * Метод позволяет запустить синхронизацию клиента
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/372244498/startSynchronization
   */
  static async startSynchronization(): Promise<YclientsSynchronizationData> {
    const { data } = await this.get<IBackendResponse<YclientsSynchronizationData>>('/integrations/yclients/startSynchronization')
      .then(res => res.data)
    return data
  }

  /**
   * Позволяет поменять статуса поля компании integrationErrorStatus
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/400687105/hangeIntegrationErrorStatus
   * @param value
   */
  static async changeIntegrationErrorStatus(value: boolean) {
    const { data } = await this.post<IBackendResponse<YclientsIntegrationData>, Pick<YclientsIntegrationData, 'integrationError'>>('/integrations/changeIntegrationErrorStatus', { integrationError: value })
      .then(res => res.data)
    return data
  }

  /**
   * Метод позволяет обновить список филиалов в шаблоне, если выбран скрипт “На выбор клиента”
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/371687442/syncCompaniesList
   */
  static async syncCompaniesList(): Promise<YclientsIntegrationData> {
    const { data } = await this.get<IBackendResponse<YclientsIntegrationData>>('/integrations/yclients/syncCompaniesList')
      .then(res => res.data)
    return data
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/705429505/AddPhoneToCards
   */
  static async addPhoneCards() {
    const { data } = await this.get<IBackendResponse<string>>('/integrations/yclients/addPhoneToCards')
      .then(res => res.data)
    return data
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/772898817/disableIntegration+YclientsNew
   */
  static async disableIntegration(disableService?: boolean) {
    const { data } = await this.post<IBackendResponse<Company>, null>(
      '/integrations/yclients/disableIntegration', {
        disableService
      })
      .then(res => res.data)
    return data
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/770048003/changeAddCardToClientScript
   */
  static async changeAddCardToClientScript(addCardAfterInstall: boolean) {
    const { data } = await this.post<IBackendResponse<Company>, { addCardAfterInstall: boolean }>('/integrations/yclients/changeAddCardToClientScript',
      { addCardAfterInstall })
      .then(res => res.data)
    return data
  }

  /**
   * Индивидуальная синхронизация по идентификатору карты
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/822706177/Yclients+SyncCard
   * @returns {Promise<string>}
   * @param cardId
   */
  static async syncCard(cardId: string): Promise<string> {
    const { data } = await this.post<IBackendResponse<string>>(
      '/integrations/yclients/syncCard',
      cardId,
    ).then((res) => res.data);
    return data;
  }

  /**
   * Метод позволяет осуществляет идентивидуальную синхронизацию
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/822542337/Yclients+syncByFilter
   * @returns {Promise<string>}
   * @param cardsFilter
   */
  static async syncByFilter(cardsFilter: ActionsRequestCards): Promise<string> {
    const { data } = await this.post<IBackendResponse<string>>(
      '/integrations/yclients/syncByFilter',
      cardsFilter,
    ).then(res => res.data)
    return data
  }

  /**
   * Метод позволяет сбросить ошибку о удалении типа карт
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/829882371/resetIntegrationErrors
   * @returns {Promise<string>}
   */
  static async resetIntegrationErrors(): Promise<string> {
    const { data } = await this.post<IBackendResponse<string>>(
      '/integrations/yclients/resetIntegrationErrors'
    ).then(res => res.data)
    return data
  }

  /**
   * Возвращает количество клиентов в Yclients
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/849346561/getTotalCustomers
   */
   static async getTotalCustomers(): Promise<number> {
    const { data } = await this.get<IBackendResponse<number>>(
      '/integrations/yclients/getTotalCustomers'
    ).then(res => res.data);
    return data;
  }
}

export default YclientsService;
