import { RouteConfig } from 'vue-router';

export default {
  path: '/other',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  children: [
    {
      path: 'idea',
      name: 'NewIdea',
      component: () => import(/* webpackChunkName: "other" */ '@/views/other/NewIdea.vue'),
      meta: {
        pageTitle: 'Common.Idea',
        defaultTitle: false
      }
    },
    {
      path: 'search',
      name: 'SearchDev',
      component: () => import(/* webpackChunkName: "other" */ '@/views/other/SearchDev.vue'),
      meta: {
        defaultTitle: false
      }
    },
  ],
} as RouteConfig;
