<template>
  <button
    class="btn btn-primary btn-lg btn-block btn-loading auth-card--btnSubmit mt-30"
    :class="{ 'loading-block': loading }"
    type="submit"
    :disabled="disabled || loading"
  >
    <span class="btn-text">
      {{ $t(btnName) }}
    </span>
    <b-spinner class="btn-spinner" small label="Spinning"></b-spinner>
  </button>
</template>

<script>
export default {
  name: 'submitBtn',
  props: {
    btnName: {
      type: String,
      default: 'btnApp.enter',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
