import store from '@/store';

export function useErrors() {
  const addGlobalError = (error) => {
    store.commit('appPass/SET_GLOBAL_ERROR', true); // just taking some guesses here
    store.commit('appPass/SET_GLOBAL_ERROR_MSG', error); // just taking some guesses here
  };

  const responseErrorNotification = (error) => {
    store.dispatch('alerts/add', {
      text: 'Common.Error',
      textBody: error.response.data.data,
      type: 'danger',
      timeout: 6000,
    });
  };

  const checkDataErrors = (res) => {
    if (res.data.code > 200) return Promise.reject(res)
    return res
  }

  const handlerErrorsAxios = (error) => {
    error.response ? responseErrorNotification(error) : addGlobalError(error);
    throw error;
  };

  /**
   * Такой же как handlerErrorsAxios
   * Для TS
   * Проверяем есть ли response
   * если нет глобальная ошибка
   * если есть то вывод ошибки из бека в нотификатор
   * @param error
   * @returns {{response}|*}
   */
  const handlerErrorsAxiosReturn = (error) => {
    if (!error.response) addGlobalError(error)
    else responseErrorNotification(error)

    return error;
  };

  /**
   * deprecated
   * Unreachable code
   * @param error
   * @returns {{response}|*|void}
   */
  const handlerErrorsAxiosSimple = (error) => {
    return error.response ? error : addGlobalError(error);
    throw error;
  };

  /**
   * Проверяем есть ли response
   * если нет глобальная ошибка
   * @param error
   */
  const handlerErrorsAxiosPromise = (error) => {
    if (!error.response) addGlobalError(error)
    throw error
  };

  return {
    addGlobalError,
    responseErrorNotification,
    handlerErrorsAxios,
    handlerErrorsAxiosSimple,
    handlerErrorsAxiosPromise,
    handlerErrorsAxiosReturn,
    checkDataErrors
  };
}
