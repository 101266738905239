<template>
  <auth-card>
    <h1 class="auth-card--title">
      {{ $t('Common.ChangePassword') }}
    </h1>
    <change-password-form />
  </auth-card>
</template>

<script>
import AuthCard from '@/components/modules/auth/layouts/authCard.vue';
import ChangePasswordForm from '@/components/modules/auth/form/changePasswordForm.vue';
import {
  checkEmailQuery,
  checkValidToken,
} from '@/components/modules/auth/composition/resetPasswordEmail';
// https://frontend.getpass.test.walletpass.ru/vue/dist/auth/reset?tokenValue=302e31313831343530302031363034323232313131323939363835323136&email=golyshev%40passteam.ru
export default {
  name: 'changePassword',
  components: { ChangePasswordForm, AuthCard },
  beforeRouteEnter(to, from, next) {
    if (!checkEmailQuery(to.query)) next({ name: 'ResetPassword' });
    checkValidToken(to.query)
      .then(res => (res ? next() : next({ name: 'ResetPassword' })))
  },
};
</script>

<style scoped></style>
