const mutations = {

  SET_ACTIVE_COMPANY(state, company) {
    state.currentCompany = company
  },

  SET_ACTIVE_COMPANY_NAME(state, companyName) {
    state.currentCompany.name = companyName
  },

  SET_ACTIVE_COMPANY_URL(state, data) {
    state.currentCompany.siteUrl = data
  },

  SET_ACTIVE_COMPANY_POSTAL_CODE(state, code) {
    state.currentCompany.address.postalCode = code
  },

  SET_ACTIVE_COMPANY_COUNTRY(state, data) {
    state.currentCompany.address.country = data
  },

  SET_ACTIVE_COMPANY_ADMINISTRATIVE_ARIA_LEVEL_ONE(state, data) {
    state.currentCompany.address.administrativeAriaLevelOne = data
  },

  SET_ACTIVE_COMPANY_ADMINISTRATIVE_ARIA_LEVEL_TWO(state, data) {
    state.currentCompany.address.administrativeAriaLevelTwo = data
  },

  SET_ACTIVE_COMPANY_LOCALITY(state, data) {
    state.currentCompany.address.locality = data
  },

  SET_ACTIVE_COMPANY_ROUTE(state, data) {
    state.currentCompany.address.route = data
  },

  SET_ACTIVE_COMPANY_STREET_NUMBER(state, data) {
    state.currentCompany.address.streetNumber = data
  },

  SET_ACTIVE_COMPANY_BUILDING_NUMBER(state, data) {
    state.currentCompany.address.buildingNumber = data
  },

  SET_ACTIVE_COMPANY_BLOCK_NUMBER(state, data) {
    state.currentCompany.address.blockNumber = data
  },

  SET_ACTIVE_COMPANY_ROOM_NUMBER(state, data) {
    state.currentCompany.address.roomNumber = data
  },

  GET_ACTIVE_PLAN(state, plan) {
    state.activePlan = plan
  },

  SET_ACTIVE_PLAN(state, plan) {
    state.activePlan = plan
  },

  GET_ACTIVE_COMPANY_ID(state, id) {
    state.currentCompany = id
  },

  GET_ACTIVE_TEMPLATE_ID(state, id) {
    state.activeTemplateId = id
  },

  SET_ACTIVE_PLAN_ID(state, id) {
    state.activePlanId = id
  },

  SET_ACTIVE_TEMPLATE_ID(state, id) {
    state.activeTemplateId = id
  },

  GET_CURRENT_USER(state, user) {
    state.currentUser = user
  },

  GET_CURRENT_USER_NAME(state, name) {
    state.currentUser.name = name
  },

  GET_CURRENT_USER_SURNAME(state, surname) {
    state.currentUser.surname = surname
  },

  GET_CURRENT_USER_PHONE(state, phone) {
    state.currentUser.phone = phone
  },

  GET_CURRENT_USER_EMAIL(state, email) {
    state.currentUser.email = email
  },

  GET_CURRENT_COMPANY(state, company) {
    state.currentCompany = company
  },

  GET_CURRENT_TEMPLATE(state, id) {
    state.currentTemplateId = id
  },

  GET_CURRENT_USE_ACCESSES(state, accesses) {
    state.currentUserAccesses = accesses
  },

  SET_IS_VISIBLE_BTN_BACK(state, flag) {
    state.isBtnBackVisible = flag
  },

  SET_BTN_BACK_URL(state, url) {
    state.btnBackUrl = url
  },

  GET_CURRENT_INTEGRATION(state, integration) {
    state.currentIntegration = integration
  },

  GET_INTEGRATIONS(state, integrations) {
    state.integrationsList = integrations
  },

  SET_IS_VISIBLE_COMPANY_NAME(state, flag) {
    state.isVisibleCurrentCompanyName = flag
  },

  GET_TIME_ZONES(state, timeZones) {
    state.timeZones = timeZones
  },

  GET_LANGUAGES(state, languages) {
    state.languages = languages
  },

  GET_PLANS(state, plans) {
    state.plans = plans
  },

  GET_VALUES_GPAY_BY_TEMPLATE_ID(state, values) {
    state.gPayValues = values
  },

  GET_TEMPLATE_FIELDS(state, fields) {
    state.fields = fields
  },

  SET_ACCOUNT_SETUP_STEP(state, step) {
    state.currentCompany.onboardingStep = step
  },

  UPDATE_WINDOW_WIDTH(state, width) {
    state.windowWidth = width
  },
  UPDATE_WINDOW_SCROLL_Y(state, val) {
    state.scrollY = val
  },
}

export default mutations
