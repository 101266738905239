export const mongoOidTemplates = /^administrator[a-f\d]{24}$/i

export const mongoOid = /^[a-f\d]{24}$/i

export const hexColorPattern = /^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/;

export const rgbaColorPattern = /^rgba?\((\d{1,3}), (\d{1,3}), (\d{1,3})(, \d{1,3})?\)$/;

export const variablesRegex = /%([^\s]+?)%/g;

export const telInput = /tel\:/gm;

export const mailTo = /mailto\:/gm;

export const domainSubdomain = /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$/

export const emailSender = /^([a-zA-Z-]{4,})$/gm
