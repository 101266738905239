import {
  ActionTree, GetterTree, Module, MutationTree
} from 'vuex';

let messagesAI = 0;

interface RootState {
  version: string;
}

interface Alert {
  id: number;
  text: string;
  timeout: number;
  type: string;
  textBody: string;
  textValue: string;
}

interface AlertsState {
  messages: Array<Alert>;
}

const actions: ActionTree<AlertsState, RootState> = {
  add(
    { commit },
    {
      text = 'Toasts.success',
      timeout = 3000,
      type = 'success',
      textBody = '',
      textValue = '',
    }: Omit<Alert, 'id'>
  ) {
    const id = ++messagesAI;
    commit('add', {
      id, text, type, textBody, textValue
    });

    if (timeout !== undefined) {
      setTimeout(() => {
        commit('remove', id);
      }, timeout);
    }
  },
};

const mutations: MutationTree<AlertsState> = {
  add(state, message: Alert) {
    state.messages.push(message);
  },
  remove(state, id: number) {
    state.messages = state.messages.filter((msg) => msg.id !== id);
  },
};
const getters: GetterTree<AlertsState, RootState> = {
  all: (state) => state.messages,
};

const state: AlertsState = {
  messages: [],
};
export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
} as Module<AlertsState, RootState>;
