import { hexColorPattern, rgbaColorPattern } from '@/helpers/regulars.ts';

export function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`
    : '0, 0, 0';
}

export function toHex(color) {
  if (hexColorPattern.test(color)) {
    return color;
  }

  const matchRgbColors = rgbaColorPattern.exec(color);

  if (matchRgbColors) {
    let r = parseInt(matchRgbColors[1], 10);
    let g = parseInt(matchRgbColors[2], 10);
    let b = parseInt(matchRgbColors[3], 10);

    r = r.toString(16).padStart(2, '0');
    g = g.toString(16).padStart(2, '0');
    b = b.toString(16).padStart(2, '0');

    return `#${r}${g}${b}`;
  }

  return false;
}

export function invertColor(color, bw = false) {
  let r = 0;
  let g = 0;
  let b = 0;

  const matchRgbColors = rgbaColorPattern.exec(color);

  if (matchRgbColors) {
    r = parseInt(matchRgbColors[1], 10);
    g = parseInt(matchRgbColors[2], 10);
    b = parseInt(matchRgbColors[3], 10);
  } else {
    const rSingleHexSymbol = '([0-9, a-z, A-Z])';
    const rDoubleHexSymbol = '([0-9, a-z, A-Z]{1,2})';
    const rMatch3DigitHex = `${rSingleHexSymbol.repeat(3)}`;
    const rMatch6DigitHex = `${rDoubleHexSymbol.repeat(3)}`;
    const rMatchHexColors = new RegExp(`#(${rMatch3DigitHex}$)|(${rMatch6DigitHex}$)`);
    const matchHexColors = rMatchHexColors.exec(color);

    if (!matchHexColors) {
      return color;
    }

    if (matchHexColors[1]) {
      r = parseInt(matchHexColors[2].repeat(2), 16);
      g = parseInt(matchHexColors[3].repeat(2), 16);
      b = parseInt(matchHexColors[4].repeat(2), 16);
    }

    if (matchHexColors[5]) {
      r = parseInt(matchHexColors[6], 16);
      g = parseInt(matchHexColors[7], 16);
      b = parseInt(matchHexColors[8], 16);
    }
  }

  if (bw) {
    // http://stackoverflow.com/a/3943023/112731
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
      ? '#000000'
      : '#FFFFFF';
  }

  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);

  return `#${r.padStart(2, '0')}${g.padStart(2, '0')}${b.padStart(2, '0')}`;
}
