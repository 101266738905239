import { LocaleMessageObject } from 'vue-i18n';

import btnApp from '@/i18n/modules/btnApp.sr.json';
import PlaceHolder from '@/i18n/modules/PlaceHolder.sr.json';
import registrationCard from '@/i18n/modules/registrationCard.sr.json';
import downloadCard from '@/i18n/modules/downloadCard.sr.json';
import confinesCard from '@/i18n/modules/confinesCard.sr.json';

export default ({
  btnApp,
  PlaceHolder,
  registrationCard,
  downloadCard,
  confinesCard
} as unknown) as LocaleMessageObject;
