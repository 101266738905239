import { ref } from '@vue/composition-api';
import { LocalStorageAuthKeys } from '@/components/modules/auth/shared/shared';
import authService from '@/services/auth/authService';

export const checkStorageExist = () => !!localStorage.getItem(LocalStorageAuthKeys.REGISTER_EMAIL);

export function useRegisterEmailSend() {
  const email = ref(localStorage.getItem(LocalStorageAuthKeys.REGISTER_EMAIL));
  const sendStatus = ref(false);
  const loading = ref(false);

  const sendRegisterDouble = () => {
    loading.value = true;
    authService
      .sendConfirmEmailMessage(email.value)
      .finally(() => {
        sendStatus.value = true;
        loading.value = false
      });
  };

  return {
    email,
    loading,
    sendRegisterDouble,
    sendStatus
  };
}
