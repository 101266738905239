<template>
    <div id="app" :class="vueAppClasses" >
        <router-view @setAppClasses="setAppClasses" @set-app-classes="setAppClasses"/>
    </div>
</template>

<script>

export default {
  data() {
    return {
      vueAppClasses: [],
      backgroundLoading: '#22c16b',
    }
  },
  watch: {
    popupOpen() {
      document.body.style.overflow = this.popupOpen ? 'hidden' : 'auto'
    }
  },
  computed: {
    popupOpen() {
      return this.$store.state.shared.popupOpen
    }
  },
  methods: {
    metrikaYandexReach() {
      const isDev = process.env.NODE_ENV !== 'production'
    },

    setAppClasses(classesStr) {
      this.vueAppClasses.push(classesStr)
    },

    handleWindowResize() {
      this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)
      // Set --vh property
      // document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    },

    handleScroll() {
      this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY)
    }
  },

  async mounted() {
    this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)
    // let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    // document.documentElement.style.setProperty('--vh', `${vh}px`);
  },

  async created() {
    this.$vs.loading.close()

    // jwt
    // jwt.init()

    window.addEventListener('resize', this.handleWindowResize)
    // window.addEventListener('scroll', this.handleScroll)
  },

  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize)
    window.removeEventListener('scroll', this.handleScroll)
  },
}

</script>
<!--<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>-->
