import { axiosServer } from '@/http-common.ts';
import { RegisterUser } from '@/models/User.ts';
import { useErrors } from '@/composition/errorsComposition';
import { IChangePasswordByTokenInterface, standartResponse } from '@/services/auth/types.ts';
import { getLanguageInLocalStorage } from '@/composition/i18nComposition.ts';
import {AddCrm, loginResponse} from '@/models/Auth.ts';
import ApiWrap from '@/services/ApiWrap.ts';

const {
  handlerErrorsAxiosSimple,
  checkDataErrors,
  handlerErrorsAxios,
} = useErrors();

interface DataResponse {
  data: { data: object };
}

class AuthService extends ApiWrap {
  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/162594817/Register+User
   * Метод для регистрации пользователя
   * Для неавторизованных пользователей
   * @param user
   * @param token
   */
  static async register(user: RegisterUser, token: string): Promise<object> {
    const {
      data: { data },
    }: DataResponse = await axiosServer
      .post('/users/registerUser', { user, token })
      .then(checkDataErrors)
      .catch(handlerErrorsAxios);
    return data;
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/189366273/LoginNew
   * @param email
   * @param password
   */
  static async login(email: string, password: string): Promise<loginResponse> {
    const {
      data: { data },
    } = await axiosServer
      .post('/site/loginNew', { email, password }, { errorSuppression: true })
      .catch(handlerErrorsAxiosSimple);
    return data;
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/145293313/ResetPassword
   * Метод для  отправки  письма с токеном для смены пароля
   * @param email
   */
  static async resetPassword(email: string) {
    const form = new FormData();
    form.append('email', email);
    form.append('lang', getLanguageInLocalStorage());
    const {
      data: { data },
    } = await axiosServer
      .post('/site/resetPassword', form, { errorSuppression: true, noAuthorization: true })
      .catch(handlerErrorsAxiosSimple);
    return data;
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/145293322/CheckTokenFromEmail
   * Метод проверяет на валидность полученный из письма подтверждения сброса пароля токена.
   * @deprecated
   * @param email
   * @param tokenValue
   * @constructor
   */
  static async CheckTokenFromEmail(email: string, tokenValue: string) {
    const {
      data: { data },
    } = await axiosServer
      .get('/users/CheckTokenFromEmail', { params: { email, tokenValue }, errorSuppression: true })
      .catch(handlerErrorsAxios);
    return data;
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/145293322/CheckTokenFromEmail
   * Метод проверяет на валидность полученный из письма подтверждения сброса пароля токена.
   * @param email
   * @param tokenValue
   * @constructor
   */
  static async checkTokenFromEmail(email: string, tokenValue: string) {
    const { data } = await axiosServer
      .get('/users/CheckTokenFromEmail', { params: { email, tokenValue }, errorSuppression: true })
      .then(res => res.data)
    return data;
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/212434949/changePasswordByToken
   * Метод необходим для сброса пароля, после того, как пользователь перейдет с ссылки,
   * подтверждающей его персону. token и email будут  get параметрами из ссылки в письме.
   * @param dataForm
   */
  static async changePasswordByToken(dataForm: IChangePasswordByTokenInterface): Promise<standartResponse> {
    const {
      data: { data },
    } = await axiosServer
      .post('/users/changePasswordByToken', dataForm, { errorSuppression: true })
      .catch(handlerErrorsAxiosSimple);
    return data;
  }

  /**
   * Метод для проверки почты на валидность путем отправки запроса,
   * где параметром является почта, указанная пользователем.
   * Правильным ответом является полученный идентификатор письма из MailGun
   * @param email
   */
  static async sendConfirmEmailMessage(email: string) {
    const form = new FormData();
    form.append('email', email);
    form.append('lang', getLanguageInLocalStorage());

    const {
      data: { data },
    } = await axiosServer
      .post('/users/sendConfirmEmailMessage', form, { errorSuppression: true })
      .catch(handlerErrorsAxiosSimple);
    return data;
  }

  /**
   * Позволяет добавить purposes в объект пользователя (1 шаг после создания пользователя)
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/436797445/AddPurposesToUser
   * @param purposes
   */
  static async addPurposesToUser(purposes: Array<number>) {
    const { data }
     = await axiosServer
       .post('/users/addPurposesToUser', { purposes })
       .then(res => res.data)
    return data;
  }

  /**
   * Добавляет выбранную crm в компанию (2 шаг после создания пользователя)
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/436666431/addCrmToCompany
   * @param crm
   */
  static async addCrmToCompany(crm: AddCrm) {
    const { data }
      = await axiosServer
        .post('/companies/addCrmToCompany', crm)
        .then(res => res.data)
    return data;
  }

  /**
   *
   */
  static async siteLogout() {
    const { data } = await this.get<string>('/site/logoutNew')
    return data
  }
}

export default AuthService;
