import { VueConstructor } from 'vue';
import PtCard from 'common/components/card/index.vue';
import PtButton from 'common/components/button/PtButton.vue';
import PtIcon from 'common/components/icon/PtIcon.vue';
import PtIconContainer from 'common/components/icon/PtIconContainer.vue'
import PtInput from 'common/components/input/PtInput.vue';

export default {
  install(Vue: VueConstructor) {
    Vue.component('pt-card', PtCard);
    Vue.component('pt-button', PtButton);
    Vue.component('pt-icon', PtIcon);
    Vue.component('pt-icon-container', PtIconContainer);
    Vue.component('pt-input', PtInput);
  },
};
