import * as VueGoogleMaps from 'vue2-google-maps';

// eslint-disable-next-line import/prefer-default-export
export function useGoogle(Vue) {
  const registerGoogleMaps = () => Vue.use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_KEY,
      libraries: 'places',
      // language: 'en-US'
    },
  })

  return {
    registerGoogleMaps
  }
}
