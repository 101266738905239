import { SharingOptions } from '@/models/confines/SharingOptions.ts';

const sharingOptDefault: SharingOptions = {
  alreadyInstalledCard: {
    backgroundButtonColor: '#329eff',
    backgroundColor: '#F8F8F8',
    body: [],
    formButton: false,
    formButtonUrl: '',
    header: [],
    imageUrl: '',
    useFormLink: false,
    useImage: false,
    useCardRecovery: false,
    cardRecoveryButtonColor: '',
  },
  deletedCard: {
    header: [],
    body: [],
    imageUrl: '',
    backgroundColor: '',
    backgroundButtonColor: '',
    formButton: false,
    formButtonUrl: '',
    useFormLink: false,
    useImage: false,
    useCardRecovery: false,
    cardRecoveryButtonColor: '',
  },
  subscriptionEnd: {
    header: [],
    body: [],
    imageUrl: '',
    backgroundColor: '',
    backgroundButtonColor: '',
    formButton: false,
    adminContact: false,
    adminContactScript: '',
    adminContactData: '',
    useImage: false,
  },
  languages: [],
  multiLanguages: false,
};

export default sharingOptDefault;
