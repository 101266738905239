import { LocaleMessageObject } from 'vue-i18n';

import admin from '@/i18n/modules/admin.ru.json';
import AuthPage from '@/i18n/modules/AuthPage.ru.json';
import backendErrors from '@/i18n/modules/backendErrors.ru.json';
import barcode from '@/i18n/modules/barcode.ru.json';
import Billing from '@/i18n/modules/Billing.ru.json';
import btnApp from '@/i18n/modules/btnApp.ru.json';
import CardsImport from '@/i18n/modules/CardsImport.ru.json';
import cardDesign from '@/i18n/modules/cardDesign.ru.json';
import cards from '@/i18n/modules/cards.ru.json';
import Common from '@/i18n/modules/Common.ru.json';
import PlaceHolder from '@/i18n/modules/PlaceHolder.ru.json';
import companies from '@/i18n/modules/companies.ru.json';
import companySettings from '@/i18n/modules/companySettings.ru.json';
import confinesCard from '@/i18n/modules/confinesCard.ru.json';
import connectToApp from '@/i18n/modules/connectToApp.ru.json';
import convertFields from '@/i18n/modules/convertFields.ru.json';
import crm from '@/i18n/modules/crm.ru.json';
import currency from '@/i18n/modules/currency.ru.json';
import dashboard from '@/i18n/modules/dashboard.ru.json';
import errorsApp from '@/i18n/modules/errorsApp.ru.json';
import filters from '@/i18n/modules/filters.ru.json';
import form from '@/i18n/modules/form.ru.json';
import formDistribution from '@/i18n/modules/formDistribution.ru.json';
import generateNumbers from '@/i18n/modules/generateNumbers.ru.json';
import integrationSettings from '@/i18n/modules/integrationSettings.ru.json';
import integrationsList from '@/i18n/modules/integrationsList.ru.json';
import layoutMain from '@/i18n/modules/layoutMain.ru.json';
import integrationsTranslate from '@/i18n/modules/integrationsTranslate.ru.json';
import logsClients from '@/i18n/modules/logsClients.ru.json';
import marketplace from '@/i18n/modules/marketplace.ru.json';
import onBoarding from '@/i18n/modules/onBoarding.ru.json';
import plans from '@/i18n/modules/plans.ru.json';
import PopupReload from '@/i18n/modules/PopupReload.ru.json';
import PosterErrors from '@/i18n/modules/PosterErrors.ru.json';
import profileSettings from '@/i18n/modules/profileSettings.ru.json';
import promo from '@/i18n/modules/promo.ru.json';
import promotionalMaterials from '@/i18n/modules/promotionalMaterials.ru.json';
import Toasts from '@/i18n/modules/Toasts.ru.json';
import integration from '@/i18n/modules/integration.ru.json';
import pushSettings from '@/i18n/modules/pushSettings.ru.json'
import usersList from '@/i18n/modules/usersList.ru.json';
import userSettings from '@/i18n/modules/userSettings.ru.json';
import variables from '@/i18n/modules/variables.ru.json';
import versions from '@/i18n/modules/versions.ru.json';
import formsNames from '@/i18n/modules/formsNames.ru.json';
import registrationCard from '@/i18n/modules/registrationCard.ru.json';
import downloadCard from '@/i18n/modules/downloadCard.ru.json';
import roles from '@/i18n/modules/roles.ru.json';
import campaign from '@/i18n/modules/campaign.ru.json';
import passlink from '@/i18n/modules/passlink.ru.json';
import sms from '@/i18n/modules/sms.ru.json';
import transactions from '@/i18n/modules/transactions.ru.json';
import automation from '@/i18n/modules/automation.ru.json';
import loyaltyCards from '@/i18n/modules/loyaltyCards.ru.json';
import settings from '@/i18n/modules/settings.ru.json';
import purposes from '@/i18n/modules/purposes.ru.json';
import products from '@/i18n/modules/products.ru.json';
import sales from '@/i18n/modules/sales.ru.json';
import whatsAppSettings from '@/i18n/modules/whatsAppSettings.ru.json';

export default {
  admin,
  AuthPage,
  backendErrors,
  barcode,
  Billing,
  btnApp,
  cardDesign,
  CardsImport,
  cards,
  Common,
  PlaceHolder,
  companies,
  companySettings,
  confinesCard,
  connectToApp,
  crm,
  convertFields,
  currency,
  dashboard,
  errorsApp,
  filters,
  form,
  formDistribution,
  formsNames,
  generateNumbers,
  integration,
  integrationSettings,
  integrationsList,
  pushSettings,
  layoutMain,
  integrationsTranslate,
  logsClients,
  marketplace,
  onBoarding,
  plans,
  PopupReload,
  PosterErrors,
  promo,
  profileSettings,
  promotionalMaterials,
  Toasts,
  usersList,
  userSettings,
  variables,
  versions,
  registrationCard,
  downloadCard,
  roles,
  campaign,
  sms,
  transactions,
  passlink,
  automation,
  loyaltyCards,
  settings,
  purposes,
  products,
  sales,
  whatsAppSettings,
} as unknown as LocaleMessageObject;
