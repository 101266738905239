<template>
  <div class="attention bg-danger-light d-flex">
    <div>
      <DynamicIcon icon="warning" variant="danger" class="mr-15" />
    </div>
    <p class="mb-0"><slot></slot></p>
    <p class="mb-0" v-if="text" v-html="text"></p>
  </div>
</template>

<script>
import DynamicIcon from '@/components/passteamUI/DynamicIcon.vue';

export default {
  name: 'attention',
  components: { DynamicIcon },
  props: {
    text: String,
  },
};
</script>
