import Vue from 'vue'
import App from './App.vue'
import i18n from '@/i18n/index.ts'
import store from './store'
import nrouter from './router'
// @ts-ignore
import formRouter from './Nrouter.ts'

import { useGoogle } from '@/plugins/googleMaps';
import { provide } from '@vue/composition-api'
import { ApolloClients } from '@vue/apollo-composable'
import 'material-icons/iconfont/material-icons.css' // Material Icons
import 'vuesax/dist/vuesax.css';

// Theme Configurations
import '../themeConfig.js'

// Globally Registered Components
import './globalComponents.js'

// Tailwind
import '@/assets/css/main.css'
import './assets/scss/main.scss'
import './filters/filters'

import '@/plugins/composition'
import '@/plugins/vuedraggable'
import '@/plugins/VueTelInput'
import '@/plugins/VueTheMask'
import '@/plugins/VuejsClipper'
import '@/plugins/bootstrapVue'
import '@/plugins/LoadScript'
import '@/plugins/vueMultiselect'
import '@/plugins/pusher.ts'
import '@/plugins/perfectScrollbar.ts'

import '@/filters/newFilter'
import { useSentry } from '@/plugins/sentry';
import { useVeeValidate } from '@/plugins/veeValidate';
import { isProd } from '@/helpers/config';

// Feather font icon
// require('./assets/css/iconfont.css')
import '@/assets/css/iconfont.css'
import { setRuntimeVM } from '@/utils/runtime.ts';
import { hideMainLoader } from '@/helpers/main.ts';
import apolloClient from '@/graphql/ApolloClient.ts';
import { isSentryPush } from '@/config/common.ts';
import GlobalComponents from '@/global-components.ts';

const { registerGoogleMaps } = useGoogle(Vue)
const { registerVeeValidate } = useVeeValidate(Vue, i18n)
registerVeeValidate()
registerGoogleMaps()

GlobalComponents.install(Vue);

if (isProd && isSentryPush) {
  const { SentryObj } = useSentry(Vue)
  SentryObj()
}

Vue.config.productionTip = false
Vue.mixin({ beforeCreate: setRuntimeVM });

const router = process.env.VUE_APP_ROUTER === 'form' ? formRouter : nrouter

new Vue({
  router,
  store,
  i18n,
  setup() {
    provide(ApolloClients, {
      default: apolloClient,
    })
  },
  render: h => h(App),
  mounted() {
    hideMainLoader()
  },
}).$mount('#app')
