import { RouteConfig } from 'vue-router';
import checkAccessOnlyRF from '@/helpers/checkAccessOnlyRF.ts';

export default {
  path: 'promotional-materials',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  meta: {
    requiredAuth: true,
  },
  beforeEnter(to, from, next) {
    if (checkAccessOnlyRF()) {
      next();
    } else {
      next({ name: 'OnboardingNew' });
    }
  },
  children: [
    {
      path: '',
      name: 'PromotionalMaterials',
      component: () => import(/* webpackChunkName: 'promotional-materials' */ '@/views/promotionalMaterials/index.vue'),
      meta: {
        pageTitle: 'layoutMain.promotionalMaterials',
        defaultTitle: false,
      },
    },
    {
      path: 'brand-materials',
      name: 'BrandMaterials',
      component: () => import(/* webpackChunkName: "promotional" */ '@/views/promotionalMaterials/BrandMaterials.vue'),
      meta: {
        pageTitle: 'promotionalMaterials.brandMaterials',
        defaultTitle: false,
      },
    },
  ],
} as RouteConfig;
