import { RouteConfig } from 'vue-router';

export default {
  path: 'marketplace',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  meta: {
    requiredAuth: true,
  },
  children: [
    {
      path: '',
      name: 'Marketplace',
      component: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/index.vue'),
      meta: {
        pageTitle: 'Common.Marketplace',
        defaultTitle: false,
      },
    },
    {
      path: 'account-service',
      name: 'AccountService',
      component: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/AccountService.vue'),
      meta: {
        pageTitle: 'Common.AccountService',
        defaultTitle: false,
      },
    },
    {
      path: 'yclients-service',
      name: 'YclientsService',
      component: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/YclientsService.vue'),
      meta: {
        pageTitle: 'Common.YclientsService',
        defaultTitle: false,
      },
    },
    {
      path: 'dev',
      name: 'DevMarketplace',
      component: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/DevMartketplace.vue'),
      meta: {
        pageTitle: 'Common.YclientsService',
        defaultTitle: false,
      },
    },
  ],
} as RouteConfig;
