import { AxiosResponse } from 'axios';
import { tracker } from '@/plugins/assayer.ts';

class AssayerLogInterceptor {
  onFulfilled(res: AxiosResponse) {
    const name = `Request Success - ${res?.config?.url} Status: [${res.status}]`;
    tracker.event(name, res);
    return res;
  }

  onRejected(err: any) {
    const name = `Request Error - ${err?.response?.config?.url} Status: [${err?.response?.status}]`;
    tracker.event(name, err);
    return Promise.reject(err);
  }
}

export default new AssayerLogInterceptor();
