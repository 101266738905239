import Vue from 'vue'

Vue.filter('numberSpace', (val) => {
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
})

// округление до тысячных
Vue.filter('numberRounding', function (val) {
if(isNaN(val)) return val = 0;
if(val.toString().indexOf(".") >= 1) {
    let res = '';
    val.toString().replace(/(\d+)(\.|,)(\d+)/g, function(o, a, b, c){
      res = a + b + c.slice(0,2);
      if(typeof res.split('.')[1][1] === "undefined") {
          res = res + "0";
      }
    });
    return res;
  } else return val;
})

Vue.filter('removeUrlHttp', val => {
  return val.toString().replace(/^(?:https?:\/\/)?(?:www\.)?/i, '');
})
