const isDev = process.env.NODE_ENV === 'development';
const isProd = process.env.NODE_ENV === 'production';
const versionApp = process.env.VUE_APP_VERSION;
const rootLink = process.env.VUE_APP_PASSTEAM_API;
const digOceanLink = process.env.VUE_APP_DIGOCEAN;
const rootRole = 'root';
const baseUrl = process.env.BASE_URL;
const stripeKey = process.env.VUE_APP_STRIPE_KEY;

/**
 * @deprecated
 * @type {{contentUrl, clientId, scope, secret}}
 */
const square = {
  contentUrl: process.env.VUE_APP_SQUARE_CONNECT_URL,
  clientId: process.env.VUE_APP_SQUARE_CLIENT_ID,
  secret: process.env.VUE_APP_SQUARE_SECRET,
  scope: process.env.VUE_APP_SQUARE_SCOPE,
};

/**
 * @deprecated
 * @type {string}
 */
const squareUrl = `${square.contentUrl}/oauth2/authorize?client_id=${square.clientId}&scope=${square.scope}`;

const redirectToRoot = () => {
  window.location.replace(rootLink);
};

const redirectToLogout = () => {
  window.location.replace(`${rootLink}/site/logout`);
};

const frontUrl = window.location.origin + baseUrl;

const getLongUrlForm = (templateId) => `${rootLink}${baseUrl}reg/${templateId}`

export {
  isDev,
  isProd,
  versionApp,
  rootLink,
  redirectToRoot,
  redirectToLogout,
  rootRole,
  digOceanLink,
  baseUrl,
  squareUrl,
  stripeKey,
  getLongUrlForm,
  frontUrl
};
