import NestWrap from '@/services/NestWrap.ts';
import {
  TwoAuthBackupCode,
  TwoAuthGenerate,
  TwoAuthIsEnable,
} from '@/models/TwoAuth.ts';

class TwoAuth extends NestWrap {
  private controller = 'user/twoAuth';

  generateBase() {
    return this.server
      .post<TwoAuthGenerate>(`${this.controller}/generateBase`, { base: true })
      .then((res) => res.data);
  }

  enable(code: string) {
    return this.server.post(this.controller, { code }).then((res) => res.data);
  }

  backupCodes() {
    return this.server
      .get<TwoAuthBackupCode[]>(`${this.controller}/backupCodes`)
      .then((res) => res.data);
  }

  recreateBackup() {
    return this.server
      .post<TwoAuthBackupCode[]>(`${this.controller}/recreateBackup`)
      .then((res) => res.data);
  }

  backupCodesDownload() {
    return this.server
      .get<TwoAuthBackupCode[]>(`${this.controller}/backupCodesDownload`, { responseType: 'blob' })
      .then((res) => res.data);
  }

  isEnable() {
    return this.server
      .get<TwoAuthIsEnable>(`${this.controller}/isEnable`)
      .then((res) => res.data);
  }

  disable(code: string) {
    return this.server
      .post<TwoAuthIsEnable>(`${this.controller}/disable`, { code })
      .then((res) => res.data);
  }
}

export default new TwoAuth();
