const registrationCountries: Array<[string, string, string, number?, Array<string>?]> = [
  ['Armenia (Հայաստան)', 'am', '374'],
  ['Azerbaijan (Azərbaycan)', 'az', '994'],
  ['Belarus (Беларусь)', 'by', '375'],
  ['Kazakhstan (Казахстан)', 'kz', '7', 1],
  ['Kyrgyzstan (Кыргызстан)', 'kg', '996'],
  ['Moldova (Republica Moldova)', 'md', '373'],
  ['Russia (Россия)', 'ru', '7', 0],
  ['Tajikistan', 'tj', '992'],
  ['Turkmenistan', 'tm', '993'],
  ['Ukraine (Україна)', 'ua', '380'],
  ['Uzbekistan (Oʻzbekiston)', 'uz', '998'],
];

export const CISCountriesCode = new Set(['am', 'az', 'by', 'kz', 'kg', 'md', 'ru', 'tj', 'tm', 'ua', 'uz'])

export default registrationCountries.map((country) => ({
  name: country[0],
  iso2: country[1].toUpperCase(),
  dialCode: country[2],
  priority: country[3] || 0,
  areaCodes: country[4] || null,
}));
