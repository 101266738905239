import ApiWrap from '@/services/ApiWrap.ts';
import { IBackendResponse } from '@/services/types.ts';
import { Onboarding, OnboardingStep } from '@/models/onboarding/Onboarding.ts';
import Steps from '@/store/modules/onboarding/initialState.ts';

class OnboardingService extends ApiWrap {
  /**
   * Метод позволяет получать настройки прохождения анбординга
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/429588513/getOnboarding
   */
  static async getOnboarding(): Promise<Onboarding> {
    // return Promise.resolve({ steps: Steps, finish: false });
    // this.saveToIntegration('YclientsNew', Steps);
    //this.saveToCompany({ steps: Steps, finish: false });
    const { data } = await this.get<IBackendResponse<Onboarding>>(
      '/onboarding/getOnboarding'
    ).then((res) => res.data);
    return data;
  }

  /**
   * Позволяет изменять шаги анбординга
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/431423503/saveToCompany
   * @param onBoarding
   */
  static async saveToCompany(onBoarding: Onboarding): Promise<Onboarding> {
    // return Promise.resolve(onBoarding);
    const { data } = await this.post<IBackendResponse<Onboarding>, Onboarding>(
      '/onboarding/saveToCompany',
      onBoarding
    ).then((res) => res.data);
    return data;
  }

  /**
   * Позволяет изменять шаги анбординга в интеграции
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/431521843/saveToIntegration
   * @param systemName
   * @param onboarding
   */
  static async saveToIntegration(
    systemName: string,
    onboarding: Array<OnboardingStep>
  ) {
    const { data } = await this.post<
      IBackendResponse<Onboarding>,
      { systemName: string; onboarding: Array<OnboardingStep> }
    >('/onboarding/saveToIntegration', { systemName, onboarding }).then(
      (res) => res.data
    );
    return data;
  }
}

export default OnboardingService;
