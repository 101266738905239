import { User } from '@/models/User.ts';
import { isProd } from '@/helpers/config';
import * as Sentry from '@sentry/browser';
import { tracker, trackerInit } from '@/plugins/assayer.ts';
import { analyticIdentify, hubspotIdentifyUser } from '@/plugins/analytics';
import { Company } from '@/models/company/Company.ts';
import { ASAYER_ENABLED } from '@/config/common.ts';
import Tracker from '@openreplay/tracker';

export function enableToolsUser({ email, surname, name }: User): void {
  if (isProd && ASAYER_ENABLED) {
    Sentry.setUser({ email });
    trackerInit(email);
    analyticIdentify(`${name} ${surname}`, email);
    hubspotIdentifyUser(email, surname);
  }
}

export function addInfoCompanyToTools(company: Company) {
  if (isProd) {
    // eslint-disable-next-line no-underscore-dangle
    Sentry.setTags({ CURRENT_TEMPLATE: company.templates[0]?._id ?? 'NO_TEMPLATE' });
    if (tracker instanceof Tracker) {
      tracker.metadata('Company_name', company.name);
      tracker.metadata('Integration', company.integration);
      tracker.metadata('Template', company.templates[0]?.name ?? 'Not found template');
      tracker.metadata('CountryCode', company.countryCode)
      tracker.metadata('isSubscriptionActive', company.isSubscriptionActive ? 'Yes' : 'No')
    }
  }
}
