import {
  ApolloClient,
  createHttpLink,
  split,
  InMemoryCache,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { getAccessToken } from '@/utils/token.ts';
import { apolloServerLink, apolloServerSocketLink } from '@/config/common.ts';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: apolloServerLink,
});

// WebSocket connection to the API
const wsLink = new WebSocketLink({
  uri: apolloServerSocketLink,
  options: {
    reconnect: true,
    connectionParams: {
      authToken: getAccessToken()
    }
  }
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getAccessToken()
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

// Cache implementation
const cache = new InMemoryCache({
  addTypename: false,
});

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(httpLink),
);

// Create the apollo client
const apolloClient = new ApolloClient({
  link: splitLink,
  cache,
});

export default apolloClient;
