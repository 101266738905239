import { RouteConfig } from 'vue-router';

export default {
  path: 'oauth',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  children: [
    {
      path: 'google',
      name: 'GoogleOauth',
      component: () => import(/* webpackChunkName: "promoMaterials" */ '@/views/auth/oauth/GoogleOauth.vue'),
      meta: {
        defaultTitle: false
      }
    },
    {
      path: 'apple',
      name: 'AppleOauth',
      component: () => import(/* webpackChunkName: "promoMaterials" */ '@/views/auth/oauth/AppleOauth.vue'),
      meta: {
        defaultTitle: false
      }
    },
  ],
} as RouteConfig;
