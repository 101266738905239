// eslint-disable-next-line import/no-cycle
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import PublicFormService from '@/services/publicForm/publicFormService.ts';
import { PublicForm } from '@/models/form/PublicForm.ts';
import PublicFormClass from '@/models/form/PublicFormClass.ts';
import { possibleErrors } from '@/components/modules/registrationCard/config.ts';

interface RootState {
  version: string;
}
export interface RegistrationCard {
  isLoading: boolean;
  currentForm: PublicFormClass | null;
  isIdentification: boolean;
  isInactive: boolean;
  authenticationField: object;
  errors: Array<possibleErrors>;
  errorData: string | { text: string };
  awaitSpinnerCard: boolean;
  cardId: string;
  templateId: string;
}

export enum actionsType {
  dispatchForm = '[registrationCard] dispatchForm',
  dispatchTemplateId = '[registrationCard] dispatchTemplateId',
}

export enum mutationsType {
  setLoading = '[registrationCard] setLoading',
  setForm = '[registrationCard] setForm',
  endIdentification = '[registrationCard] endIdentification',
  setInactive = '[registrationCard] setInactive',
  setAuthenticationField = '[registrationCard] setAuthenticationField',
  clearErrors = '[registrationCard] clearErrors',
  addError = '[registrationCard] addError',
  addErrorData = '[registrationCard] addErrorData',
  setAwaitCard = '[registrationCard] setAwaitCard',
  setCardId = '[registrationCard] setCardId',
  setTemplateId = '[registrationCard] setTemplateId',
}

const actions: ActionTree<RegistrationCard, RootState> = {
  [actionsType.dispatchForm]({ commit }, templateId: string) {
    commit(mutationsType.setLoading, true);
    return PublicFormService.getPublicForm(templateId).then((res) => {
      commit(mutationsType.setForm, res);
      return res
    }).catch(e => {
      console.log(e.response)
    }).finally(() => {
      commit(mutationsType.setLoading, false);
    })
  },
  [actionsType.dispatchTemplateId]({ commit }, templateId: string) {
    commit(mutationsType.setTemplateId, templateId);
    localStorage.setItem('templateId', templateId)
  },
};

const mutations: MutationTree<RegistrationCard> = {
  [mutationsType.setLoading](state: RegistrationCard, payload: boolean) {
    state.isLoading = payload;
  },
  [mutationsType.setForm](state: RegistrationCard, payload: PublicForm) {
    state.currentForm = new PublicFormClass(payload);
  },
  [mutationsType.endIdentification](state: RegistrationCard) {
    state.isIdentification = false;
  },
  [mutationsType.setInactive](state: RegistrationCard) {
    state.isInactive = true;
  },
  [mutationsType.setCardId](state: RegistrationCard, payload: string) {
    state.cardId = payload;
  },
  [mutationsType.setAuthenticationField](state: RegistrationCard, payload) {
    state.authenticationField = payload;
  },

  [mutationsType.clearErrors](state) {
    state.errors = [];
    state.errorData = '';
  },
  [mutationsType.addError](state, error: possibleErrors) {
    state.errors = [...state.errors, error];
  },
  [mutationsType.addErrorData](state, data: any) {
    state.errorData = data.data;
  },
  [mutationsType.setAwaitCard](state: RegistrationCard, payload: boolean) {
    state.awaitSpinnerCard = payload;
  },
  [mutationsType.setTemplateId](state: RegistrationCard, payload: string) {
    state.templateId = payload;
  },
};

export enum gettersType {
  isLoading = '[registrationCard] isLoading',
  isIdentification = '[registrationCard] isIdentification',
  isInactive = '[registrationCard] isInactive',
  currentForm = '[registrationCard] currentForm',
  isGetAndCreate = '[registrationCard] isGetAndCreate',
  isGet = '[registrationCard] isGet',
  isEmailIdentification = '[registrationCard] isEmailIdentification',
  isSmsIdentification = '[registrationCard] isSmsIdentification',
  isError = '[registrationCard] isError',
  isAwait = '[registrationCard] isAwait',
  isEnrichment = '[registrationCard] isEnrichment',
  cardId = '[registrationCard] cardId',
  templateId = '[registrationCard] templateId',
  errorData = '[registrationCard] errorData',
}

const getters: GetterTree<RegistrationCard, RootState> = {
  [gettersType.isLoading]: (state) => state.isLoading,
  [gettersType.isIdentification]: (state) => state.isIdentification,
  [gettersType.isInactive]: (state) => state.isInactive,
  [gettersType.currentForm]: (state) => state.currentForm,
  [gettersType.isGetAndCreate]: (state) =>
    state.currentForm !== null && state.currentForm.getAndCreate,
  [gettersType.isGet]: (state) =>
    state.currentForm !== null && state.currentForm.isGet,
  [gettersType.isEmailIdentification]: (state) =>
    state.currentForm !== null && state.currentForm.isEmailIdentification,
  [gettersType.isSmsIdentification]: (state) =>
    state.currentForm !== null && state.currentForm.isSmsIdentification,
  [gettersType.isError]: (state) => (error: possibleErrors) =>
    state.errors.includes(error),
  [gettersType.isAwait]: (state) => state.awaitSpinnerCard,
  [gettersType.isEnrichment]: (state) =>
    state.currentForm?.useEnrichment ?? false,
  [gettersType.cardId]: (state) => state.cardId,
  [gettersType.templateId]: (state) => state.templateId,
  [gettersType.errorData]: (state) =>
    state.errorData.hasOwnProperty('text') &&
    typeof state.errorData !== 'string'
      ? state.errorData.text
      : state.errorData,
};

const state: RegistrationCard = {
  isLoading: true,
  currentForm: null,
  isIdentification: true,
  isInactive: false,
  authenticationField: {},
  errors: [],
  errorData: '',
  awaitSpinnerCard: false,
  cardId: '',
  templateId: ''
};

export default {
  state,
  actions,
  mutations,
  getters,
};
