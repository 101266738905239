import { LocaleMessageObject } from 'vue-i18n';

import btnApp from '@/i18n/modules/btnApp.it.json';
import PlaceHolder from '@/i18n/modules/PlaceHolder.it.json';
import registrationCard from '@/i18n/modules/registrationCard.it.json';
import downloadCard from '@/i18n/modules/downloadCard.it.json';
import confinesCard from '@/i18n/modules/confinesCard.it.json';

export default ({
  btnApp,
  PlaceHolder,
  registrationCard,
  downloadCard,
  confinesCard
} as unknown) as LocaleMessageObject;