import { rootLink } from '@/helpers/config';

const state = {
  bodyOverlay: false,
  scrollY: 0,
  windowWidth: null,
  domain: rootLink,

  activePlan: {
    _id: {
      $oid: '',
    },
    pricing: '',
    additionalDevices: {
      prices: [{
        priceForOneDeviceAnnually: '',
        priceForOneDevice: ''
      }],
    },
    order: 0,
    accesses: {},
    isStandAlone: true,
  },

  plans: [],

  activeTemplateId: null,

  currentCompany: {
    isSubscriptionActive: true,
    name: '',
    _id: {
      $oid: ''
    },
    plan: '',
    billing: {
      totalSubscriptionPrice: '',
      countAdditionalDevices: ''
    },
    autoIncAdditionalDevices: '',
    cancelSubscription: '',
    subscription: {
      status: '',
      id: ''
    },
    siteUrl: '',
    address: {},
    languages: [],
    integration: '',
    multiLanguages: '',
    integrationData: {
      companyId: '',
      groupId: '',
      accountName: '',
    },
    onboardingStep: 1
  },

  gPayValues: null,

  currentUser: {
    role: []
  },

  currentUserAccesses: {},
  currentTemplateId: '',
  currentIntegration: '',

  integrationsList: [],

  timeZones: null,

  languages: {},

  isVisibleCurrentCompanyName: false,
  isBtnBackVisible: false,
  btnBackUrl: '',

  isStaticInformPanelVisible: false,

  fields: [],

}

export default state
