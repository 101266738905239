export default {
  namespaced: true,
  state: {
    globalError: false,
    veryGlobalError: false,
    reloadCompanyLoader: false,
    globalErrorsMessage: '',
    processId: [],
  },

  actions: {
    removeGlobalError({ commit }) {
      commit('SET_GLOBAL_ERROR', false);
    },
  },

  mutations: {
    startProcess(state, id) {
      state.processId.push(id);
    },
    endProcess(state, rid) {
      state.processId = state.processId.filter((id) => id !== rid);
    },
    SET_GLOBAL_ERROR(state, value) {
      state.globalError = value;
    },
    SET_GLOBAL_ERROR_MSG(state, value) {
      state.globalErrorsMessage = value;
    },
    SET_VERY_GLOBAL_ERROR(state, payload) {
      state.veryGlobalError = payload;
    },
    SET_RELOAD_COMPANY_LOADER(state, payload) {
      state.reloadCompanyLoader = payload;
    },
  },

  getters: {
    inProcess: (state) => (id) => state.processId.includes(id),
  },
};
