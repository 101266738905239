import { initialEditForm as editForm } from '@/store/modules/form/initalState';

export default {
  validEditForm: false,
  isEditForm: false,
  isFormDelete: false,
  isSomeChangeInForm: false,
  isIdentificationDisabled: false,
  isVariablesDisabled: false,
  isAppearanceDisabled: false,
  isPublicationsDisabled: false,
  templateVars: [],
  editForm,
};
