import { RouteConfig } from 'vue-router';

export default {
  path: 'plans',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  meta: {
    requiredAuth: true,
    rootAccess: true,
  },
  children: [
    {
      path: '/',
      alias: 'list',
      name: 'PlansList',
      component: () => import(/* webpackChunkName: "plans" */ '@/views/admin/plans/index.vue'),
      meta: {
        defaultTitle: false,
        pageTitle: 'plans.pageTitle',
      },
    },
    {
      path: 'list/:id',
      name: 'PlanView',
      component: () => import(/* webpackChunkName: "plans" */ '@/views/admin/plans/PlanView.vue'),
      meta: {
        defaultTitle: false,
      },
    },
    {
      path: 'add',
      name: 'AddPlan',
      component: () => import(/* webpackChunkName: "plans" */ '@/views/admin/plans/AddPlan.vue'),
      meta: {
        defaultTitle: false,
      },
    },
  ],
} as RouteConfig;
