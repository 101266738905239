<template>
  <div>
    <div class="div" id="tooltip-button-1">
      <b-tooltip :show="score<2 && touch" target="tooltip-button-1" placement="top">
        {{ $t('AuthPage.passwordNotValid') }}
      </b-tooltip>
      <div class="d-flex" v-if="!touch">
        <div class="ellipse"></div>
        <div class="ellipse"></div>
        <div class="ellipse"></div>
        <div class="ellipse"></div>
      </div>
      <div class="d-flex" v-if="score === 0 && touch">
        <div class="ellipse bg-danger"></div>
        <div class="ellipse"></div>
        <div class="ellipse"></div>
        <div class="ellipse"></div>
      </div>
      <div class="d-flex" v-if="score === 1 && touch">
        <div class="ellipse bg-danger"></div>
        <div class="ellipse bg-danger"></div>
        <div class="ellipse"></div>
        <div class="ellipse"></div>
      </div>
      <div class="d-flex" v-if="score === 2 && touch">
        <div class="ellipse bg-warning"></div>
        <div class="ellipse bg-warning"></div>
        <div class="ellipse bg-warning"></div>
        <div class="ellipse"></div>
      </div>
      <div class="d-flex" v-if="score >= 3 && touch">
        <div class="ellipse bg-success"></div>
        <div class="ellipse bg-success"></div>
        <div class="ellipse bg-success"></div>
        <div class="ellipse bg-success"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'passwordStatus',
  props: {
    score: Number,
    touch: Boolean,
  },
};
</script>

<style scoped>
.ellipse {
  width: 6px;
  height: 6px;
  background: #e9ecee;
  margin-right: 4px;
  border-radius: 5px;
}
</style>
