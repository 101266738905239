import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations/dist/vue';
import { Integrations } from '@sentry/tracing';

// eslint-disable-next-line import/prefer-default-export
export function useSentry(Vue = null) {
  const SentryObj = () => Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new VueIntegration({
        Vue,
        tracing: true,
        logErrors: true,
        attachProps: true,
        attachStacktrace: true
      }),
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1,
  })

  const sentryTags = (sessionID) => {
    Sentry.setTags({ asayer_session_id: sessionID })
    Sentry.setTags({ AsayerLink: `${process.env.VUE_APP_ASAYER_LINK}${sessionID}` })
    Sentry.setContext('asayer', { asayer_session_id: sessionID })
    // Sentry.captureMessage('Asayer enabled')
  }
  return {
    SentryObj,
    sentryTags
  }
}
