import { RouteConfig } from 'vue-router';

export default {
  path: 'crm',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  children: [
    {
      path: '/',
      alias: 'list',
      name: 'CrmList',
      component: () => import(/* webpackChunkName: "crm" */ '@/views/admin/crm/index.vue'),
      meta: {
        pageTitle: 'layoutMain.sidebarLinkIntegrations',
        defaultTitle: false
      }
    },
    {
      path: 'list/:id',
      name: 'EditCrm',
      component: () => import(/* webpackChunkName: "crm" */ '@/views/admin/crm/EditCrm.vue'),
      meta: {
        defaultTitle: false
      }
    },
    {
      path: 'add',
      name: 'AddCrm',
      component: () => import(/* webpackChunkName: "crm" */ '@/views/admin/crm/AddCrm.vue'),
      meta: {
        defaultTitle: false
      }
    },
  ],
} as RouteConfig;
