import {
  Address,
  Billing,
  Company, CompanySynchronizationErrors,
  CountInstalledCards,
  Plan,
  RegisterForm,
  Reserved,
  Subscription,
  Template,
  UserCompanies,
  SmsSettings,
  WhatsAppParams,
  EmailsParams,
  AvailableInPlan,
} from '@/models/company/Company.ts'
import { MongoOID } from '@/models/MongoTypes.ts'
import MongoClass from '@/models/MongoClass.ts';
import { IntegrationData, SynchronizationData } from '@/models/integrations/Integration.ts';
import { Onboarding } from '@/models/onboarding/Onboarding.ts';
import { RecurrentPayment } from '@/models/RecurrentPayment.ts';

class CompanyClass extends MongoClass implements Company {
  _id: MongoOID;

  address: Address;

  autoIncAdditionalDevices: boolean;

  billing: Billing;

  blockForm: boolean;

  cancelSubscription: boolean;

  countInstalledCards: CountInstalledCards;

  created: number;

  domains: any[];

  forbidCreateCard: boolean;

  forbidDownloadCard: boolean;

  forbidUpdateCard: boolean;

  hubSpotId: string;

  integration: string;

  integrationData: IntegrationData | [];

  isSubscriptionActive: boolean;

  languages: string[];

  multiLanguages: boolean;

  name: string;

  newCardsList: boolean;

  onboardingStep: number;

  plan: Plan;

  redeemedCoupons: any[];

  registerForm: RegisterForm;

  reserved: Reserved | [];

  siteUrl: string;

  subscription: Subscription;

  templates: Template[];

  timeZone: string;

  updated: number;

  users: UserCompanies[];

  integrationError: boolean;

  countryCode: string;

  enableOnboarding: boolean;

  onboarding: Onboarding;

  IQSMSInRegisterForm?: boolean;

  errors: Array<CompanySynchronizationErrors>;

  synchronizationData: SynchronizationData;

  migrationError: boolean;

  useElasticsearch: boolean;

  integrationDisabled: boolean;

  smsSettings: SmsSettings;

  emailsParams: EmailsParams;

  whatsAppParams: WhatsAppParams;

  availableInPlan: AvailableInPlan;

  useTrial: boolean;

  tinkoffRecurrentPaymentsSettings: Array<RecurrentPayment>;

  constructor(CompanyObj: Company) {
    // eslint-disable-next-line no-underscore-dangle
    super(CompanyObj._id);

    // eslint-disable-next-line no-underscore-dangle
    this._id = CompanyObj._id;
    this.address = CompanyObj.address;
    this.autoIncAdditionalDevices = CompanyObj.autoIncAdditionalDevices;
    this.billing = CompanyObj.billing;
    this.blockForm = CompanyObj.blockForm;
    this.cancelSubscription = CompanyObj.cancelSubscription;
    this.countInstalledCards = CompanyObj.countInstalledCards;
    this.created = CompanyObj.created;
    this.domains = CompanyObj.domains;
    this.forbidCreateCard = CompanyObj.forbidCreateCard;
    this.forbidDownloadCard = CompanyObj.forbidDownloadCard;
    this.forbidUpdateCard = CompanyObj.forbidUpdateCard;
    this.hubSpotId = CompanyObj.hubSpotId;
    this.integration = CompanyObj.integration;
    this.integrationData = CompanyObj.integrationData;
    this.isSubscriptionActive = CompanyObj.isSubscriptionActive;
    this.languages = CompanyObj.languages;
    this.multiLanguages = CompanyObj.multiLanguages;
    this.name = CompanyObj.name;
    this.newCardsList = CompanyObj.newCardsList;
    this.onboardingStep = CompanyObj.onboardingStep;
    this.plan = CompanyObj.plan;
    this.redeemedCoupons = CompanyObj.redeemedCoupons;
    this.registerForm = CompanyObj.registerForm;
    this.reserved = CompanyObj.reserved;
    this.siteUrl = CompanyObj.siteUrl;
    this.subscription = CompanyObj.subscription;
    this.templates = CompanyObj.templates;
    this.timeZone = CompanyObj.timeZone;
    this.updated = CompanyObj.updated;
    this.users = CompanyObj.users;
    this.integrationError = CompanyObj.integrationError;
    this.countryCode = CompanyObj.countryCode;
    this.enableOnboarding = CompanyObj.enableOnboarding;
    this.onboarding = CompanyObj.onboarding;
    this.IQSMSInRegisterForm = CompanyObj.IQSMSInRegisterForm;
    this.errors = CompanyObj.errors;
    this.synchronizationData = CompanyObj.synchronizationData;
    this.migrationError = CompanyObj.migrationError;
    this.useElasticsearch = CompanyObj.useElasticsearch ?? false;
    this.integrationDisabled = CompanyObj.integrationDisabled;
    this.smsSettings = CompanyObj.smsSettings;
    this.emailsParams = CompanyObj.emailsParams;
    this.whatsAppParams = CompanyObj.whatsAppParams;
    this.availableInPlan = CompanyObj.availableInPlan;
    this.useTrial = CompanyObj.useTrial;
    this.tinkoffRecurrentPaymentsSettings =
      CompanyObj.tinkoffRecurrentPaymentsSettings;
  }
}

export default CompanyClass
