import { RouteConfig } from 'vue-router';
import changePassword from '@/views/auth/changePassword.vue';
import emailSendRegister from '@/views/auth/emailSendRegister.vue';
import orderCrmComplete from '@/views/auth/orderCrmComplete.vue';
import { logout } from '@/utils/auth.ts';
import OauthAuthRoutes from '@/routes/auth/OauthAuthRoutes.ts';
import UserNodeService from '@/services/node/UserNodeService.ts';

export default {
  path: '/auth',
  component: () => import(/* webpackChunkName: "auth" */ '@/layouts/auth.vue'),
  redirect: '/auth/login',
  beforeEnter(to, from, next) {
    // Насильная проверка из за опасений если php сессия отвалится
    if (Object.prototype.hasOwnProperty.call(to.meta, 'public')) {
      next();
      return;
    }
    UserNodeService.isAuthorized()
      .then((res) => {
        // @ts-ignore
        if (!('auth' in to.meta)) {
          next({ name: 'CallBackPage' });
          return;
        }
        next();
      })
      .catch((e) => {
        logout();
        // @ts-ignore
        if ('auth' in to.meta) {
          next({ name: 'Login', query: { from: to.fullPath } });
          return;
        }
        next();
      });
    // localStorage.getItem(LocalStorageAuthKeys.ACCESS_TOKEN) ? redirectToRoot() : next()
  },
  meta: {
    publicPage: true,
  },
  children: [
    {
      path: 'login',
      component: () =>
        import(/* webpackChunkName: "auth" */ '@/views/auth/login.vue'),
      name: 'Login',
      meta: {
        pageTitle: 'AuthPage.signinTitle',
      },
    },
    {
      path: '2fa',
      component: () =>
        import(/* webpackChunkName: "auth" */ '@/views/auth/twoAuth.vue'),
      name: 'TwoAuthCode',
      meta: {
        pageTitle: 'AuthPage.signinTitle',
        public: true,
      },
    },
    {
      path: 'reset-password',
      component: () =>
        import(/* webpackChunkName: "auth" */ '@/views/auth/resetPassword.vue'),
      name: 'ResetPassword',
      meta: {
        pageTitle: 'AuthPage.resetPasswordLink',
      },
    },
    {
      path: 'signup',
      component: () =>
        import(/* webpackChunkName: "auth" */ '@/views/auth/signup.vue'),
      name: 'Signup',
      meta: {
        pageTitle: 'AuthPage.signupTitle',
      },
    },
    {
      path: 'reset-email',
      component: () =>
        import(/* webpackChunkName: "auth" */ '@/views/auth/emailSendReset.vue'),
      name: 'ResetEmailPassword',
      meta: {
        pageTitle: 'AuthPage.checkEmail',
      },
    },
    {
      path: 'reset',
      component: changePassword,
      name: 'ChangePassword',
      meta: {
        pageTitle: 'Common.ChangePassword',
        public: true,
      },
    },
    {
      path: 'thank',
      component: emailSendRegister,
      name: 'Thank',
      meta: {
        pageTitle: 'AuthPage.checkEmail',
      },
    },
    {
      path: 'create-account',
      component: () =>
        import(/* webpackChunkName: "auth" */ '@/views/auth/createAccount.vue'),
      name: 'CreateAccount',
      meta: {
        pageTitle: 'AuthPage.welcome',
      },
    },
    {
      path: 'purposes',
      component: () =>
        import(/* webpackChunkName: "auth" */ '@/views/auth/purposesNew.vue'),
      name: 'Purposes',
      meta: {
        pageTitle: 'AuthPage.welcome',
        auth: true,
      },
    },
    {
      path: 'crm',
      component: () =>
        import(/* webpackChunkName: "auth" */ '@/views/auth/crm.vue'),
      name: 'Crm',
      meta: {
        pageTitle: 'AuthPage.welcome',
        auth: true,
      },
    },
    {
      path: 'crm-send',
      component: orderCrmComplete,
      name: 'OrderCrmComplete',
      meta: {
        pageTitle: 'AuthPage.crmOrderTitle',
        public: true,
      },
    },
    {
      path: 'crm-send-api',
      component: () =>
        import(
          /* webpackChunkName: "auth" */ '@/views/auth/orderCrmCompleteApi.vue'
        ),
      name: 'OrderCrmCompleteApi',
      meta: {
        pageTitle: 'AuthPage.crmOrderTitle',
        public: true,
      },
    },
    {
      path: 'token-expired',
      component: () =>
        import(/* webpackChunkName: "auth" */ '@/views/auth/TokenExpired.vue'),
      name: 'TokenExpired',
      meta: {
        pageTitle: 'AuthPage.tokenExpiredTitle',
        public: true,
      },
    },
    {
      path: 'email-exist',
      component: () =>
        import(/* webpackChunkName: "auth" */ '@/views/auth/EmailExists.vue'),
      name: 'EmailExist',
      meta: {
        pageTitle: 'AuthPage.emailAlreadyConfirmedTitle',
        public: true,
      },
    },
    {
      path: 'create-company',
      component: () =>
        import(/* webpackChunkName: "auth" */ '@/views/auth/CreateCompany.vue'),
      name: 'CreateCompany',
      meta: {
        pageTitle: 'AuthPage.welcome',
        auth: true,
      },
    },
    {
      path: 'select-company',
      component: () =>
        import(/* webpackChunkName: "auth" */ '@/views/auth/SelectCompany.vue'),
      name: 'SelectCompany',
      meta: {
        pageTitle: 'AuthPage.welcome',
        auth: true,
      },
    },
    {
      path: 'accept-invite',
      component: () =>
        import(/* webpackChunkName: "auth" */ '@/views/auth/AcceptInvite.vue'),
      name: 'AcceptInviteSignup',
      meta: {
        pageTitle: 'AuthPage.welcome',
      },
    },
    OauthAuthRoutes,
  ],
} as RouteConfig;
