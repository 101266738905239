import { CommonAccesses } from '@/models/Accesses.ts';

const initialCommonAccesses: CommonAccesses = {
  additionalImages: false,
  api: false,
  campaigns: false,
  automation: false,
  apiCreateTemplate: false,
  complexBarcode: false,
  connectToApp: false,
  duplicateSearch: false,
  email: false,
  emailAfterCreateCard: false,
  export: false,
  generatingNumbers: false,
  geoNotifications: false,
  getCardForm: false,
  iBeacon: false,
  import: false,
  pushNotifications: false,
  sharingOptions: false,
  shortUrls: false,
  sms: false,
  smsAfterCreateCard: false,
  variables: false,
  webhooks: false,
  promoMaterials: false,
  integrationSettings: false,
  onBoarding: false,
  serviceMarketplace: false,
  utm: false,
  rfm: false,
  formAlias: false,
  logs: false,
  useEnrichment: false,
  useLoyaltyCards: false,
  webPush: false,
  cardsHistoryMonthLimit: false,
};

// eslint-disable-next-line import/prefer-default-export
export { initialCommonAccesses }
