import { syncOptions } from '@/components/modules/settings/integration/yclients/standalone/data';
import {
  YclientsIntegrationData,
  YclientsSynchronizationData,
  AltegioIntegrationData,
  AltegioSynchronizationData,
} from '@/models/integrations/Yclients.ts';

const yclientsInitialData: YclientsIntegrationData = {
  login: '',
  userKey: '',
  groupId: '',
  nameGroup: '',
  cardType: '',
  nameCardType: '',
  companyId: '',
  nameCompany: '',
  companyList: [],
  currentStep: 1,
  defaultCompany: false,
  errors: [],
  integrationError: false,
  addCardAfterInstall: false,
  firstConnect: true,
  synchronizationOption: 'customersAndLoyalty',
  useMapping: false,
};

const yclientsSyncInitialData: YclientsSynchronizationData = {
  notSynchronizedClientCount: 0,
  synchronization: false,
  synchronizedClientCount: 0,
  totalClient: 0,
  isAwait: false,
};

const altegioInitialData: AltegioIntegrationData = {
  login: '',
  userKey: '',
  groupId: '',
  nameGroup: '',
  cardType: '',
  nameCardType: '',
  companyId: '',
  nameCompany: '',
  companyList: [],
  currentStep: 1,
  defaultCompany: false,
  errors: [],
  integrationError: false,
  addCardAfterInstall: false,
  firstConnect: true,
  synchronizationOption: 'customersAndLoyalty',
  useMapping: false,
};

const altegioSyncInitialData: AltegioSynchronizationData = {
  notSynchronizedClientCount: 0,
  synchronization: false,
  synchronizedClientCount: 0,
  totalClient: 0,
  isAwait: false,
};

export {
  yclientsInitialData,
  yclientsSyncInitialData,
  altegioInitialData,
  altegioSyncInitialData,
};
