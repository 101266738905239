<template>
  <input
    :type="type"
    :required="required"
    class="form-control"
    :class="[{ 'is-invalid': invalid }, size]"
    :id="inputName"
    :value="value"
    @input="$emit('input', $event.target.value)"
    @change="$emit('change', $event.target.value)"
    :placeholder="$t(placeholder)"
    :disabled="disabled"
    @focus="$emit('focus')"
  />
</template>

<script>
export default {
  name: 'PtInput',
  props: {
    value: [String, Number],
    placeholder: {
      type: String,
      default: 'PlaceHolder.enterText'
    },
    inputName: String,
    required: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
};
</script>

<style lang="scss" scoped>
input:disabled {
  background-color: var(--gray-75);
}
</style>
