const ASAYER_ENABLED = process.env.VUE_APP_ASAYER_ENABLED === 'ON';

const isSentryPush = process.env.VUE_APP_SENTRY_PUSH === 'ON';

const emailSupport = process.env.VUE_APP_EMAIL_SUPPORT

const rootLinkNode = `${process.env.VUE_APP_PASSTEAM_API}/node`;

const apolloServerLink = `${process.env.VUE_APP_NODE_URL}/node/graphql`;

const apolloServerSocketLink = `${process.env.VUE_APP_NODE_WS_URL}/node/graphql/subscriptions`;

const Emails = {
  RequestNewField: 'inbox-3tsxgcamqp1j7ij7dm6rhfjj@inbound.productboard.com'
}


export {
  ASAYER_ENABLED,
  emailSupport,
  isSentryPush,
  rootLinkNode,
  apolloServerLink,
  apolloServerSocketLink,
  Emails
}
