import { RouteConfig } from 'vue-router';

export default {
  path: '/import/cards',
  name: 'importCards',
  meta: {
    pageTitle: 'CardsImport.PageHeader',
    defaultTitle: false,
  },
  component: () => import(/* webpackChunkName: "importCards" */ '@/views/pages/import/cards/index.vue'),
} as RouteConfig;
