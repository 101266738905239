import {
  ActionTree,
  MutationTree,
  GetterTree,
  Module
} from 'vuex';
import { ImportOptions } from '@/models/import/cards.ts';
import initialState from '@/store/modules/import/cards/initialState.ts';
import importCardsService from '@/services/import/importCardsService.ts';

interface RootState {
  company: any;
  version: string;
}

interface ImportCards {
  options: ImportOptions;
}

const actions: ActionTree<ImportCards, RootState> = {
  async getOptions({ commit }) {
    const options = await importCardsService.getOptions();
    commit('SET_IMPORT_OPTIONS', options);

    return options;
  },

  async saveOptions({ commit }, options) {
    const savedOptions = await importCardsService.saveOptions(options);
    commit('SET_IMPORT_OPTIONS', savedOptions);

    return savedOptions;
  },
};

const mutations: MutationTree<ImportCards> = {
  SET_IMPORT_OPTIONS(state: ImportCards, options: ImportOptions) {
    state.options = options;
  }
};

const getters: GetterTree<ImportCards, RootState> = {
  options: (state) => state.options,
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
} as Module<ImportCards, RootState>;
