import { OnboardingStep } from '@/models/onboarding/Onboarding.ts';

const usingSteps: Array<OnboardingStep> = [
  {
    group: 'using',
    isDone: false,
    required: false,
    state: 'lock',
    systemName: 'OnBoardMarketingPush',
  },
  {
    group: 'using',
    isDone: false,
    required: false,
    state: 'lock',
    systemName: 'OnBoardAutomatization',
  },
  {
    group: 'using',
    isDone: false,
    required: false,
    state: 'lock',
    systemName: 'OnBoardGeo',
  },
];

const distributionSteps: Array<OnboardingStep> = [
  {
    group: 'distribution',
    isDone: false,
    required: false,
    state: 'lock',
    systemName: 'OnBoardAddLinkSoc',
  },
  {
    group: 'distribution',
    isDone: false,
    required: false,
    state: 'lock',
    systemName: 'OnBoardTablent',
  },
  {
    group: 'distribution',
    isDone: false,
    required: false,
    state: 'lock',
    systemName: 'OnBoardSendSms',
  },
];

const commonSteps: Array<OnboardingStep> = [
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'lock',
    systemName: 'OnBoardStepSelectTimezone',
  },
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'lock',
    systemName: 'onBoardStepSelectLanguages',
  },
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'lock',
    systemName: 'OnBoardDesignCard',
  },
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'lock',
    systemName: 'OnBoardFormSettings',
  },
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'lock',
    systemName: 'OnBoardSharingOpt',
  },
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'lock',
    systemName: 'OnBoardDownloadCard',
  },
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'lock',
    systemName: 'OnBoardSendPush',
  },
];

const inSalesSteps: Array<OnboardingStep> = [
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'unlock',
    systemName: 'InSalesStepConnectForm',
  },
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'lock',
    systemName: 'InsalesSettingsLoaylty',
  },
  ...commonSteps,
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'lock',
    systemName: 'OnBoardProcessUses',
  },
  ...distributionSteps,
  ...usingSteps,
];

const retailCrmSteps: Array<OnboardingStep> = [
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'unlock',
    systemName: 'RetailCrmStepConnect',
  },
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'lock',
    systemName: 'RetailCrmSettingsLoaylty',
  },
  ...commonSteps,
  ...distributionSteps,
  ...usingSteps,
];

const posterSteps: Array<OnboardingStep> = [
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'unlock',
    systemName: 'PosterStepConnection',
  },
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'lock',
    systemName: 'PosterSettingsLoaylty',
  },
  ...commonSteps,
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'lock',
    systemName: 'OnBoardProcessUses',
  },
  ...distributionSteps,
  ...usingSteps,
];

const quickRestoSteps: Array<OnboardingStep> = [
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'unlock',
    systemName: 'QuickRestoStepConnect',
  },
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'lock',
    systemName: 'QuickRestoSettingsLoaylty',
  },
  ...commonSteps,
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'lock',
    systemName: 'OnBoardProcessUses',
  },
  ...distributionSteps,
  ...usingSteps,
];

const yclientsSteps: Array<OnboardingStep> = [
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'unlock',
    systemName: 'YclientsStepConnection',
  },
  ...commonSteps,
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'lock',
    systemName: 'OnBoardProcessUses',
  },
  ...distributionSteps,
  ...usingSteps,
];

const altegioSteps: Array<OnboardingStep> = [
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'unlock',
    systemName: 'AltegioStepConnection',
  },
  ...commonSteps,
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'lock',
    systemName: 'OnBoardProcessUses',
  },
  ...distributionSteps,
  ...usingSteps,
];

const cftSteps: Array<OnboardingStep> = [
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'unlock',
    systemName: 'CftStepConnectForm',
  },
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'lock',
    systemName: 'CftSettingsLoyalty',
  },
];

const squareSteps: Array<OnboardingStep> = [
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'unlock',
    systemName: 'SquareStepConnect',
  },
  ...commonSteps,
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'lock',
    systemName: 'OnBoardProcessUses',
  },
  ...distributionSteps,
  ...usingSteps,
]

const mindboxSteps: Array<OnboardingStep> = [
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'unlock',
    systemName: 'MindboxStepConnect',
  },
  ...commonSteps,
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'lock',
    systemName: 'OnBoardProcessUses',
  },
  ...distributionSteps,
  ...usingSteps,
]

const iikoSteps: Array<OnboardingStep> = [
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'unlock',
    systemName: 'IikoStepConnect',
  },
  ...commonSteps,
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'lock',
    systemName: 'OnBoardProcessUses',
  },
  ...distributionSteps,
  ...usingSteps,
]

const withoutCrmSteps: Array<OnboardingStep> = [
  {
    systemName: 'OnBoardLanguages',
    state: 'unlock',
    group: 'crm',
    isDone: false,
    required: false
  },
  {
    systemName: 'OnBoardVariables',
    state: 'unlock',
    group: 'crm',
    isDone: false,
    required: false
  },
  {
    systemName: 'OnBoardCustomerBase',
    state: 'unlock',
    group: 'crm',
    isDone: false,
    required: false
  },
  {
    systemName: 'OnBoardEnrollmentForm',
    state: 'unlock',
    group: 'customerBase',
    isDone: false,
    required: false
  },
  {
    systemName: 'OnBoardPasslink',
    state: 'unlock',
    group: 'customerBase',
    isDone: false,
    required: false
  },
  {
    systemName: 'OnBoardLinks',
    state: 'unlock',
    group: 'customerBase',
    isDone: false,
    required: false
  },
  {
    systemName: 'OnBoardPromotionalMaterials',
    state: 'unlock',
    group: 'customerBase',
    isDone: false,
    required: false
  },
  {
    systemName: 'OnBoardEnrichment',
    state: 'unlock',
    group: 'customerBase',
    isDone: false,
    required: false
  },
  {
    systemName: 'OnBoardAppleWalletPush',
    state: 'unlock',
    group: 'campaigns',
    isDone: false,
    required: false
  },
  {
    systemName: 'OnBoardSMS',
    state: 'unlock',
    group: 'campaigns',
    isDone: false,
    required: false
  },
  {
    systemName: 'OnBoardGeoTargetedPush',
    state: 'unlock',
    group: 'campaigns',
    isDone: false,
    required: false
  },
  {
    systemName: 'OnBoardIBeacon',
    state: 'unlock',
    group: 'campaigns',
    isDone: false,
    required: false
  },
  {
    systemName: 'OnBoardWebPushGoogle',
    state: 'unlock',
    group: 'campaigns',
    isDone: false,
    required: false
  },
  {
    systemName: 'OnBoardAutomations',
    state: 'unlock',
    group: 'campaigns',
    isDone: false,
    required: false
  },
  {
    systemName: 'OnBoardVirtualCards',
    state: 'unlock',
    group: 'loyaltyProgram',
    isDone: false,
    required: false
  },
  {
    systemName: 'OnBoardCardsDesign',
    state: 'unlock',
    group: 'loyaltyProgram',
    isDone: false,
    required: false
  },
  {
    systemName: 'OnBoardRestrictions',
    state: 'unlock',
    group: 'loyaltyProgram',
    isDone: false,
    required: false
  },
  {
    systemName: 'OnBoardCardDownload',
    state: 'unlock',
    group: 'loyaltyProgram',
    isDone: false,
    required: false
  },
  {
    systemName: 'OnBoardSMSWithCards',
    state: 'unlock',
    group: 'loyaltyProgram',
    isDone: false,
    required: false
  }
]

const Steps: Array<OnboardingStep> = [
  ...posterSteps,
  ...yclientsSteps,
  ...altegioSteps,
  ...retailCrmSteps,
  ...inSalesSteps,
  ...quickRestoSteps,
  ...squareSteps,
  ...cftSteps,
  ...mindboxSteps,
  ...commonSteps,
  {
    group: 'settings',
    isDone: false,
    required: true,
    state: 'lock',
    systemName: 'OnBoardProcessUses',
  },
  ...distributionSteps,
  ...usingSteps,
];

export {
  cftSteps,
  inSalesSteps,
  retailCrmSteps,
  yclientsSteps,
  altegioSteps,
  posterSteps,
  quickRestoSteps,
  squareSteps,
  mindboxSteps,
  iikoSteps,
  withoutCrmSteps
};
export default Steps;
