<template>
  <component
    :is="dynamicIcon"
    :style="{
      width: size || width,
      height: size || height
    }"
    class="dynamic-icon"
    :class="[variant, { hover: hover }]"
  />
</template>

<script>
export default {
  name: 'DynamicIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: '20px',
    },
    height: {
      type: String,
      default: '20px',
    },
    size: {
      type: String,
      required: false,
    },
    variant: String,
    hover: Boolean,
  },
  computed: {
    dynamicIcon() {
      const { icon } = this;
      return () =>
        import(
          /* webpackChunkName: "icons" */
          /* webpackMode: "lazy-once" */
          `@/assets/svg/icon/${icon}.svg?inline`
        );
    },
  },
};
</script>
