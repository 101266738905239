import { RouteConfig } from 'vue-router';

export default {
  path: '/email/:companyId',
  name: 'unsubscribeEmailMailingPublic',
  component: () =>
    import(
      /* webpackChunkName: "email" */ '@/views/settings/email/EmailUnsubscribePublic.vue'
    ),
  children: [
    {
      path: ':clientId',
      name: 'unsubscribeEmailMailing'
    }
  ]
} as RouteConfig;
