<template>
  <div class="card auth-card standart-shadow px-30 align-self-center">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'authCard',
};
</script>

<style lang="scss" scoped>
.auth-card {
  padding-top: 30px;
  padding-bottom: 40px;
  width: 100%;
  max-width: 450px;
  transition: all .5s ease-in-out;

  .form-group {
    margin-bottom: 1.3333333333333333rem;
  }

  &--title {
    margin-bottom: 25px;
    text-align: center;
  }
  &--secondary {
    text-align: center;
    margin-bottom: 20px;
    color: var(--gray-850);
  }

  &--btnSubmit {
    margin-top: 30px;
  }
}
</style>
