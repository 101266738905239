import { RouteConfig } from 'vue-router';
import VersionNotifications from '@/routes/admin/VersionNotifications.ts';
import companies from '@/routes/admin/companies.ts';
import plans from '@/routes/admin/plans.ts';
import crm from '@/routes/admin/crm.ts';
import templates from '@/routes/admin/templates.ts';
import roles from '@/routes/admin/roles.ts';
import marketplaceAdminRoutes from '@/routes/admin/marketplaceAdminRoutes.ts';
import templatePatternRoute from '@/routes/admin/templatePatternRoute.ts';
import integrationsRoutes from '@/routes/admin/integrationsRoutes.ts';
import CardsAdminRoutes from '@/routes/admin/CardsAdminRoutes.ts';
import PromoMaterialsRoutes from '@/routes/admin/PromoMaterialsRoutes.ts';
import SegmentsAdminRoutes from '@/routes/admin/SegmentsAdminRoutes.ts';
import TasksRoutes from '@/routes/admin/TasksRoutes.ts';
import DevicesRoutes from '@/routes/admin/DevicesRoutes.ts';
import ConfigRoutes from '@/routes/admin/ConfigRoutes.ts';
import ElasticsearchAdminRoutes from '@/routes/admin/ElasticsearchAdminRoutes.ts';

export default {
  path: '/admin',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  meta: {
    requiresAuth: true,
    rootAccess: true,
  },
  redirect: '/admin/versions',
  children: [
    VersionNotifications,
    companies,
    plans,
    crm,
    templates,
    roles,
    marketplaceAdminRoutes,
    templatePatternRoute,
    integrationsRoutes,
    CardsAdminRoutes,
    PromoMaterialsRoutes,
    SegmentsAdminRoutes,
    TasksRoutes,
    DevicesRoutes,
    ConfigRoutes,
    ElasticsearchAdminRoutes,
  ],
} as RouteConfig;
