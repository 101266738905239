<template>
  <div class="card" :class="{ 'card--shadow': !noShadow }">
    <div
      v-if="isHeader"
      class="card__header"
      @click="toggleCollapse"
      :class="{ 'cursor-link': collapse }"
    >
      <div class="d-flex flex-wrap justify-content-between">
        <slot name="header">
          <h2 class="mb-0" :class="headerCss">
            {{ header }}
          </h2>
        </slot>
        <slot name="component"></slot>
      </div>
      <transition name="fade">
        <hr v-if="show" class="my-10" />
      </transition>
    </div>
    <transition name="fade">
      <div class="card__body" v-show="show">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'PtCard',
  props: {
    collapse: {
      type: Boolean,
      default: false,
    },
    noShadow: {
      type: Boolean,
      default: false,
    },
    header: String,
    headerCss: { type: String, default: 'text-16' },
  },
  data() {
    return {
      computedHeight: 'auto',
      show: true,
    };
  },
  computed: {
    isHeader() {
      console.log(this.$slots.header)
      return this.$slots.header !== undefined || this.header;
    }
  },
  methods: {
    toggleCollapse() {
      this.show = this.collapse ? !this.show : this.show;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 20px;
  margin-bottom: 20px;

  &__link {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    color: #329eff;
    cursor: pointer;
  }

  h2 {
    margin: 0;
  }

  &--shadow {
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  }
}
</style>
