import { RouteConfig } from 'vue-router';

export default {
  path: 'marketplace',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  children: [
    {
      path: '/',
      alias: 'list',
      name: 'MarketplaceList',
      component: () => import(/* webpackChunkName: "crm" */ '@/views/admin/marketplace/MarketplaceIndex.vue'),
      meta: {
        pageTitle: 'layoutMain.marketplace',
        defaultTitle: false
      }
    },
  ],
} as RouteConfig;
