import { RouteConfig } from 'vue-router';

export default {
  path: 'elasticsearch',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  children: [
    {
      path: '/',
      name: 'ElasticsearchList',
      component: () =>
        import(
          /* webpackChunkName: "elasticsearch" */ '@/views/admin/elasticsearch/ElasticSearchIndex.vue'
        ),
      meta: {
        defaultTitle: false,
      },
    },
    {
      path: 'jobs',
      name: 'ElasticsearchList',
      component: () =>
        import(
          /* webpackChunkName: "elasticsearch" */ '@/views/admin/elasticsearch/ElasticSearchJobs.vue'
        ),
      meta: {
        defaultTitle: false,
      },
    },
  ],
} as RouteConfig;
