import {
  ExtendedOnboardingStep,
  GroupState,
  Onboarding,
  OnboardingStep,
} from '@/models/onboarding/Onboarding.ts';
import OnboardingStepClass from '@/models/onboarding/OnboardingStepClass.ts';
import { cloneDeep, find } from 'lodash-es';

const isCurrentStep = (step: OnboardingStep) =>
  !step.isDone && step.state === 'unlock';

/* Проверяем что все шаги пройдены */
// eslint-disable-next-line max-len
const checkIfFinish = (stepsNew: Array<ExtendedOnboardingStep>) =>
  !find(stepsNew, { isDone: false });

/* Проверяем есть ли шаги открытые */
const checkStepsComplete = (stepsNew: Array<ExtendedOnboardingStep>) =>
  stepsNew.length > 0 && !find(stepsNew, { isDone: false, state: 'unlock' });

const getOnboarding = (stepsNew: Array<ExtendedOnboardingStep>) => ({
  finish: checkIfFinish(stepsNew),
  steps: stepsNew.map((st) => new OnboardingStepClass(st)),
});

type IncreaseStepFn = (
  steps: Array<ExtendedOnboardingStep>,
  index: number,
  max: number,
  notSkip?: boolean
) => Onboarding;
const inCreaseStep: IncreaseStepFn = (steps, index, max, notSkip = true) => {
  const upStep = index + 1;
  const cloneSteps = [...cloneDeep(steps)];
  const updStep: ExtendedOnboardingStep = {
    ...cloneSteps[index],
    isDone: notSkip,
    state: notSkip ? 'unlock' : 'skipped',
  };

  const stepsNew: Array<ExtendedOnboardingStep> = [
    ...cloneSteps.slice(0, index),
    updStep,
    ...cloneSteps.slice(index + 1),
  ];

  if (upStep >= max) {
    return getOnboarding(stepsNew);
  }

  stepsNew[upStep] = {
    ...stepsNew[upStep],
    state:
      stepsNew[upStep].state === 'lock' ? 'unlock' : stepsNew[upStep].state,
  };

  /**
   * Ищем если есть еще обязательные, если нет,
   * то меняем закрытые на открытые
   */
  if (!find(stepsNew, { isDone: false, required: true })) {
    stepsNew.forEach((step, i) => {
      if (step.state === 'lock') {
        stepsNew[i].state = 'unlock';
      }
    });
  }
  return getOnboarding(stepsNew);
};

const decreaseStep: IncreaseStepFn = (steps, index, max) => {
  const cloneSteps = [...cloneDeep(steps)];
  const updStep: ExtendedOnboardingStep = {
    ...cloneSteps[index],
    isDone: false,
    state: 'lock',
  };

  const stepsNew: Array<ExtendedOnboardingStep> = [
    ...cloneSteps.slice(0, index),
    updStep,
    ...cloneSteps.slice(index + 1),
  ];

  /**
   * Ищем если есть обязательные
   */
  if (find(stepsNew, { isDone: false, required: true })) {
    stepsNew.forEach((step, i) => {
      if (step.state === 'unlock') {
        stepsNew[i].state = 'lock';
      }
    });
  }

  return getOnboarding(stepsNew);
};

type completeStepFn = (
  steps: Array<ExtendedOnboardingStep>,
  index: number,
  max: number
) => Onboarding;
const completeStep: completeStepFn = (steps, index, max) => {
  const upStep = index + 1;
  const cloneSteps = [...cloneDeep(steps)];
  const updStep: ExtendedOnboardingStep = {
    ...cloneSteps[index],
    isDone: true,
    state: 'unlock',
  };

  const stepsNew: Array<ExtendedOnboardingStep> = [
    ...cloneSteps.slice(0, index),
    updStep,
    ...cloneSteps.slice(index + 1),
  ];

  return getOnboarding(stepsNew);
};

type controlOnboard = (
  steps: Array<ExtendedOnboardingStep>,
  index: number,
  max: number,
  val: boolean
) => Onboarding;
const controlOnboarding: controlOnboard = (steps, index, max, val) => {
  return val
    ? inCreaseStep(steps, index, max)
    : decreaseStep(steps, index, max);
};

const getPercent = (state: Onboarding, current: number, max: number) => {
  if (state.finish) {
    return 100;
  }
  return Math.round((current / max) * 100);
};

type kd = {
  [k in GroupState]: Array<OnboardingStep>;
};
type fnGroup = (steps: Array<OnboardingStep>) => Partial<kd>;
/**
 * Группируем шаги
 * @param steps
 */
const getGroupsOnboarding: fnGroup = (steps) =>
  steps.reduce((acc: kd, abb) => {
    const gr = abb.group;
    // eslint-disable-next-line no-unused-expressions
    acc[gr] ? acc[gr].push(abb) : (acc[gr] = [abb]);
    return acc;
  }, {});

export {
  isCurrentStep,
  inCreaseStep,
  completeStep,
  getPercent,
  checkStepsComplete,
  getGroupsOnboarding,
  controlOnboarding,
};
