import { RouteConfig } from 'vue-router';

export default {
  path: 'cards',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  meta: {
    requiredAuth: true,
    rootAccess: true,
  },
  children: [
    {
      path: '/',
      alias: 'list',
      name: 'CardsAdminList',
      component: () => import(/* webpackChunkName: "CardsAdmin" */ '@/views/admin/cards/CardsAdminIndex.vue'),
      meta: {
        defaultTitle: false,
        pageTitle: 'Common.CardList',
      },
    },
  ],
} as RouteConfig;
