import { RouteConfig } from 'vue-router';

export default {
  path: 'billing',
  component: { render: (h) => h('div', [h('router-view')]) },
  redirect: { name: 'BillingSubscription' },
  children: [
    {
      path: 'payment',
      name: 'BillingSubscription',
      component: () =>
        import(
          /* webpackChunkName: "BillingSubscription" */ '@/views/billing/BillingSubscription.vue'
        ),
      meta: {
        pageTitle: 'Настройки тарифа',
        defaultTitle: false,
      },
    },
    {
      path: 'method',
      name: 'BillingMethod',
      component: () =>
        import(
          /* webpackChunkName: "BillingMethod" */ '@/views/billing/BillingMethod.vue'
        ),
      meta: {
        pageTitle: 'Метод оплаты',
        defaultTitle: false,
      },
    },
    {
      path: 'cancel',
      name: 'BillingCancelSubscription',
      component: () =>
        import(
          /* webpackChunkName: "BillingCancelSubscription" */ '@/views/billing/BillingCancelSubscription.vue'
        ),
      meta: {
        pageTitle: 'Отмена подписки',
        defaultTitle: false,
      },
    },
  ],
} as RouteConfig;
