import { useStore } from '@/composition/storeComposition.ts';
import { Company, companyPathSave } from '@/models/company/Company.ts';
import { computed } from '@vue/composition-api';
import { IntegrationsNamesT } from '@/helpers/integrationsNamesT.ts';
import { CompanyState } from '@/store/modules/company/companyStore.ts';
import { access } from '@/store/modules/plan/initialState.ts';
import { SmsPlan } from '@/models/plan/Plan.ts';
import { IntegrationEnum } from '@/components/modules/auth/shared/types.ts';
import { IntegrationData } from '@/models/integrations/Integration.ts';
import { CISCountriesCode } from '@/data/registrationCountries';

export default function useCompanyStore() {
  const store = useStore();
  const companyState = computed<CompanyState>(() => store.state.company);

  const activePlan = computed(() => companyState.value.activePlan);
  const loadingPlan = computed(() => companyState.value.loadingPlan);
  const currentCompany = computed(() => companyState.value.currentCompany);
  const loadingCompany = computed(() => companyState.value.loadingCompany);

  const saveCompany = (company: Partial<Company>) =>
    store.dispatch('company/saveCompany', company);

  const patchSaveCompany = (company: companyPathSave) =>
    store.dispatch('company/patchSaveCompany', company);

  const invisibleReloadCompany = () =>
    store.dispatch('company/invisibleReloadCompany');

  const currentIntegrationName = computed<IntegrationsNamesT>(
    () => store.getters['company/integration']
  );
  const currentTemplateId = computed(
    () => store.getters['company/currentTemplateId']
  );
  const company = computed(() => companyState.value.currentCompany);

  const currency = computed(() => activePlan.value?.currency);

  const standAlonePricing = computed(() => activePlan.value?.standAlonePricing);

  const standAloneAccesses = computed(() => standAlonePricing.value?.accesses);

  const paymentMethodCard = computed(
    () =>
      currentCompany.value.tinkoffRecurrentPaymentsSettings.find(
        (item) => item.paymentType === 'addPaymentMethod'
      )
  );

  const getters = computed(() => ({
    isSquare: currentIntegrationName.value === 'Square',
    isYclients:
      currentIntegrationName.value === 'YclientsNew' ||
      currentIntegrationName.value === 'YclientsV3',
    isAltegio: currentIntegrationName.value === 'Altegio',
    isPoster: currentIntegrationName.value === 'Poster',
    isQuickResto: currentIntegrationName.value === 'QuickResto',
    isIiko: currentIntegrationName.value === 'iiko',
    accesses: companyState.value?.activePlan?.accesses ?? access,
    smsCountryBuyAvailable: activePlan.value?.smsPlans?.find((plan: SmsPlan) =>
      plan.availableInCountries.includes(currentCompany.value.countryCode)
    ),
    senderName: currentCompany.value.smsSettings?.senderName,
    isFromRussia: currentCompany.value.countryCode === 'RU',
    isFromCIS: CISCountriesCode.has(currentCompany.value.countryCode.toLowerCase()),
    isFromCanadaOrUSA:
      currentCompany.value.countryCode === 'US' ||
      currentCompany.value.countryCode === 'CA',
    isActivePaymentMethod: paymentMethodCard.value?.isEnable,
    hiddenMethodCard: paymentMethodCard.value?.pan
      ? `${'*'.repeat(
          paymentMethodCard.value.pan.length - 1
        )}${paymentMethodCard.value.pan.slice(-4)}`
      : '',
    currency: activePlan.value?.currency === 'RUB' ? '₽' : '$',
  }));

  const isStandalone = computed(
    () =>
      currentCompany.value.registerForm.nameCrm === IntegrationEnum.WITHOUT_CRM
  );

  const subscription = computed(() => currentCompany.value.subscription);

  const billing = computed(() => currentCompany.value.billing);

  const availableInPlan = computed(() => currentCompany.value.availableInPlan);

  const Actions = {
    loadCompany: () => store.dispatch('company/getCurrentCompany'),
    getPaymentMethods: () =>
      store.dispatch(
        'billing/getPaymentMethods',
        currentCompany.value._id.$oid
      ),
    subscribeSocket: () => store.dispatch('company/subscribeSocket'),
    invisibleReloadCompany: () =>
      store.dispatch('company/invisibleReloadCompany'),
    saveIntegrationData: (
      integrationData: Partial<IntegrationData>,
      rewrite: Boolean
    ) =>
      store.dispatch('company/saveIntegrationData', {
        integrationData,
        rewrite,
      }),
  };

  return {
    saveCompany,
    patchSaveCompany,
    currentIntegrationName,
    company,
    currency,
    standAlonePricing,
    standAloneAccesses,
    isStandalone,
    activePlan,
    loadingPlan,
    currentCompany,
    subscription,
    billing,
    availableInPlan,
    loadingCompany,
    currentTemplateId,
    getters,
    invisibleReloadCompany,
    Actions,
  };
}
