import { axiosServer } from '@/http-common.ts';
import qs from "qs";

export default {
  async getIntegrationBySystemName({commit}, systemName) {
    try {
      let {data} = await axiosServer({
        method: 'POST',
        headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
        data: qs.stringify({systemName: systemName}),
        url: '/integrations/getIntegrationBySystemName',
      })
      if (data.data.error !== true) {
        commit('SET_SELECTED_INTEGRATION', data.data)
        return data.data
      } else {
        return false
      }
    } catch (e) {
      console.error(e)
    }
  }
}
