import { rootLink } from '@/helpers/config';

const defaultBgColor = '#f8f8f8';
const defaultBtnColor = '#329EF2';
const blackAppleBg = '#010103'
const urlToGetGoogleCard = `${rootLink}/cards/getCardGoogle/id/`;
const urlToGetAppleCard = `${rootLink}/cards/getCard/id/`;

export {
  defaultBgColor,
  urlToGetGoogleCard,
  defaultBtnColor,
  urlToGetAppleCard,
  blackAppleBg,
};
