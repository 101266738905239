import {
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from 'vuex';
import { AddUtmParams, CabinetForm } from '@/models/form/CabinetForm.ts';
import FormServiceTs from '@/services/form/FormServiceNew.ts';

export enum actionsType {
  dispatchForm = '[form] dispatchForm',
  saveForm = '[form] saveForm',
  blockForm = '[form] blockForm',
  addUtm = '[form] addUtm',
  deleteUtm = '[form] deleteUtm',
  connectIQSMS = '[form] connectIQSMS',
  editForm = '[form] editForm',
  changeSender = '[form] changeSender',
  createForm = '[form] createForm',
}

export enum mutationsType {
  setLoading = '[form] setLoading',
  setIsError = '[form] setIsError',
  setForm = '[form] setForm',
}

interface RootState {
  version: string;
}

export enum gettersType {
  isEmailIdentification = '[form] isEmailIdentification',
  isSmsIdentification = '[form] isSmsIdentification',
  isSmsIntegration = '[form] isSmsIntegration'
}

export interface FormState {
  loading: boolean;
  isError: boolean;
  form: CabinetForm;
}
const mutations: MutationTree<FormState> = {
  [mutationsType.setForm](state, payload) {
    state.form = payload;
  },
  [mutationsType.setLoading](state, payload) {
    state.loading = payload;
  },
  [mutationsType.setIsError](state, payload) {
    state.isError = payload;
  },
};
const actions: ActionTree<FormState, RootState> = {
  [actionsType.dispatchForm]({ commit }) {
    commit(mutationsType.setLoading, true);

    return FormServiceTs.getForm().then(
      (data) => {
        commit(mutationsType.setForm, data);
        commit(mutationsType.setIsError, false);
        commit(mutationsType.setLoading, false);
      },
      () => {
        commit(mutationsType.setIsError, true);
        commit(mutationsType.setLoading, false);
      }
    );
  },
  async [actionsType.saveForm](
    { commit, state },
    payload: Partial<CabinetForm>
  ) {
    // const upd = { ...state.form, ...payload }
    const data = await FormServiceTs.saveForm(payload);
    commit(mutationsType.setForm, data);
    return data;
  },
  async [actionsType.editForm](
    { commit, dispatch },
    payload: Partial<CabinetForm>
  ) {
    const data = await FormServiceTs.editForm(payload);
    dispatch(actionsType.dispatchForm)
    // commit(mutationsType.setForm, data);
    return data;
  },
  async [actionsType.blockForm]({ dispatch }, isBlocked = false) {
    const data = await FormServiceTs.blockForm(isBlocked);
    dispatch('company/invisibleReloadCompany');
    return data;
  },
  async [actionsType.addUtm]({ dispatch }, payload: AddUtmParams) {
    const data = await FormServiceTs.addUtm(payload);
    dispatch(actionsType.dispatchForm)
    // commit(mutationsType.setForm, data);
    return data;
  },
  async [actionsType.deleteUtm]({ dispatch }, name: string) {
    const data = await FormServiceTs.deleteUtm(name);
    // commit(mutationsType.setForm, data);
    dispatch(actionsType.dispatchForm)
    return data;
  },
  async [actionsType.connectIQSMS]({ dispatch }, { login = '', password = '' }) {
    const data = await FormServiceTs.connectIQSMS(login, password);
    // commit(mutationsType.setForm, data);
    dispatch(actionsType.dispatchForm)
    return data;
  },
  async [actionsType.changeSender]({ dispatch }, sender: string) {
    const data = await FormServiceTs.saveSenderName(sender)
    // commit(mutationsType.setForm, data);
    dispatch(actionsType.dispatchForm)
    return data;
  },
  async [actionsType.createForm]({ dispatch, commit }) {
    const data = await FormServiceTs.saveFormByIntegration();
    dispatch(actionsType.dispatchForm);
    commit(mutationsType.setIsError, false);

    return data;
  },
};

const getters: GetterTree<FormState, RootState> = {
  [gettersType.isEmailIdentification]: (state) =>
    state.form.authenticationType === 'email',
  [gettersType.isSmsIdentification]: (state) =>
    state.form.authenticationType === 'sms',
  [gettersType.isSmsIntegration]: (state) =>
    Object.values(state.form.smsIntegrations).some((v) => !v)
};

export default {
  actions,
  mutations,
  state: {
    loading: true,
    isError: false,
    form: {},
  },
  getters,
} as Module<FormState, RootState>;
