import OnboardingStepClass from '@/models/onboarding/OnboardingStepClass.ts';
import {
  ExtendedOnboardingStep,
  OnboardingStep,
} from '@/models/onboarding/Onboarding.ts';
import { ComponentNamesT } from '@/components/modules/onboarding/steps/index.ts';
import { StepsCollection } from '@/components/modules/onboarding/steps/stepCollection.ts';

class ExtendedOnboardingStepClass
  extends OnboardingStepClass
  implements ExtendedOnboardingStep {
  componentName: ComponentNamesT;

  label: string;
  text: string;
  link?: string;
  linkText?: string;
  needLoyaltyCards?: string;

  value: boolean;

  constructor(onBoardingStep: OnboardingStep) {
    super(onBoardingStep);

    const { label, text, link, linkText, needLoyaltyCards, componentName } = this.findBySystemName();
    this.componentName = componentName;
    this.label = label;
    this.text = text;
    this.link = link;
    this.linkText = linkText;
    this.needLoyaltyCards = needLoyaltyCards;
    this.value = false;
  }

  findBySystemName() {
    const { systemName } = this;
    return (
      StepsCollection.find((st) => st.systemName === systemName) ??
      StepsCollection[0]
    );
  }
}

export default ExtendedOnboardingStepClass;
