import { RouteConfig } from 'vue-router';

type Meta = 'requiresAuth' | 'rootAccess';

interface MetaObj {
  requiresAuth: boolean;
  rootAccess?: boolean;
}

export default {
  path: '/users',
  //redirect: '/users/list',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  meta: {
    requiresAuth: true,
    rootAccess: true,
  } as MetaObj,
  children: [
    {
      path: '/',
      alias: 'list',
      name: 'UsersList',
      component: () => import(/* webpackChunkName: "users" */ '@/views/admin/users/index.vue'),
      meta: {
        pageTitle: 'layoutMain.sidebarLinkUser',
        defaultTitle: false
      }
    },
    {
      path: 'list/:userId',
      name: 'UserProfile',
      component: () => import(/* webpackChunkName: "users" */ '@/views/admin/users/UserId.vue'),
      meta: {
        defaultTitle: false
      }
    },
    {
      path: 'add',
      name: 'AddUser',
      component: () => import(/* webpackChunkName: "users" */ '@/views/admin/users/AddUser.vue'),
      meta: {
        defaultTitle: false
      }
    },
  ],
} as RouteConfig;
