import axios from 'axios';
import { RootObject } from 'location';

const COUNTRY_CODE_LOCALSTORAGE = 'passteam_country_code';

const getLocationData: () => Promise<RootObject> = async () => {
  const data = await axios
    .get<RootObject>('https://api.sypexgeo.net/json')
    .then((res) => res.data)
  return data;
};

const getCity: () => Promise<string> = () => getLocationData().then((res) => res?.city?.name_en ?? 'Not found city');
const getTimezone: () => Promise<string> = () => getLocationData().then((res) => res?.region?.timezone ?? 'Not found timezone');

export function getCountry() {
  return fetch('https://ip2c.org/s')
    .then((response) => response.text())
    .then((response) => {
      const result = (response || '').toString();

      if (!result || result[0] !== '1') {
        throw new Error('unable to fetch the country');
      }
      const res = result.substr(2, 2);
      localStorage.setItem(COUNTRY_CODE_LOCALSTORAGE, res);
      return res
    });
}

const getCountryFromLocalstorage = () => localStorage.getItem(COUNTRY_CODE_LOCALSTORAGE)

export {
  getCity,
  getLocationData,
  getCountryFromLocalstorage,
  getTimezone
}
