export default {
    GET_CURRENT_PAYMENT_METHODS(state, paymentCardInform) {
        state.paymentCardInform = paymentCardInform
    },

    GET_CURRENT_INVOICES(state, invoices) {
        state.invoices = invoices
    },

    GET_UPCOMING_INVOICE(state, invoice) {
        state.upcomingInvoices = invoice
    },

    GET_CURRENT_SUBSCRIPTION (state, subscription){
        state.currentSubscription = subscription
    },

    GET_CURRENT_TARIFF(state, tariff) {
        state.currentTariff = tariff
    },

    GET_CARDS_INSTALLED_PRESENT(state, present) {
        state.cardInstalledPercent = present
    },

    GET_STRIPE_PAYMENT_METHOD(state, method) {
        state.stripePaymentMethod = method
    },

    APPLY_STRIPE_COUPON(state, coupon) {
        state.cupon = coupon
    }
}
