import { AxiosInstance } from 'axios';
import nestServer from '@/http/axios/nestServer.ts';

class NestWrap {
  public server: AxiosInstance;

  constructor(serverProp: AxiosInstance = nestServer) {
    this.server = serverProp;
  }
}

export default NestWrap;
