import { IIntegrationLog } from '@/models/logs/IntegrationLog.ts';
import LogClass from '@/models/logs/LogClass.ts';
import groupBy from 'lodash-es/groupBy';

interface GroupedIntegrations<T> {
  [key: string]: Array<T>;
}

class LogsGroupCollection {
  public integrationArray: Array<LogClass>;

  public integrationArrayOriginal: Array<IIntegrationLog>;

  public groupedIntegrations: GroupedIntegrations<LogClass>;

  public keysDateGroup: Array<string>;

  constructor(integrationArray: Array<IIntegrationLog>) {
    this.integrationArrayOriginal = integrationArray
    this.integrationArray = integrationArray.map(it => new LogClass(it))

    this.groupedIntegrations = groupBy<LogClass>(this.integrationArray, 'groupBy');
    this.keysDateGroup = Object.keys(this.groupedIntegrations)
  }

  get lastId(): string {
    return this.integrationArray.length
      ? this.integrationArray[this.integrationArray.length - 1].id
      : ''
  }
}
export default LogsGroupCollection
