/**
 * Returns a random number between min (inclusive) and max (exclusive)
 */
export function getRandomArbitrary(min: number, max: number): number {
  return Math.random() * (max - min) + min;
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
export function getRandomInt(min: number, max: number): number {
  return (
    Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min) + 1)) + min
  );
}

/**
 * Returns a random ArrayNumbers
 * @param len
 * @param val
 */
export function getRandomArrayNumbers(len = 24, val = 40): Array<number> {
  return Array(len)
    .fill(0)
    .map(() => Math.round(Math.random() * val));
}

/**
 * Возвращает количество подключений
 * @param startCount
 * @param step
 */
export function calculateSubscribers(startCount = 0, step = 0) {
  const date = Math.ceil(Date.now() / 86400000) - 18515;
  return startCount + date * step;
}
