import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { LocalStorageAuthKeys } from '@/components/modules/auth/shared/shared.ts';
import {
  getAccessToken,
  getJWTPayload,
  getCurrentJwtPayload,
} from '@/utils/token.ts';
import UserService from '@/services/user/userService.ts';
import { redirectToLogout } from '@/helpers/config';
import { uuidv4 } from '@/utils/helpers.dev.ts';
import { loginResponse } from '@/models/Auth.ts';

const tabID = sessionStorage.tabID
  ? sessionStorage.tabID
  : (sessionStorage.tabID = uuidv4());

const afterLogin = (res: loginResponse) => {
  localStorage.setItem(LocalStorageAuthKeys.ACCESS_TOKEN, res.jwt);
  const {
    data: { id = null, email = '' },
  } = getJWTPayload(res.jwt);
  if (id) localStorage.setItem(LocalStorageAuthKeys.USER_ID, id);
};

export const checkTwoFactorIsEnable = () => {
  const { isTwoFactorEnable } = getCurrentJwtPayload();
  return isTwoFactorEnable;
};

export const checkTwoFactorAuthenticated = () => {
  const { isTwoFaAuthenticated } = getCurrentJwtPayload();
  return isTwoFaAuthenticated;
};

const logout = () => {
  localStorage.removeItem(LocalStorageAuthKeys.ACCESS_TOKEN);
  localStorage.removeItem(LocalStorageAuthKeys.USER_ID);
};

const addAccessToken = (request: AxiosRequestConfig) => {
  const accessToken = getAccessToken();

  if (accessToken !== null && !request.noAuthorization) {
    request.headers.Authorization = `Bearer ${accessToken}`;
  }

  return request;
};
// переменная для хранения запроса токена
let refreshTokenRequest: null | Promise<any> = null;

const refreshToken = (error: { response: AxiosResponse }) => {
  if (error.response.status !== 401) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
  logout();

  if (refreshTokenRequest === null) {
    refreshTokenRequest = UserService.refreshToken();
  }

  return refreshTokenRequest
    .then((res) => {
      refreshTokenRequest = null;
      afterLogin(res);
      // New request with new token
      const config: AxiosRequestConfig = {
        ...error.response.config,
        headers: {
          ...error.response.config.headers,
          Authorization: `Bearer ${getAccessToken()}`,
        },
        withCredentials: true,
      };
      return new Promise((resolve, reject) => {
        axios
          .request(config)
          .then((response) => {
            resolve(response);
          })
          .catch((er) => {
            reject(er);
          });
      });
    })
    .catch((err) => {
      logout();
      redirectToLogout();
      Promise.reject(err);
    });
};

export { afterLogin, logout, addAccessToken, refreshToken, tabID };
