import { RouteConfig } from 'vue-router';

export default {
  path: 'devices',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  children: [
    {
      path: 'web',
      name: 'WebDevices',
      component: {
        render: (h) => h('div', [h('router-view')]),
      },
      meta: {
        defaultTitle: false
      },
      children: [
        {
          path: '/',
          alias: 'Weblist',
          name: 'WebDevicesList',
          component: () => import(/* webpackChunkName: "devices" */ '@/views/admin/devices/web/WebDeviceIndex.vue'),
          meta: {
            pageTitle: 'layoutMain.sidebarLinkDevices',
            defaultTitle: false
          }
        },
      ],
    },
  ],
} as RouteConfig;
