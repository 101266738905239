import { RouteConfig } from 'vue-router';

export default {
  path: 'campaign',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  redirect: {
    name: 'AllCampaign',
  },
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: 'all',
      name: 'AllCampaign',
      component: () =>
        import(
          /* webpackChunkName: "campaign" */ '@/views/campaign/all/index.vue'
        ),
      meta: {
        pageTitle: 'Common.MailingLists',
        defaultTitle: false,
      },
    },
    {
      path: 'createPush',
      name: 'CreatePush',
      component: () =>
        import(
          /* webpackChunkName: "campaign" */ '@/views/campaign/create/CreatePush.vue'
        ),
      meta: {
        defaultTitle: false,
      },
    },
    {
      path: 'createPush/:id',
      name: 'PushDraft',
      component: () =>
        import(
          /* webpackChunkName: "campaign" */ '@/views/campaign/create/CreatePush.vue'
        ),
      meta: {
        defaultTitle: false,
      },
    },
    {
      path: 'createWebPush',
      name: 'CreateWebPush',
      component: () =>
        import(
          /* webpackChunkName: "campaign" */ '@/views/campaign/create/CreateWebPush.vue'
        ),
      meta: {
        defaultTitle: false,
      },
    },
    {
      path: 'createWebPush/:id',
      name: 'WebPushDraft',
      component: () =>
        import(
          /* webpackChunkName: "campaign" */ '@/views/campaign/create/CreateWebPush.vue'
        ),
      meta: {
        defaultTitle: false,
      },
    },
    {
      path: 'createWhatsApp',
      name: 'CreateWhatsApp',
      component: () =>
        import(
          /* webpackChunkName: "campaign" */ '@/views/campaign/create/CreateWhatsApp.vue'
        ),
      meta: {
        defaultTitle: false,
      },
    },
    {
      path: 'createWhatsApp/:id',
      name: 'WhatsAppDraft',
      component: () =>
        import(
          /* webpackChunkName: "campaign" */ '@/views/campaign/create/CreateWhatsApp.vue'
        ),
      meta: {
        defaultTitle: false,
      },
    },
    {
      path: 'createSms',
      name: 'CreateSms',
      component: () =>
        import(
          /* webpackChunkName: "campaign" */ '@/views/campaign/create/CreateSms.vue'
        ),
      meta: {
        defaultTitle: false,
      },
    },
    {
      path: 'createSms/:id',
      name: 'SmsDraft',
      component: () =>
        import(
          /* webpackChunkName: "campaign" */ '@/views/campaign/create/CreateSms.vue'
        ),
      meta: {
        defaultTitle: false,
      },
    },
    {
      path: 'createCombo',
      name: 'CreateCombo',
      component: () =>
        import(
          /* webpackChunkName: "campaign" */ '@/views/campaign/create/CreateCombo.vue'
        ),
      meta: {
        defaultTitle: false,
      },
    },
    {
      path: 'createGeo',
      name: 'CreateGeo',
      component: () =>
        import(
          /* webpackChunkName: "campaign" */ '@/views/campaign/create/CreateGeo.vue'
        ),
      meta: {
        defaultTitle: false,
      },
    },
    {
      path: 'createGeo/:id',
      name: 'GeoDraft',
      component: () =>
        import(
          /* webpackChunkName: "campaign" */ '@/views/campaign/create/CreateGeo.vue'
        ),
      meta: {
        defaultTitle: false,
      },
    },
    {
      path: 'createIBeacon',
      name: 'CreateIBeacon',
      component: () =>
        import(
          /* webpackChunkName: "campaign" */ '@/views/campaign/create/CreateIBeacon.vue'
        ),
      meta: {
        defaultTitle: false,
      },
    },
    {
      path: 'createIBeacon/:id',
      name: 'BeaconDraft',
      component: () =>
        import(
          /* webpackChunkName: "campaign" */ '@/views/campaign/create/CreateIBeacon.vue'
        ),
      meta: {
        defaultTitle: false,
      },
    },
    {
      path: 'createEmail',
      name: 'CreateEmail',
      component: () =>
        import(
          /* webpackChunkName: "campaign" */ '@/views/campaign/create/CreateEmail.vue'
        ),
      meta: {
        defaultTitle: false,
      },
    },
    {
      path: 'createEmail/:id',
      name: 'EmailDraft',
      component: () =>
        import(
          /* webpackChunkName: "campaign" */ '@/views/campaign/create/CreateEmail.vue'
        ),
      meta: {
        defaultTitle: false,
      },
    },
    {
      path: ':id',
      name: 'CampaignID',
      component: () =>
        import(
          /* webpackChunkName: "campaign" */ '@/views/campaign/id/CampaignID.vue'
        ),
      meta: {
        defaultTitle: false,
      },
    },
  ],
} as RouteConfig;
