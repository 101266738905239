import { RouteConfig } from 'vue-router';

export default {
  path: 'company',
  name: 'CompanySettings',
  redirect: '/settings/company/main',
  component: () =>
    import(
      /* webpackChunkName: "settings" */ '@/views/settings/company/index.vue'
    ),
  children: [
    {
      path: 'main',
      name: 'CompanySettingsMain',
      component: () =>
        import(
          /* webpackChunkName: "settings" */ '@/views/settings/company/CompanySettingsMain.vue'
        ),
      meta: {
        pageTitle: 'Common.CompanySettings',
        defaultTitle: false,
      },
    },
    {
      path: 'users',
      name: 'CompanySettingsUsers',
      component: () =>
        import(
          /* webpackChunkName: "settings" */ '@/views/settings/company/CompanySettingsUsers.vue'
        ),
      meta: {
        pageTitle: 'Common.CompanySettings',
        defaultTitle: false,
      },
    },
    {
      path: 'integration',
      name: 'CompanySettingsIntegration',
      component: () =>
        import(
          /* webpackChunkName: "settings" */ '@/components/modules/settings/integration/HolderSettingsMoved.vue'
        ),
      meta: {
        defaultTitle: false,
      },
    },
    {
      path: 'users',
      name: 'CompanySettingsUsers',
      component: () =>
        import(
          /* webpackChunkName: "settings" */ '@/components/modules/settings/company/TheCompanySettingsUsers.vue'
        ),
      meta: {
        defaultTitle: false,
        rootAccess: true,
      },
    },
  ],
} as RouteConfig;
