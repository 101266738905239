



























// @ts-nocheck
import AuthCard from '@/components/modules/auth/layouts/authCard.vue';
import DynamicIcon from '@/components/passteamUI/DynamicIcon.vue';
import Vue from 'vue';
import { useI18n } from '@/composition/i18nComposition.ts';
import { computed } from '@vue/composition-api';
import { languagesEnum } from '@/models/i18n/Language.ts';

export default Vue.extend({
  name: 'integrationOrderSent',
  components: { AuthCard, DynamicIcon },
  props: {
    orderTitle: {
      type: String,
      default: 'AuthPage.crmOrderTitle',
    },
  },
  setup(_, { root }) {
    const { getCurrentLang } = useI18n(root);
    const onlyRussian = computed(
      () => getCurrentLang.value.lang === languagesEnum.RU
    );
    return {
      onlyRussian,
    };
  },
});
