import {
  IIntegrationLog,
  queryType,
  statusQuery,
} from '@/models/logs/IntegrationLog.ts';
import { MongoOID } from '@/models/MongoTypes.ts';

class LogClass implements IIntegrationLog {
  _id: MongoOID;

  bodyRequest: string;

  bodyResponse: string;

  code: number;

  created: number;

  status: statusQuery;

  templateId: { $oid: string };

  time: number;

  type: queryType;

  updated: number;

  url: string;

  public groupBy: string;

  constructor({
    type = 'POST',
    updated = 1605864913,
    url = '',
    time = 1,
    _id = { $oid: '' },
    bodyRequest = '',
    bodyResponse = '',
    code = 200,
    created = 1605864913,
    status = 'Ok',
    templateId = { $oid: '' },
  }: IIntegrationLog) {
    this.url = url;
    this.updated = updated;
    // eslint-disable-next-line no-underscore-dangle
    this._id = _id;
    this.type = type;
    this.bodyRequest = bodyRequest;
    this.status = status;
    this.time = time;
    this.code = code;
    this.templateId = templateId;
    this.bodyResponse = bodyResponse;
    this.created = created;

    this.groupBy = new Date(created * 1000).toLocaleDateString()
  }

  get id() {
    // eslint-disable-next-line no-underscore-dangle
    return this._id.$oid;
  }

  get formattedTime() {
    return new Date(this.created * 1000).toLocaleTimeString();
  }

  get formattedDate() {
    return new Date(this.created * 1000).toLocaleDateString();
  }

  get bodyResponseWithCheck(): string {
    return (typeof this.bodyResponse === 'string') ? this.bodyResponse : ''
  }

  get bodyRequestWithCheck(): string {
    return (typeof this.bodyRequest === 'string') ? this.bodyRequest : ''
  }
}
export default LogClass;
