import { RouteConfig } from 'vue-router';

export default {
  path: 'integrations',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  meta: {
    requiredAuth: true,
    rootAccess: true,
  },
  children: [
    {
      path: '/',
      alias: 'list',
      name: 'IntegrationsList',
      component: () => import(/* webpackChunkName: "plans" */ '@/views/admin/integrations/ListIntegrations.vue'),
      meta: {
        pageTitle: 'layoutMain.sidebarListIntegrations',
        defaultTitle: false,
      },
    },
    {
      path: 'list/:id',
      name: 'EditIntegration',
      component: () => import(/* webpackChunkName: "plans" */ '@/views/admin/integrations/EditIntegrations.vue'),
      meta: {
        defaultTitle: false,
      },
      redirect: 'list/:id/main',
      children: [
        {
          path: 'main',
          name: 'EditIntegrationMain',
          component: () => import('@/views/admin/integrations/id/IntegrationMain.vue'),
          meta: {
            pageTitle: 'integrationSettings.PageTitle',
            defaultTitle: false,
          },
        },
        {
          path: 'accesses',
          name: 'EditIntegrationAccesses',
          component: () => import('@/views/admin/integrations/id/EditIntegrationAccesses.vue'),
          meta: {
            pageTitle: 'integrationSettings.PageTitle',
            defaultTitle: false,
          },
        },
        {
          path: 'variables',
          name: 'EditIntegrationVariables',
          component: () => import('@/views/admin/integrations/id/EditIntegrationVariables.vue'),
          meta: {
            pageTitle: 'integrationSettings.PageTitle',
            defaultTitle: false,
          },
        },
        {
          path: 'fields',
          name: 'EditIntegrationFields',
          component: () => import('@/views/admin/integrations/id/EditIntegrationFields.vue'),
          meta: {
            pageTitle: 'integrationSettings.PageTitle',
            defaultTitle: false,
          },
        },
        {
          path: 'other',
          name: 'EditIntegrationOther',
          component: () => import('@/views/admin/integrations/id/EditIntegrationOther.vue'),
          meta: {
            pageTitle: 'integrationSettings.PageTitle',
            defaultTitle: false,
          },
        },
      ],
    },
    {
      path: 'add',
      name: 'AddIntegration',
      component: () => import(/* webpackChunkName: "plans" */ '@/views/admin/integrations/AddIntegration.vue'),
      meta: {
        defaultTitle: false,
        pageTitle: 'integrationSettings.newIntegration',
      },
    },
  ],
} as RouteConfig;
