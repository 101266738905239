import {
  Integration,
  SynchronizationData,
} from '@/models/integrations/Integration.ts';
import { initialCommonAccesses } from '@/data/intitialAccesses.ts';
import { FieldLayout } from '@/models/Fields.ts';

const integrationInitial: Integration = {
  _id: { $oid: '' },
  fields: [],
  accesses: initialCommonAccesses,
  createCard: false,
  created: 0,
  deleteCard: false,
  name: '',
  fieldsLayouts: [],
  languages: [],
  onboarding: [],
  plans: [],
  systemName: '',
  updateCard: false,
  updated: 0,
};

const synchData: SynchronizationData = {
  isAwait: false,
  notSynchronizedClientCount: 0,
  synchronization: false,
  synchronizedClientCount: 0,
  totalClient: 0,
};

export const initialIntegrationField: FieldLayout = {
  googleLayout: {
    main1: '',
    main2: '',
    text1: '',
    text2: '',
    text3: '',
  },
  name: [
    { language: 'ru', value: 'Новое поле' },
    { language: 'en', value: 'New field' },
  ],
  appleLayout: {
    secondary3: '',
    secondary2: '',
    picture: '',
    head: '',
    secondary1: '',
  },
};

export { synchData, integrationInitial };
