import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {
  ru,
  en,
  ua,
  es,
  sk,
  de,
  fr,
  it,
  ka,
  pt,
  sr,
} from '@/i18n/lang/index.ts';
import { LocalStorageAuthKeys } from '@/components/modules/auth/shared/shared.ts';

Vue.use(VueI18n);


export default new VueI18n({
  locale: localStorage.getItem(LocalStorageAuthKeys.LANG_PASSTEAM) ?? 'ru',
  fallbackLocale: 'ru',
  pluralizationRules: {
    ru(choice, choicesLength) {
      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return (choicesLength < 4) ? 2 : 3;
    },
  },
  messages: {
    ru,
    en,
    ua,
    es,
    sk,
    de,
    fr,
    it,
    ka,
    pt,
    sr,
  },
});
