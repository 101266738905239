import { RouteConfig } from 'vue-router';

export default {
  path: 'promoMaterials',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  children: [
    {
      path: '/',
      alias: 'list',
      name: 'PromoMaterialsList',
      component: () => import(/* webpackChunkName: "promoMaterials" */ '@/views/admin/promoMaterials/PromoMaterialsIndex.vue'),
      meta: {
        pageTitle: 'layoutMain.promotionalMaterials',
        defaultTitle: false
      }
    },
    {
      path: 'list/:id',
      name: 'EditPromoMaterials',
      component: () => import(/* webpackChunkName: "promoMaterials" */ '@/views/admin/promoMaterials/EditPromoMaterial.vue'),
      meta: {
        defaultTitle: false
      }
    },
    {
      path: 'add',
      name: 'PromoMaterialsAdd',
      component: () => import(/* webpackChunkName: "promoMaterials" */ '@/views/admin/promoMaterials/AddPromoMaterials.vue'),
      meta: {
        defaultTitle: false
      }
    },
  ],
} as RouteConfig;
