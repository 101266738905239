import formService from '@/services/form/formService';

export default {
  async getForm({ commit }, templateId) {
    const form = await formService.getForm(templateId);
    return form;
  },

  async saveForm({ commit }, form) {
    const newForm = {
      ...form,
      templateId: form.templateId.$oid ?? form.templateId,
    };
    await formService.SaveForm(newForm);
  },

  async removeForm({ commit }, formId) {
    commit('appPass/startProcess', 'remove-form', { root: true });
    await formService.removeForm(formId);
    commit('UPDATE_FORM_STATUS', false);
    commit('UPDATE_FORM_PHONE_VALUE', '');
    commit('UPDATE_FORM_EMAIL_VALUE', '');
    commit('UPDATE_FORM_VALUES', []);
    commit('UPDATE_FORM_RULES', 'get');
    commit('RESET_FORM_ID');
    commit('appPass/endProcess', 'remove-form', { root: true });
  },

  async blockForm({ commit, rootGetters }, isBlocked) {
    const formInfo = {
      templateId: rootGetters['passTemplate/currentTemplateId'],
      isBlocked,
    };
    commit('appPass/startProcess', 'remove-form', { root: true });
    await formService.blockForm(formInfo);
    commit('appPass/endProcess', 'remove-form', { root: true });
  },

  async removeUtm({ commit, getters, dispatch }, linkId) {
    commit('appPass/startProcess', 'remove-link', { root: true });
    const form = JSON.parse(JSON.stringify(getters.editForm));
    form.shortUrls.splice(linkId, 1);
    commit('form/UPDATE_FORM_DISTRIBUTION', form, { root: true });
    await dispatch('saveForm', form);
    commit('appPass/endProcess', 'remove-link', { root: true });
  },

  async addUtm({ commit, rootGetters, getters, dispatch }, newUrl) {
    commit('appPass/startProcess', 'add-link', { root: true });
    const form = JSON.parse(JSON.stringify(getters.editForm));
    form.shortUrls.push(newUrl);
    await dispatch('saveForm', form);
    const templateId = rootGetters['passTemplate/currentTemplateId'];
    const newForm = await dispatch('getForm', templateId);
    commit('form/UPDATE_FORM_DISTRIBUTION', newForm, { root: true });
    commit('appPass/endProcess', 'add-link', { root: true });
  },
};
