import { RouteConfig } from 'vue-router';

export default {
  path: 'config',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  children: [
    {
      path: 'errorsCards',
      name: 'ErrorsCards',
      component: () => import(/* webpackChunkName: "crm" */ '@/views/admin/config/ErrorsCardsSettings.vue'),
      meta: {
        pageTitle: 'layoutMain.sidebarLinkErrorMessages',
        defaultTitle: false,
      },
    },
  ],
} as RouteConfig;
