import { User } from '@/models/User.ts';
import { Commit } from 'vuex';
import { isProd } from '@/helpers/config';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';

function checkUserCompanyExpect({ companyIds, email }: User, commit: Commit) {
  if (companyIds.length === 0) {
    commit('appPass/SET_VERY_GLOBAL_ERROR', true, { root: true });
    if (isProd) {
      Sentry.captureException(
        new Error('Внимание! У пользователя нет компании!!!'),
        {
          level: Severity.Fatal,
          user: {
            email,
          },
        }
      );
    }
  }
}

export function userCheck(user: User, commit: Commit) {
  checkUserCompanyExpect(user, commit);
}
