import ApiWrap from '@/services/ApiWrap.ts';
import { IBackendResponse } from '@/services/types.ts';
import { AddUtmParams, CabinetForm, IqsmsSenders, UploadFormImageParams } from '@/models/form/CabinetForm.ts';
import { Detailing } from '@/models/Sms';

class FormServiceNew extends ApiWrap {
  /**
   * Метод позволяет редактировать статус блокировки формы.
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/348815361/blockForm
   *
   */
  static async blockForm(isBlocked: boolean) {
    const { data } = await this.post('/form/blockForm', { isBlocked });
    return data;
  }

  /**
   * Получение формы
   */
  static async getForm(): Promise<CabinetForm> {
    const { data } = await this.get<IBackendResponse<CabinetForm>>(
      '/form/getForm'
    ).then(res => res.data)
    return data;
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/488570898/saveForm
   * Метод позволяет сохранять форму ( создавать или обновлять)
   */
  static async saveForm(form: Partial<CabinetForm>): Promise<CabinetForm> {
    const { data } = await this.patch<IBackendResponse<CabinetForm>, Partial<CabinetForm>>(
      '/form/saveForm',
      form
    ).then(res => res.data)
    return data;
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/533528589/Edit
   * Метод позволяет редактировать поля формы
   */
  static async editForm(form: Partial<CabinetForm>): Promise<CabinetForm> {
    const { data } = await this.patch<IBackendResponse<CabinetForm>, Partial<CabinetForm>>(
      '/form',
      form
    ).then(res => res.data)
    return data;
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/493125651/uploadFormImage
   */
  static async uploadFormImage(image: UploadFormImageParams): Promise<string> {
    const formData = new FormData()
    formData.append('image', image.image)
    formData.append('imageType', image.imageType)
    formData.append('imageName', image.imageName)
    const { data } = await this.post<IBackendResponse<string>, FormData>(
      '/form/uploadFormImage',
      formData
    ).then(res => res.data)
    return data;
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/834961409/UploadUniqueOfferImage
   * Позволяет загрузить изображение для уникального предложения
   */
  static async uploadUniqueOfferImage(image: UploadFormImageParams): Promise<string> {
    const formData = new FormData()
    formData.append('image', image.image)
    formData.append('imageType', image.imageType)
    formData.append('imageName', image.imageName)
    const { data } = await this.post<IBackendResponse<string>, FormData>(
      '/form/UploadUniqueOfferImage',
      formData
    ).then(res => res.data)
    return data;
  }

  /**
   * Позволяет добавить ссылочку с utm меточкой
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/538509335/addUtm
   */
  static async addUtm(shortUrl: AddUtmParams): Promise<CabinetForm> {
    const { data } = await this.patch<IBackendResponse<CabinetForm>, AddUtmParams>(
      '/form/addUtm',
      shortUrl
    ).then(res => res.data)
    return data;
  }

  /**
   * Позволяет удалить utm метку по имени
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/533463055/deleteUtm
   */
  static async deleteUtm(name: string): Promise<CabinetForm> {
    const { data } = await this.get<IBackendResponse<CabinetForm>>(
      '/form/deleteUtm', {
        params: {
          name
        }
      }
    ).then(res => res.data)
    return data;
  }

  /**
   *
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/553385985/connectIQSMS
   */
  static async connectIQSMS(userLogin: string, userPassword: string): Promise<CabinetForm> {
    const { data } = await this.post<IBackendResponse<CabinetForm>, {userLogin: string; userPassword: string}>(
      '/form/connectIQSMS', {
        userLogin,
        userPassword
      }
    ).then(res => res.data)
    return data;
  }

  /**
   * IQSMS
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/553385985/connectIQSMS
   */
  static async getBalance(): Promise<CabinetForm> {
    const { data } = await this.get<IBackendResponse<CabinetForm>>(
      '/form/getBalance'
    ).then(res => res.data)
    return data;
  }

  static async getSendersName(): Promise<IqsmsSenders> {
    const { data } = await this.get<IBackendResponse<IqsmsSenders>>(
      '/form/getSendersName'
    ).then(res => res.data)
    return data;
  }

  /**
   * Метод позволяет получить баланс смс'ок в количестве штук (сейчас возвращает количество средств)
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/562823169/getBalance+AccountTransactions
   */
  static async getBalanceSMS(companyId: string): Promise<CabinetForm> {
    const { data } = await this.post<IBackendResponse<string>, null>(
      '/accountTransactions/getBalance',
      { companyId }
    ).then(res => res.data)
    return data;
  }

  /**
   * Изменить баланс компании
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/812580865/changeBalance+SprintSmS
   */
  static async changeBalance(amount: number, companyId: string): Promise<CabinetForm> {
    const { data } = await this.post<IBackendResponse<number>>(
      '/accountTransactions/changeBalance',
      { amount, companyId }
    ).then(res => res.data)
    return data;
  }

  /**
   * Метод позволяет получить детализацию по транзакциям
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/813203457/detailing
   */
  static async getDetailing(lastId: string): Promise<CabinetForm> {
    const { data } = await this.post<IBackendResponse<Detailing>>(
      '/accountTransactions/detailing',
      { lastId }
    ).then(res => res.data)
    return data;
  }

  /**
   * Позволяет обновить имя отправителя IQSMS
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/554893313/saveSenderName
   */
  static async saveSenderName(senderName: string): Promise<CabinetForm> {
    const { data } = await this.post<IBackendResponse<CabinetForm>, { senderName: string }>(
      '/form/saveSenderName',
      { senderName }
    ).then(res => res.data)
    return data;
  }

  static async saveFormByIntegration(): Promise<CabinetForm> {
    const { data } = await this.get<IBackendResponse<CabinetForm>>('/form/saveFormByIntegration')
      .then(res => res.data);

    return data;
  }
}
export default FormServiceNew;
