import { Module } from 'vuex';
import UsersService from '@/services/users/UsersService.ts';
import { UserAdmin, UsersFiltersParams } from '@/models/users/types.ts';

interface RootState {
  version: string;
}

interface UsersState {
  users: Array<UserAdmin>;
  loading: boolean;
  currentUser: {};
  params: UsersFiltersParams;
  counts: number;
}

export default {
  namespaced: true,
  actions: {
    async updateParams({ commit, dispatch }, payload: UsersFiltersParams) {
      commit('SET_PARAMS', payload);
      dispatch('getUsers');
    },
    async getUsers({ commit, state }) {
      commit('SET_LOADING', true);
      const data = await UsersService.getUsers(state.params);
      commit('SET_USERS', data.users);
      commit('SET_COUNT', data.usersCount);
      commit('SET_LOADING', false);
    },
    async getUser({ commit }, id) {
      commit('SET_LOADING', true);
      commit('SET_CURRENT_USER', await UsersService.getUserById(id));
      commit('SET_LOADING', false);
    },
  },
  mutations: {
    SET_LOADING(state, loading: boolean) {
      state.loading = loading;
    },
    SET_CURRENT_USER(state, user) {
      state.currentUser = user;
    },
    SET_USERS(state, users) {
      state.users = users;
    },
    SET_PARAMS(state, params: UsersFiltersParams) {
      state.params = { ...state.params, ...params };
    },
    SET_COUNT(state, counts) {
      state.counts = counts;
    },
  },
  state: {
    users: [],
    loading: false,
    currentUser: {},
    params: {
      page: 1,
      perPage: 25,
      search: '',
    },
    counts: 0,
  },
} as Module<UsersState, RootState>;
