export default {
    UPDATE_FORM_DISTRIBUTION(state, form) {
        state.editForm = form
    },

    UPDATE_FORM_STATUS(state, isform) {
        state.isEditForm = isform
    },

    DELETE_FORM(state, value) {
        state.isFormDelete = value
    },

    UPDATE_FORM_INTEGRATION(state, integration) {
        state.editForm.authenticationType = integration
    },

    UPDATE_FORM_TEMPLATE_ID(state, id) {
        state.editForm.templateId = id
    },

    UPDATE_FORM_PHONE_VALUE(state, phoneValue) {
        state.editForm.phoneValue = phoneValue
    },

    UPDATE_FORM_EMAIL_VALUE(state, emailValue) {
        state.editForm.emailValue = emailValue
    },

    UPDATE_FORM_SMS_INTEGRATION_USER_LOGIN(state, login) {
        state.editForm.smsIntegrations.userLogin = login
    },

    UPDATE_FORM_SMS_INTEGRATION_USER_PASSWORD(state, password) {
        state.editForm.smsIntegrations.userPassword = password
    },

    UPDATE_FORM_SMS_INTEGRATION_SENDER_NAME(state, senderName) {
        state.editForm.smsIntegrations.senderName = senderName
    },

    UPDATE_FORM_RULES(state, rules) {
        state.editForm.script = rules
    },

    UPDATE_FORM_AGREEMENT(state, url) {
        state.editForm.agreementUrl = url
    },

    UPDATE_FORM_RESTRICTION_URL(state, url) {
        state.editForm.restrictionUrl = url
    },

    UPDATE_FORM_VALUES(state, values) {
        state.editForm.formValues = values
    },

    UPDATE_FORM_USE_APPLE_SIGN(state, flag) {
        state.editForm.useAppleSign = flag
    },

    UPDATE_FORM_USE_USE_GOOGLE_SIGN(state, flag) {
        state.editForm.useGoogleSign = flag
    },

    UPDATE_FORM_APPEARANCE_BACKGROUND(state, value) {
        state.editForm.formAppearance.background = value
    },

    UPDATE_FORM_APPEARANCE_BUTTON_COLOR(state, value) {
        state.editForm.formAppearance.buttonColor = value
    },

    UPDATE_FORM_APPEARANCE_TEXT_COLOR(state, value) {
        state.editForm.formAppearance.textColor = value
    },

    UPDATE_FORM_APPEARANCE_LOGO_COLOR(state, value) {
        state.editForm.formAppearance.logoColor = value
    },

    UPDATE_FORM_LOGO_URL(state, url) {
        state.editForm.logoUrl = url
    },

    UPDATE_TEMPLATE_VARS(state, templateVars) {
        state.templateVars = templateVars
    },

    UPDATE_TEMPLATE_IS_IDENTIFICATION_DISABLED(state, status) {
        state.templateVars = status
    },

    UPDATE_TEMPLATE_IS_VARIABLES_DISABLED(state, status) {
        state.isVariablesDisabled = status
    },

    UPDATE_TEMPLATE_IS_APPEARANCE_DISABLED(state, status) {
        state.isAppearanceDisabled = status
    },

    UPDATE_TEMPLATE_IS_PUBLICATIONS_DISABLED(state, status) {
        state.isPublicationsDisabled = status
    },
    RESET_FORM_ID(state) {
        delete state.editForm._id
    }
}
