import { baseUrl, isProd } from '@/helpers/config';
import { stripTrailingSlash } from '@/helpers/urlHelpers.ts';
import { getCity } from '@/helpers/location/locationHelpers.ts';

export function gTag(targetId, eventCategory) {
  // window.gtag('event', targetId, { 'event_category': eventCategory });
}

export function analyticIdentify(name = '', email = '') {
  if (isProd && window && window.analytics) {
    window.analytics.identify({
      name,
      email,
    });
  }
}

/**
 * Set Page Path
 * https://legacydocs.hubspot.com/docs/methods/tracking_code_api/set_page_path
 * @param path
 */
export function hubspotSetPath(path) {
  const pathUrl = stripTrailingSlash(baseUrl) + path;
  // eslint-disable-next-line no-underscore-dangle
  const _hsq = window._hsq = window._hsq || [];
  _hsq.push(['setPath', pathUrl]);
  _hsq.push(['trackPageView']);
}

/**
 * Identify a visitor
 * https://legacydocs.hubspot.com/docs/methods/tracking_code_api/identify_visitor
 * @param email
 * @param surname
 */
export function hubspotIdentifyUser(email = '', surname = '') {
  const user = {
    email,
    surname
  }
  // eslint-disable-next-line no-underscore-dangle
  const _hsq = window._hsq = window._hsq || [];

  getCity().then(city => {
    user.City = city;
    user.ipCity = city;
  })
    .finally(() => {
      _hsq.push(['identify', user]);
      _hsq.push(['trackPageView']);
    })
}
