import { computed, ref, watchEffect } from '@vue/composition-api';

const zxcvbn = require('zxcvbn');

export function usePasswordValidator(password, passwordDouble) {
  const invalidPasswords = ref(false);
  const passwordCheckScore = computed(() => zxcvbn(password.value));
  const passwordCheck = computed(() => password.value === passwordDouble.value);

  watchEffect(() => {
    if (invalidPasswords.value) {
      invalidPasswords.value = !passwordCheck.value;
    }
  });

  const submitPasswordsForm = (callback) => {
    if (passwordCheck.value) {
      return callback();
    }
    invalidPasswords.value = true;
  };

  return {
    passwordCheckScore,
    submitPasswordsForm,
    invalidPasswords,
  };
}
