const calcPresent = (sync, notSync, total) => {
  return total > 0 ? Math.ceil(((sync + notSync) / total) * 100) : 0
}
const calculatePresentSync = (integrationData) => {
  const {
    synchronizedClientCount,
    notSynchronizedClientCount,
    totalClient,
  } = integrationData
  const hasVar =
    synchronizedClientCount && notSynchronizedClientCount && totalClient
  return hasVar
    ? calcPresent(
      synchronizedClientCount,
      notSynchronizedClientCount,
      totalClient
    )
    : 0
}

const transformTimeZone = (label, name) => {
  const [full, tmz] = label.match(/\((.*?)\S*\)/)
  return {
    label: `(UTC ${tmz.trim()}) ${label.replace(full, '')}`,
    name,
    zone: Number(tmz.trim()),
  }
}

const sortTimeZones = (zones) => {
  return zones.sort((a, b) => (a.zone < b.zone ? 1 : -1))
}

const getTimeZones = ({
  America,
  Arctic,
  Asia,
  Atlantic,
  Europe,
  Indian,
  Pacific,
}) => {
  const timeZones = {
    ...America,
    ...Arctic,
    ...Asia,
    ...Atlantic,
    ...Europe,
    ...Indian,
    ...Pacific,
  }
  const timeZonesForSelect = []
  for (let item in timeZones) {
    timeZonesForSelect.push(transformTimeZone(timeZones[item], item))
  }
  return sortTimeZones(timeZonesForSelect)
}

/**
 * Получает айди темплайта
 * @param templates
 * @returns {*|string}
 */
// eslint-disable-next-line no-underscore-dangle
const getTemplateID = (templates) => (templates.length ? templates[0]._id : '')

export {
  calculatePresentSync, getTimeZones, transformTimeZone, getTemplateID
}
