import { RouteConfig } from 'vue-router';

export default {
  path: '/dashboard',
  name: 'Dashboard',
  meta: {
    pageTitle: 'Dashboard',
    defaultTitle: false,
  },
  redirect: '/dashboard/finance',
  component: () =>
    import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/index.vue'),
  children: [
    {
      path: 'finance',
      name: 'FinanceDashboard',
      component: () =>
        import(
          /* webpackChunkName: "dashboard" */ '@/views/dashboard/Finance.vue'
        ),
      meta: {
        pageTitle: 'layoutMain.mainDashboard',
        defaultTitle: false,
        tabName: 'finance',
      },
    },
    {
      path: 'clients',
      name: 'ClientsDashboard',
      component: () =>
        import(
          /* webpackChunkName: "dashboard" */ '@/views/dashboard/Clients.vue'
        ),
      meta: {
        pageTitle: 'layoutMain.mainDashboard',
        defaultTitle: false,
        tabName: 'clients',
      },
    },
    {
      path: 'loyaltyProgram',
      name: 'LoyaltyProgramDashboard',
      component: () =>
        import(
          /* webpackChunkName: "dashboard" */ '@/views/dashboard/LoyaltyProgram.vue'
        ),
      meta: {
        pageTitle: 'layoutMain.mainDashboard',
        defaultTitle: false,
        tabName: 'loyalty',
      },
    },
    {
      path: 'push',
      name: 'PushDashboard',
      component: () =>
        import(
          /* webpackChunkName: "dashboard" */ '@/views/dashboard/Push.vue'
        ),
      meta: {
        pageTitle: 'layoutMain.mainDashboard',
        defaultTitle: false,
        tabName: 'push',
      },
    },
    {
      path: 'clientsStatic',
      name: 'ClientsStatic',
      component: () =>
        import(
          /* webpackChunkName: "dashboard" */ '@/views/dashboard/ClientsStatic.vue'
        ),
      meta: {
        pageTitle: 'layoutMain.mainDashboard',
        defaultTitle: false,
        tabName: 'pushTransaction',
      },
    },
    {
      path: 'dev',
      name: 'DevDashboard',
      component: () =>
        import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Dev.vue'),
      meta: {
        pageTitle: 'layoutMain.mainDashboard',
        defaultTitle: false,
        rootAccess: true,
      },
    },
  ],
} as RouteConfig;
