import { RouteConfig } from 'vue-router';

export default {
  path: 'templatePattern',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  children: [
    {
      path: ':id',
      name: 'EditTemplatePattern',
      component: () => import(/* webpackChunkName: "templatePattern" */ '@/views/admin/templatesPattern/EditTemplatePattern.vue'),
      meta: {
        defaultTitle: false
      }
    },
    {
      path: ':id/design',
      name: 'EditTemplatePatternDesign',
      component: () => import(/* webpackChunkName: "templatePattern" */ '@/views/admin/templatesPattern/EditTemplatePatternDesign.vue'),
      meta: {
        defaultTitle: false
      }
    },
    {
      path: '/',
      alias: 'list',
      name: 'TemplatePatternList',
      component: () => import(/* webpackChunkName: "templatePattern" */ '@/views/admin/templatesPattern/TemplatesPatternIndex.vue'),
      meta: {
        pageTitle: 'layoutMain.sidebarLinkCardDesignerTemplates',
        defaultTitle: false
      }
    },
    {
      path: 'add',
      name: 'TemplatePatternAdd',
      component: () => import(/* webpackChunkName: "templatePattern" */ '@/views/admin/templatesPattern/AddTemplatePattern.vue'),
      meta: {
        defaultTitle: false
      }
    },
  ],
} as RouteConfig;
