import { RouteConfig } from 'vue-router';

export default {
  path: 'roles',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  children: [
    {
      path: '/',
      alias: 'list',
      name: 'RolesList',
      component: () => import(/* webpackChunkName: "crm" */ '@/views/admin/roles/index.vue'),
      meta: {
        defaultTitle: false
      }
    },
    {
      path: 'list/:id',
      name: 'EditRole',
      component: () => import(/* webpackChunkName: "crm" */ '@/views/admin/roles/EditRole.vue'),
      meta: {
        defaultTitle: false
      }
    },
    {
      path: 'add',
      name: 'AddRole',
      component: () => import(/* webpackChunkName: "crm" */ '@/views/admin/roles/AddRole.vue'),
      meta: {
        defaultTitle: false
      }
    },
  ],
} as RouteConfig;
