export enum YclientsStep {
  FIRST_LOGIN_AUTH = 1,
  SECOND_SETTINGS,
  SETUP_CARD,
  FOUR_NOTIFICATION_OFF,
  FIVE_CARDS_OFF,
  SIX_END_CONNECT,
}

export enum IntegrationStatusYclients {
  'SUCCESS' = 'success',
  'WARNING_SYNC' = 'warning_sync',
  'DANGER_SYNC' = 'danger_sync',
  'DANGER_INT' = 'danger',
  'UNKNOWN' = 'unknown'
}

export enum AltegioStep {
  FIRST_LOGIN_AUTH = 1,
  SECOND_SETTINGS,
  SETUP_CARD,
  FOUR_NOTIFICATION_OFF,
  FIVE_CARDS_OFF,
  SIX_END_CONNECT,
}

export enum IntegrationStatusAltegio {
  'SUCCESS' = 'success',
  'WARNING_SYNC' = 'warning_sync',
  'DANGER_SYNC' = 'danger_sync',
  'DANGER_INT' = 'danger',
  'UNKNOWN' = 'unknown'
}
