import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { initialCommonAccesses } from '@/data/intitialAccesses.ts';
import userService from '@/services/user/userService.ts';
import { enableToolsUser } from '@/helpers/user/UserAppliedTools.ts';
import { userCheck } from '@/helpers/user/userChecker.ts';
import CompanyClass from '@/models/company/CompanyClass.ts';
import UserClass from '@/models/user/UserClass.ts';
import { CommonAccesses } from '@/models/Accesses.ts';
import { Company } from '@/models/company/Company.ts';
import { defaultUser } from '@/store/modules/user/initialState.ts';
import { logout, tabID } from '@/utils/auth.ts';

import {
  ConfirmEmaiLParams,
  User,
  UserEdit,
  UserNotifications,
} from '@/models/User.ts';
import UserNodeService from '@/services/node/UserNodeService.ts';
import AuthService from '@/services/auth/authService.ts';
import { TwoAuthIsEnable } from '@/models/TwoAuth.ts';
import twoAuth from '@/services/nest/user/twoAuth.ts';

interface RootState {
  version: string;
}
export interface UserState {
  currentUser: UserClass;
  loadingUser: boolean;
  loadingUserCompany: boolean;
  currentUserCompanies: Array<CompanyClass>;
  currentUserAccesses: CommonAccesses;
  UserIsAuth: boolean;
  UserTwoAuth: TwoAuthIsEnable;
}

const actions: ActionTree<UserState, RootState> = {
  async getCurrentUser({ commit, dispatch }) {
    commit('SET_LOADING_USER', true);
    const data = await userService.getUser();
    commit('SET_CURRENT_USER', data);
    dispatch('getCurrentUserCompanies');
    await dispatch('getCurrentUserAccesses');
    await dispatch('userTwoAuth');
    enableToolsUser(data);
    userCheck(data, commit);
    commit('SET_LOADING_USER', false);
    commit('SET_USER_AUTH', true);
    return data;
  },
  async userTwoAuth({ commit }) {
    const data = await twoAuth.isEnable();
    commit('SET_USER_TWO_AUTH', data);
  },
  reloadUser({ commit, dispatch }) {
    return userService.getUser().then((data) => {
      commit('SET_CURRENT_USER', data);
      dispatch('getCurrentUserAccesses');
    });
  },
  async getCurrentUserCompanies({ commit, getters }) {
    commit('SET_LOADING_USER_COMPANY', true);
    const data = await userService.getUserCompanies(getters.userId);
    commit('SET_USER_COMPANIES', data);
    commit('SET_LOADING_USER_COMPANY', false);
  },
  async getCurrentUserAccesses({ commit }) {
    const data = await userService.getUserAccess();
    commit('SET_CURRENT_USE_ACCESSES', data);
  },
  async updateCurrentUser({ state, commit }, user: Partial<User>) {
    const newUser = new UserClass({ ...state.currentUser, ...user });
    const updatedUser = await userService.updateUser(newUser);
    commit('SET_CURRENT_USER', updatedUser);

    return updatedUser;
  },
  async updateUserProfile({ commit }, user: UserEdit) {
    const updatedUser = await userService.edit(user);
    commit('SET_CURRENT_USER', updatedUser);
    return updatedUser;
  },
  async confirmEmail({ commit }, params: ConfirmEmaiLParams) {
    const user = await userService.confirmEmailInProfile(params);
    commit('SET_CURRENT_USER', user);

    return user;
  },
  async updateNotifications({ commit }, notifications: UserNotifications) {
    const user = await userService.updateNotifications({
      notifications,
    });
    commit('SET_CURRENT_USER', user);

    return user;
  },
  async logoutUser({ commit }) {
    try {
      await AuthService.siteLogout();
      // await UserNodeService.authEvents({ event: 'LOGOUT', tabId: tabID });
      logout();
      await UserNodeService.logout();
      commit('SET_USER_AUTH', false);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },
};

const mutations: MutationTree<UserState> = {
  SET_USER_COMPANIES(state, data: Array<Company>) {
    state.currentUserCompanies = data.map(
      (company) => new CompanyClass(company)
    );
  },
  SET_LOADING_USER(state, value) {
    state.loadingUser = value;
  },
  SET_LOADING_USER_COMPANY(state, value: boolean) {
    state.loadingUserCompany = value;
  },
  SET_CURRENT_USER(state, user: User) {
    state.currentUser = new UserClass(user);
  },
  SET_CURRENT_USE_ACCESSES(state, accesses) {
    state.currentUserAccesses = accesses;
  },
  SET_USER_AUTH(state, val: boolean) {
    state.UserIsAuth = val;
  },
  SET_USER_TWO_AUTH(state, val: TwoAuthIsEnable) {
    state.UserTwoAuth = val;
  },
};

const getters: GetterTree<UserState, RootState> = {
  // eslint-disable-next-line no-underscore-dangle
  userId: (state) => state.currentUser._id.$oid,
  isLogin: (state) => state.currentUser.role.length > 0,
  rootUser: (state) => state.currentUser.role.includes('root'),
  currentUser: (state) => state.currentUser,
  selectedCompanyId: (state) => state.currentUser?.selectedCompanyId,
  currentUserCompanies: (state) => state.currentUserCompanies,
  userName: (state) => state.currentUser.name,
  userSurname: (state) => state.currentUser.surname,
  loadingUser: (state) => state.loadingUser,
  loadingUserCompany: (state) => state.loadingUserCompany,
  currentUserAccesses: (state) => state.currentUserAccesses,
};
const state: UserState = {
  currentUser: new UserClass(defaultUser),
  loadingUser: true,
  loadingUserCompany: true,
  currentUserCompanies: [],
  currentUserAccesses: initialCommonAccesses,
  UserIsAuth: false,
  UserTwoAuth: { isTwoFaAuthenticated: false, isTwoFactorEnable: false },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
} as Module<UserState, RootState>;
