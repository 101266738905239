export const inactiveSubscriptionLinks = [
  'ProfileSettings',
  'CompanySettings',
  'SubscriptionEnded',
  'BillingMethod',
  'BillingSubscription',
];

type RoutesType = {
  matched: Array<{ name: string }>;
};

export interface CheckCompanyParams {
  subActive: boolean;
  isStandAlone: boolean;
}

export const checkCompanyRoute = (
  params: CheckCompanyParams,
  routes: RoutesType,
  next: (obj: { name: string }) => void
) => {
  const currentPageInactive = routes.matched.some(({ name = '' }) =>
    inactiveSubscriptionLinks.includes(name)
  );
  if (!params.subActive && !currentPageInactive) {
    next({ name: 'SubscriptionEnded' });
  }
};
