import { FormAppearance } from '@/models/cards/PublicCard.ts';
import { defaultBgColor, defaultBtnColor } from '@/components/modules/downloadingCard/config.ts';

class FormApperanceClass implements FormAppearance {

  background: string;

  buttonColor: string;

  logoColor: string;

  textColor: string;

  private defaultBgColor = '#F8F8F8';

  private defaultBtnColor = '#329EF2';

  private defaultBtnTextColor = '#ffffff';

  constructor({
    background = defaultBgColor,
    buttonColor = defaultBtnColor,
    logoColor,
    textColor,
  }: FormAppearance) {
    this.background = background || this.defaultBgColor;
    this.buttonColor = buttonColor || this.defaultBtnColor;
    this.logoColor = logoColor;
    this.textColor = textColor || this.defaultBtnTextColor;
  }
}
export default FormApperanceClass
