import { RouteConfig } from 'vue-router';

export default {
  path: '/cards',
  component: () => import(/* webpackChunkName: "cards" */'@/views/pages/cards/viewIndex.vue'),
  redirect: {
    name: 'cards',
  },
  children: [
    {
      path: '/',
      name: 'cards',
      redirect: {
        name: 'CustomersAll'
      },
    },
    {
      path: ':cardId',
      name: 'currentCard',
      component: () => import('@/views/pages/cards/card/index.new.vue'),
      meta: {
        pageTitle: 'Common.Card',
        defaultTitle: false,
      },
    },
  ]
} as RouteConfig;
