import Vue from 'vue'
import Vuex, { Store } from 'vuex'

import state from './state'
import mutations from './mutations'
import actions from './actions'
import moduleForm from './modules/form'
import shared from './modules/app.js'
import moduleBilling from './modules/billing'
import moduleIntegrations from './modules/integrations'
import user from './modules/user/userStore.ts'
import company from './modules/company/companyStore.ts'
import passTemplate from './modules/passTemplate'
import cards from './modules/cards'
import passteam from './modules/passteam/index.ts'
import appPass from './modules/appPass'
import alerts from './modules/alerts/index.ts'
import integration from './modules/integration/integrationStore.ts'
import registrationCard from './modules/registrationCard'
import { logsClients } from './modules/logsClients/index.ts'
import { getTimeZones } from '@/helpers/company'
import confines from './modules/sharingOpt/index.ts'
import users from './modules/users/usersStore.ts'
import importCards from './modules/import/cards/index.ts';
import onboarding from './modules/onboarding/onboardingStore.ts'
import plans from './modules/plans/plansStore.ts';
import formNew from './modules/form/formStore.ts'

Vue.use(Vuex)

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters: {
    timeZoneForSelect: (state) => state.timeZones ? getTimeZones(state.timeZones) : [],

    checkAccess: state => access => (state.user.currentUserAccesses[access]
      && ( state.integration.currentIntegration.accesses[access] || state.company.currentCompany.registerForm.nameCrm === "withoutCrm" ))
  },
  modules: {
    user,
    company,
    passTemplate,
    shared,
    form: moduleForm,
    billing: moduleBilling,
    integrations: moduleIntegrations,
    cards,
    passteam,
    appPass,
    alerts,
    integration,
    logsClients,
    registrationCard,
    confines,
    users,
    importCards,
    onboarding,
    formNew,
    plans,
  },
  strict: process.env.NODE_ENV !== 'production',
})

export default store
