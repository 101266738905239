class FilterClass {
  constructor({
    variable = true,
    property = '',
    type = 'text',
    operator = '',
    value = '' || [],
    secondValue = '',
  }) {
    this.variable = variable;
    this.property = property;
    this.type = type;
    this.operator = operator;
    this.value = value;
    this.secondValue = secondValue;
  }
}

export default FilterClass
