import { ref } from '@vue/composition-api';
import authService from '@/services/auth/authService';
import { LocalStorageAuthKeys } from '@/components/modules/auth/shared/shared';
import { getLanguageInLocalStorage } from '@/composition/i18nComposition';

export function useChangePassword(root) {
  const token = ref(root.$route.query.tokenValue);
  const email = ref(root.$route.query.email);
  const password = ref('');
  const process = ref(false);
  const passwordDouble = ref('');

  const afterChangePassword = () => {
    localStorage.removeItem(LocalStorageAuthKeys.RESET_EMAIL);
    root.$router.push({ name: 'Login' });
  };

  const sendChangePassword = () => {
    process.value = true;
    authService
      .changePasswordByToken({
        email: email.value,
        newPassword: password.value,
        token: token.value,
        lang: getLanguageInLocalStorage()
      })
      .then(afterChangePassword)
      .catch(() => root.$router.push({ name: 'ResetPassword' }))
      .finally(() => (process.value = false));
  };

  return {
    password,
    passwordDouble,
    process,
    sendChangePassword,
  };
}
