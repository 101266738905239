export default {
    paymentCardInform: {
        brand: '',
        checks: {
            address_line1_check: null,
            address_postal_code_check: '',
            cvc_check: ''
        },
        country: '',
        exp_month: null,
        exp_year: null,
        fingerprint: '',
        funding: '',
        generated_from: null,
        last4: '',
        three_d_secure_usage: {
            supported: false
        },
        wallet: null
    },

    currentTariff: {},
    currentSubscription: {
        discount: {
            coupon: {
                name: ''
            }
        }
    },
    stripePaymentMethod: {},
    defaultPaymentMethod: {},

    invoices: {},
    upcomingInvoices: {
        next_payment_attempt: null,
        discount: {
            coupon: {
                name: '',
                amount_off: null,
                percent_off: null,
            }
        }
    },
    cardInstalledPercent: 0,
    cupon: {},
}
