import Pusher from 'pusher-js';
import Vue from 'vue';

// app_id = "1148727"
// key = "5d4d998c3f14e2e334d6"
// secret = "a37291b7aad7ec3923c2"
// cluster = "eu"

const pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
  cluster: 'eu',
  authEndpoint: '/dev/pusher',
  auth: {
    headers: {
      'X-CSRF-Token': 'SOME_CSRF_TOKEN',
    },
  },
});

export default pusher;

Vue.prototype.$pusher = pusher;
