import AllLanguages from '@/data/allLanguages.ts';
import { Translation } from '@/models/Translation.ts';

const transformToArray = (languages: Array<string>) =>
  AllLanguages.filter((lang) => languages.includes(lang.lang));

/**
 * Сформировать массив
 * @param arr
 * @param names
 */
const generateTranslations: (
  arr: Array<string>,
  names?: Array<string>
) => Array<Translation> = (arr, names = []) =>
  arr.map((l, index) => ({
    language: l,
    value: names[index] ?? names[0] ?? '',
  }));

/**
 * Заполнить недостающий
 * @param arr
 * @param lang
 */
const missingFields: (
  arr: Array<Translation>,
  lang: Array<string>
) => Array<Translation> = (arr, lang) => {
  const diff = lang.filter((lg) => !arr.map((l) => l.language).includes(lg));
  return [...arr, ...generateTranslations(diff, [''])];
};


/**
 * Убрать лишние
 * @param arr
 * @param lang
 */
const removeExcessFields: (
  arr: Array<Translation>,
  lang: Array<string>
) => Array<Translation> = (arr, lang) => {
  return [...arr.filter(a => lang.includes(a.language))];
};

/**
 * УБрать и добавить языки
 * @param arr
 * @param lang
 */
const removeAndMissingLanguagesFields = (
  arr: Array<Translation>,
  lang: Array<string>) => removeExcessFields(missingFields(arr, lang), lang)

export {
  // eslint-disable-next-line import/prefer-default-export
  transformToArray,
  generateTranslations,
  missingFields,
  removeExcessFields,
  removeAndMissingLanguagesFields
}
