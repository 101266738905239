import { RouteConfig } from 'vue-router';

export default {
  path: 'geo-pushes',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  meta: {
    requiredAuth: true,
  },
  children: [
    {
      path: '',
      name: 'GeoPushes',
      component: () => import(/* webpackChunkName: 'automation' */ '@/views/geoPushes/index.vue'),
      meta: {
        pageTitle: 'layoutMain.geoPushes',
        defaultTitle: false,
      },
    },
  ],
} as RouteConfig;
