import {
  ActionTree, GetterTree, Module, MutationTree
} from 'vuex';
import { Integration, SynchronizationData } from '@/models/integrations/Integration.ts';
import { IntegrationsNamesT } from '@/helpers/integrationsNamesT.ts';
import integrationsService from '@/services/integrations/integrationsService.ts';
import yclientsStore from '@/store/modules/integration/yclientsStore.ts';
import altegioStore from '@/store/modules/integration/altegioStore.ts';
import { transformToArray } from '@/helpers/languageHelper.ts';
import { synchData, integrationInitial } from '@/store/modules/integration/initialState.ts';
import pusher from '@/plugins/pusher.ts';

interface RootState {
  version: string;
}
export interface IntegrationState {
  currentIntegrationData: {};
  currentIntegration: Partial<Integration>;
  syncData: SynchronizationData;
}

const actions: ActionTree<IntegrationState, RootState> = {
  async getCurrentIntegration({ commit, dispatch }, payload) {
    const data = await integrationsService.getCurrentIntegration()
    if (typeof data === 'object') commit('SET_CURRENT_INTEGRATION', data)
    await dispatch('getSynchronizationData')
    dispatch('subscribeSocket')
    return data
  },
  async getCurrentIntegrationData({ commit }, companyId) {
    const integration = await integrationsService.getIntegrationData(companyId)
    commit('SET_CURRENT_INTEGRATION_DATA', integration)
    return integration
  },
  async getSynchronizationData({ commit }) {
    const syncdata = await integrationsService.getSynchronizationData()
    commit('SET_SYNCH_DATA', syncdata)
    return syncdata
  },
  async clearDataIntegration({ commit, dispatch }) {
    commit('SET_CURRENT_INTEGRATION', integrationInitial);
    commit('SET_CURRENT_INTEGRATION_DATA', {});
  },
  subscribeSocket({ commit, dispatch, rootGetters }) {
    const companyId = rootGetters['company/companyId']
    const channel = pusher.subscribe(`company_${companyId}`);
    channel.bind('synchronization', (data: SynchronizationData) => {
      commit('SET_SYNCH_DATA', data);
      if (!data.synchronization) {

      }
    });
  },
}
const mutations: MutationTree<IntegrationState> = {
  SET_CURRENT_INTEGRATION(state, integration: Integration) {
    state.currentIntegration = integration
  },
  SET_CURRENT_INTEGRATION_DATA(state, integration) {
    state.currentIntegrationData = integration
  },
  SET_SYNCH_DATA(state, integration) {
    state.syncData = integration
  },
}

const getters: GetterTree<IntegrationState, RootState> = {
  currentIntegration: state => state.currentIntegration,
  currentIntegrationFields: state => state.currentIntegration.fields,
  createCard: state => ('createCard' in state.currentIntegration ? state.currentIntegration.createCard : false),
  deleteCard: state => ('deleteCard' in state.currentIntegration ? state.currentIntegration.deleteCard : false),
  updateCard: state => ('updateCard' in state.currentIntegration ? state.currentIntegration.updateCard : false),
  currentIntegrationData: state => state.currentIntegrationData,
  isPosterIntegration: state => state.currentIntegration.systemName === IntegrationsNamesT.POSTER,
  isYclientsIntegration: state => state.currentIntegration.systemName === IntegrationsNamesT.YCLIENTS_NEW ||
    state.currentIntegration.systemName === IntegrationsNamesT.YCLIENTSV3,
  isAltegioIntegration: state => state.currentIntegration.systemName === IntegrationsNamesT.ALTEGIO,
  isSquareIntegration: state => state.currentIntegration.systemName === IntegrationsNamesT.SQUARE,
  isIikoIntegration: state => state.currentIntegration.systemName === IntegrationsNamesT.IIKO,
  isQuickRestoIntegration: state => state.currentIntegration.systemName === 'QuickResto',
  currentIntegrationAccesses: state => state.currentIntegration.accesses,
  transformLanguages: state => transformToArray(state.currentIntegration.languages ?? []),
}
const state: IntegrationState = {
  currentIntegration: integrationInitial,
  currentIntegrationData: {},
  syncData: synchData
};
export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
  modules: {
    yclients: yclientsStore,
    altegio: altegioStore,
  }
} as Module<IntegrationState, RootState>;
