import ApiWrap from '@/services/ApiWrap.ts';
import {
  BasicModelAltegio,
  AltegioAuthResponse, AltegioCompany, AltegioGroups,
  AltegioIntegrationData,
  AltegioSynchronizationData
} from '@/models/integrations/Altegio.ts';
import { IBackendResponse } from '@/services/types.ts';
import {Company} from "@/models/company/Company";
import { ActionsRequestCards } from '@/models/cards/CardsClients.ts';

interface AuthAltegio {
  login: string;
  password: string;
}

class AltegioService extends ApiWrap {
  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/371720196/Auth
   * @param login
   * @param password
   */
  static async auth(login: string, password: string): Promise<AltegioAuthResponse> {
    const data = await this.post<IBackendResponse<AltegioAuthResponse>, AuthAltegio>('/integrations/altegio/auth', { login, password }, { errorSuppression: true })
      .then(res => res.data.data)
    return data;
  }

  /**
   * Метод позволяет вернуть все сохраненные раннее настройки интеграции
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/371916834/getIntegrationData
   * @return {Promise<AltegioIntegrationData>}
   */
  static async getIntegrationData(): Promise<AltegioIntegrationData> {
    const { data } = await this.get<IBackendResponse<AltegioIntegrationData>>('/integrations/altegio/getIntegrationData')
      .then(res => res.data)
    return data
  }

  static async saveIntegrationData(integration: AltegioIntegrationData): Promise<AltegioIntegrationData> {
    const { data } = await this.post<IBackendResponse<AltegioIntegrationData>, AltegioIntegrationData>('/integrations/altegio/saveIntegrationData', integration)
      .then(res => res.data)
    return data
  }

  /**
   * Метод позволяет получить список доступных для сети типов лояльности
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/371752982/getLoyaltyTypes
   */
  static async getLoyaltyTypes(): Promise<Array<BasicModelAltegio>> {
    const { data } = await this.get<IBackendResponse<Array<BasicModelAltegio>>>('/integrations/altegio/getLoyaltyTypes')
      .then(res => res.data)
    return data
  }

  /**
   * Метод позволяет получить список доступных для пользователя сетей в Yclients
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/371916808/getGroups
   */
  static async getGroups(): Promise<Array<AltegioGroups>> {
    const { data } = await this.get<IBackendResponse<AltegioGroups>>('/integrations/altegio/getGroups')
      .then(res => res.data)
    return data
  }

  /**
   * Метод позволяет получить список доступных филиалов для выбранного типа карт
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/372244485/getCompanies
   */
  static async getCompanies(): Promise<Array<AltegioCompany>> {
    const { data } = await this.get<IBackendResponse<AltegioGroups>>('/integrations/altegio/getCompanies')
      .then(res => res.data);

    return data;
  }

  static async getSynchronizationData(): Promise<AltegioSynchronizationData> {
    const { data } = await this.get<IBackendResponse<AltegioGroups>>('/integrations/altegio/getSynchronizationData')
      .then(res => res.data)
    return data
  }

  /**
   * Метод позволяет запустить синхронизацию клиента
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/372244498/startSynchronization
   */
  static async startSynchronization(): Promise<AltegioSynchronizationData> {
    const { data } = await this.get<IBackendResponse<AltegioSynchronizationData>>('/integrations/altegio/startSynchronization')
      .then(res => res.data)
    return data
  }

  /**
   * Позволяет поменять статуса поля компании integrationErrorStatus
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/400687105/hangeIntegrationErrorStatus
   * @param value
   */
  static async changeIntegrationErrorStatus(value: boolean) {
    const { data } = await this.post<IBackendResponse<AltegioIntegrationData>, Pick<AltegioIntegrationData, 'integrationError'>>('/integrations/changeIntegrationErrorStatus', { integrationError: value })
      .then(res => res.data)
    return data
  }

  /**
   * Метод позволяет обновить список филиалов в шаблоне, если выбран скрипт “На выбор клиента”
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/371687442/syncCompaniesList
   */
  static async syncCompaniesList(): Promise<AltegioIntegrationData> {
    const { data } = await this.get<IBackendResponse<AltegioIntegrationData>>('/integrations/altegio/syncCompaniesList')
      .then(res => res.data)
    return data
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/705429505/AddPhoneToCards
   */
  static async addPhoneCards() {
    const { data } = await this.get<IBackendResponse<string>>('/integrations/altegio/addPhoneToCards')
      .then(res => res.data)
    return data
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/772898817/disableIntegration+YclientsNew
   */
  static async disableIntegration() {
    const { data } = await this.post<IBackendResponse<Company>, null>('/integrations/altegio/disableIntegration')
      .then(res => res.data)
    return data
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/770048003/changeAddCardToClientScript
   */
  static async changeAddCardToClientScript(addCardAfterInstall: boolean) {
    const { data } = await this.post<IBackendResponse<Company>, { addCardAfterInstall: boolean }>('/integrations/altegio/changeAddCardToClientScript',
      { addCardAfterInstall })
      .then(res => res.data)
    return data
  }

  /**
   * Индивидуальная синхронизация по идентификатору карты
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/822706177/Yclients+SyncCard
   * @returns {Promise<string>}
   * @param cardId
   */
  static async syncCard(cardId: string): Promise<string> {
    const { data } = await this.post<IBackendResponse<string>>(
      '/integrations/altegio/syncCard',
      cardId,
    ).then((res) => res.data);
    return data;
  }

  /**
   * Метод позволяет осуществляет идентивидуальную синхронизацию
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/822542337/Yclients+syncByFilter
   * @returns {Promise<string>}
   * @param cardsFilter
   */
  static async syncByFilter(cardsFilter: ActionsRequestCards): Promise<string> {
    const { data } = await this.post<IBackendResponse<string>>(
      '/integrations/altegio/syncByFilter',
      cardsFilter,
    ).then(res => res.data)
    return data
  }

  /**
   * Метод позволяет сбросить ошибку о удалении типа карт
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/829882371/resetIntegrationErrors
   * @returns {Promise<string>}
   */
   static async resetIntegrationErrors(): Promise<string> {
    const { data } = await this.post<IBackendResponse<string>>(
      '/integrations/altegio/resetIntegrationErrors'
    ).then(res => res.data)
    return data
  }
}

export default AltegioService;
