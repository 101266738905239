import { Plan } from '@/models/plan/Plan.ts';
import { CommonAccesses } from '@/models/Accesses.ts';

export const access: CommonAccesses = {
  sms: false,
  email: false,
  emailAfterCreateCard: false,
  smsAfterCreateCard: false,
  campaigns: false,
  automation: false,
  duplicateSearch: false,
  generatingNumbers: false,
  pushNotifications: false,
  geoNotifications: false,
  sharingOptions: false,
  api: false,
  apiCreateTemplate: false,
  shortUrls: false,
  export: false,
  import: false,
  additionalImages: false,
  iBeacon: false,
  variables: false,
  connectToApp: false,
  getCardForm: false,
  complexBarcode: false,
  webhooks: false,
  promoMaterials: false,
  integrationSettings: false,
  onBoarding: false,
  serviceMarketplace: false,
  rfm: false,
  utm: false,
  useEnrichment: false,
  logs: false,
  webPush: false,
  cardsHistoryMonthLimit: false,
};

export const defaultPlan: Plan = {
  availableInCountries: [],
  hubspotName: '',
  isArchive: false,
  isStripe: false,
  _id: {
    $oid: '',
  },
  name: 'Профессиональный',
  pricing: {
    monthly: '0',
    annually: '0',
    sms: '0',
  },
  whiteLabel: false,
  baseCountCards: 4000,
  maxCountTemplates: 1,
  maxCountUsers: 4000,
  apiRequestLimitDay: 7000,
  apiRequestLimitHour: 300,
  isPublic: true,
  order: 3,
  stripeMonthlyPlanId: '',
  stripeAnnuallyPlanId: '',
  accesses: access,
  additionalDevices: {
    step: 1000,
    maxCountCards: 50000,
    prices: [
      {
        devicesFrom: 0,
        devicesTo: 46000,
        priceForOneDevice: '0.50',
        priceForOneDeviceAnnually: '0.40',
        id: 0,
      },
      {
        devicesFrom: 46000,
        devicesTo: 50000,
        priceForOneDevice: '0.80',
        priceForOneDeviceAnnually: '0.59',
        id: 0,
      },
    ],
  },
  standAlonePricing: {
    accesses: {
      email:  true,
      pushNotifications: false,
      webPush: false,
      smsSenderName: true,
      whiteLabel: false
    },
    customersPricing: [
        {
            maxCustomers: 100,
            minCustomers: 0,
            monthlyPrice: 100,
            annuallyPrice: 1200
        }
    ],
    baseCustomersCount: 1000,
    maxCustomersCount: 10000,
    emailPricing: {
        multiplier: 15,
        oneElementPrice: 15,
        stripePrice: ''
    },
    walletPushPricing: {
        multiplier: 4,
        oneElementPrice: 4,
        stripePrice: ''
    },
    webPushPricing: {
        multiplier: 2,
        oneElementPrice: 2,
        stripePrice: ''
    },
    whatsAppPricing: {
        multiplier: 1,
        oneElementPrice: 0,
        stripePrice: ''
    },
    mobilePushPricing: {
        price: 100,
        stripePrice: ''
    },
    smsSenderName: {
        price: 0,
        stripePrice: ''
    },
    whiteLabel: {
        price: 0,
        stripePrice: ''
    },
    yclientsIntegration : {
        price: 0,
        stripePrice: ''
    }
  },
  updated: 0,
  created: 0,
  currency: 'rub',
  forNewCompanies: true,
  individual: false
};
