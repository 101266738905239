<template>
  <button
    class="btn btn-lg p-0 text-primary text-14 font-weight-normal align-self-center line-height-1-5"
    :disabled="sendStatus"
  >
    {{ $t(emailBtnText) }}
    <transition name="fade">
      <b-spinner small variant="primary" v-if="loading"></b-spinner>
    </transition>
  </button>
</template>

<script>
export default {
  name: 'bottomBtn',
  props: {
    sendStatus: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    emailBtnText() {
      return this.sendStatus ? 'Common.sent' : 'AuthPage.emailResend'
    }
  }
};
</script>

<style scoped></style>
