import axios, { AxiosInstance } from 'axios';
import { addAccessToken } from '@/utils/auth.ts';
import AssayerLogInterceptor from '@/http/axios/AssayerLog.interceptor.ts';

const nestServer: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_PROXY_DEV
    ? ''
    : process.env.VUE_APP_NODE_NEST_URL,
});

nestServer.interceptors.request.use(addAccessToken);

nestServer.interceptors.response.use(
  AssayerLogInterceptor.onFulfilled,
  AssayerLogInterceptor.onRejected
);


export default nestServer
