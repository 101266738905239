import { ComponentNamesT } from '@/components/modules/onboarding/steps/index.ts';

const addLabel: (
  steps: Array<Omit<Steps, 'label'>>,
  label: string
) => Array<Steps> = (steps, label) => steps.map((s) => ({ ...s, label }));

export const systemNames = [
  /* Подключение YClients */
  'YclientsStepConnection',
  /* Подключение Altegio */
  'AltegioStepConnection',
  /* Подключение Poster */
  'PosterStepConnection',
  /* Подключение RetailCRm */
  'RetailCrmStepConnect',
  /* Подключение Cft */
  'CftStepConnectForm',
  /* Подключение InSales */
  'InSalesStepConnectForm',
  /* Подключение QuickRest */
  'QuickRestoStepConnect',
  /* Подключение Square */
  'SquareStepConnect',
  /* Подключение Mindbox */
  'MindboxStepConnect',
  /* Подключение Iiko */
  'IikoStepConnect',
  /* Настройте программу лояльности RetailCRm  */
  'RetailCrmSettingsLoaylty',
  /* Настройте программу лояльности Poster  */
  'PosterSettingsLoaylty',
  /* Настройте программу лояльности Cft  */
  'CftSettingsLoyalty',
  /* Настройте программу лояльности InSales  */
  'InsalesSettingsLoaylty',
  /* Настройте программу лояльности QuickRest  */
  'QuickRestoSettingsLoaylty',
  /* Добавьте собственные переменные */
  'OnBoardVariables',
  /*  Выбор таймзоны */
  'OnBoardStepSelectTimezone',
  /*  Выбор Языков */
  'onBoardStepSelectLanguages',
  /* Дизайн карты  */
  'OnBoardDesignCard',
  /* Настройки формы  */
  'OnBoardFormSettings',
  /* Настройки Ограничений  */
  'OnBoardSharingOpt',
  /* Скачивание карты  */
  'OnBoardDownloadCard',
  /* Отправьте первое push-уведомление   */
  'OnBoardSendPush',
  /* Проверьте процесс использования карты   */
  'OnBoardProcessUses',
  /* 'Добавьте ссылку на анкету регистрации в программе лояльности в соцсетях и на сайте   */
  'OnBoardAddLinkSoc',
  /* Установите тейблтенты с QR-кодом  */
  'OnBoardTablent',
  /* Отправьте своим клиентам смс с готовыми картами  */
  'OnBoardSendSms',
  /* Push-рассылка  */
  'OnBoardMarketingPush',
  /* Автоматизация  */
  'OnBoardAutomatization',
  /* Геоуведомления  */
  'OnBoardGeo',
  /* Выберите языки  */
  'OnBoardLanguages',
  /* Подготовьте клиентскую базу  */
  'OnBoardCustomerBase',
  /* Добавьте собственные переменные  */
  'OnBoardVariables',
  /* Настройте онлайн-анкету  */
  'OnBoardEnrollmentForm',
  /* Подключите Пасслинк  */
  'OnBoardPasslink',
  /* Разместите ссылки в соцсетях и на сайте  */
  'OnBoardLinks',
  /* Разместите рекламные материалы с QR-кодом  */
  'OnBoardPromotionalMaterials',
  /* Настройте дозаполнение данных о клиентах */
  'OnBoardEnrichment',
  /* Пуш-рассылка (Apple Wallet) */
  'OnBoardAppleWalletPush',
  /* SMS-рассылка */
  'OnBoardSMS',
  /* Рассылка по геопозиции (Apple Wallet) */
  'OnBoardGeoTargetedPush',
  /* iBeacon-рассылка (Apple Wallet) */
  'OnBoardIBeacon',
  /* Веб пуш-рассылка (Google) */
  'OnBoardWebPushGoogle',
  /* Автоматизации */
  'OnBoardAutomations',
  /* Подключите виртуальные карты */
  'OnBoardVirtualCards',
  /* Настройте дизайн виртуальных карт */
  'OnBoardCardsDesign',
  /* Настройте ограничения на выдачу карт */
  'OnBoardRestrictions',
  /* Скачайте карту */
  'OnBoardCardDownload',
  /* Отправьте своим клиентам SMS-рассылку с готовыми картами */
  'OnBoardSMSWithCards',
] as const;

export type systemNamesT = typeof systemNames[number];

interface Steps {
  componentName: ComponentNamesT;
  systemName: systemNamesT;
  label: string;
  text?: string;
  link?: string;
  linkText?: string;
  needLoyaltyCards?: boolean;
}

const loayltySettingsCollection: Array<Omit<Steps, 'label'>> = [
  {
    systemName: 'RetailCrmSettingsLoaylty',
    componentName: 'RetailCrmSettingsLoaylty',
  },
  {
    systemName: 'PosterSettingsLoaylty',
    componentName: 'PosterSettingsLoaylty',
  },
  {
    systemName: 'CftSettingsLoyalty',
    componentName: 'CftSettingsLoyalty',
  },
  {
    systemName: 'InsalesSettingsLoaylty',
    componentName: 'InsalesSettingsLoaylty',
  },
  {
    systemName: 'QuickRestoSettingsLoaylty',
    componentName: 'QuickRestoSettingsLoaylty',
  },
];

const connectedSteps: Array<Steps> = [
  {
    systemName: 'YclientsStepConnection',
    componentName: 'YclientsStepConnection',
    label: 'connectIntegrationWithYclients'
  },
  {
    systemName: 'AltegioStepConnection',
    componentName: 'AltegioStepConnection',
    label: 'connectIntegrationWithAltegio'
  },
  {
    systemName: 'PosterStepConnection',
    componentName: 'PosterStepConnection',
    label: 'connectIntegrationWithPoster'
  },
  {
    systemName: 'RetailCrmStepConnect',
    componentName: 'RetailCrmStepConnect',
    label: 'connectIntegrationWithRetailCRM'
  },
  {
    systemName: 'InSalesStepConnectForm',
    componentName: 'InSalesStepConnectForm',
    label: 'connectIntegrationWithInSales'
  },
  {
    systemName: 'QuickRestoStepConnect',
    componentName: 'QuickRestoStepConnect',
    label: 'connectIntegrationWithQuickResto'
  },
  {
    systemName: 'SquareStepConnect',
    componentName: 'SquareStepConnect',
    label: 'connectIntegrationWithSquare'
  },
  {
    systemName: 'CftStepConnectForm',
    componentName: 'CftStepConnectForm',
    label: 'connectIntegrationWithQuickCFT'
  },
  {
    systemName: 'MindboxStepConnect',
    componentName: 'MindboxStepConnect',
    label: 'connectIntegrationWithMindbox'
  },
  {
    systemName: 'IikoStepConnect',
    componentName: 'IikoStepConnect',
    label: 'connectIntegrationWithIiko'
  },
]

const standAloneSteps: Array<Steps> = [
  {
    systemName: 'OnBoardLanguages',
    componentName: 'OnBoardUniversalStep',
    label: 'selectLanguagesTitle',
    text: 'onBoarding.selectLanguagesText',
    link: 'CompanySettingsLanguages',
    linkText: 'onBoarding.selectBtn',
  },
  {
    systemName: 'OnBoardCustomerBase',
    componentName: 'OnBoardUniversalStep',
    label: 'customerBaseTitle',
    text: 'onBoarding.customerBaseText',
    link: 'CustomersAll',
    linkText: 'onBoarding.importBtn',
  },
  {
    systemName: 'OnBoardVariables',
    componentName: 'OnBoardVariables',
    label: 'variablesTitle',
  },
  {
    systemName: 'OnBoardEnrollmentForm',
    componentName: 'OnBoardUniversalStep',
    label: 'onlineQuestionnaireTitle',
    text: 'onBoarding.onlineQuestionnaireText',
    link: 'FormSettingsMain',
    linkText: 'onBoarding.customizeBtn',
  },
  {
    systemName: 'OnBoardPasslink',
    componentName: 'OnBoardUniversalStep',
    label: 'passlinkTitle',
    text: 'onBoarding.passlinkText',
    link: 'PasslinkSettings',
    linkText: 'onBoarding.connectBtn',
  },
  {
    systemName: 'OnBoardLinks',
    componentName: 'OnBoardLinks',
    label: 'socialNetworksTitle',
    text: 'onBoarding.socialNetworksText',
  },
  {
    systemName: 'OnBoardPromotionalMaterials',
    componentName: 'OnBoardUniversalStep',
    label: 'QRCodeTitle',
    text: 'onBoarding.QRCodeText',
    link: 'BrandMaterials',
    linkText: 'onBoarding.goToMarketplaceBtn',
  },
  {
    systemName: 'OnBoardEnrichment',
    componentName: 'OnBoardUniversalStep',
    label: 'prepopulatedTitle',
    text: 'onBoarding.prepopulatedText',
    link: 'FormSettingsMain',
    linkText: 'onBoarding.customizeBtn',
  },
  {
    systemName: 'OnBoardVirtualCards',
    componentName: 'OnBoardUniversalStep',
    label: 'virtualCardsTitle',
    text: 'onBoarding.virtualCardsText',
    link: 'VirtualCardSettings',
    linkText: 'onBoarding.connectBtn',
  },
  {
    systemName: 'OnBoardCardsDesign',
    componentName: 'OnBoardUniversalStep',
    label: 'designCardsTitle',
    text: 'onBoarding.designCardsText',
    link: 'DesignCard',
    linkText: 'onBoarding.customizeBtn',
  },
  {
    systemName: 'OnBoardRestrictions',
    componentName: 'OnBoardUniversalStep',
    label: 'limitsCardTitle',
    text: 'onBoarding.limitsCardText',
    link: 'confineIndex',
    linkText: 'onBoarding.customizeBtn',
    needLoyaltyCards: true,
  },
  {
    systemName: 'OnBoardCardDownload',
    componentName: 'OnBoardCardDownload',
    label: 'cardDownloadTitle',
    text: 'onBoarding.cardDownloadText',
    linkText: 'onBoarding.downloadCardbtn',
    needLoyaltyCards: true,
  },
  {
    systemName: 'OnBoardSMSWithCards',
    componentName: 'OnBoardUniversalStep',
    label: 'smsSendTitle',
    text: 'onBoarding.smsSendText',
    link: 'AllCampaign',
    linkText: 'onBoarding.createCampaignBtn',
    needLoyaltyCards: true,
  },
  {
    systemName: 'OnBoardAppleWalletPush',
    componentName: 'OnBoardUniversalStep',
    label: 'pushMailingTitle',
    text: 'onBoarding.pushMailingText',
    link: 'AllCampaign',
    linkText: 'onBoarding.createCampaignBtn',
    needLoyaltyCards: true,
  },
  {
    systemName: 'OnBoardSMS',
    componentName: 'OnBoardSMS',
    label: 'smsMailingTitle',
    text: 'onBoarding.smsMailingText',
    link: 'AllCampaign',
    linkText: 'onBoarding.createCampaignBtn',
  },
  {
    systemName: 'OnBoardGeoTargetedPush',
    componentName: 'OnBoardGeo',
    label: 'geoLocationTitle',
    text: 'onBoarding.geoLocationText',
    link: 'AllCampaign',
    linkText: 'onBoarding.createCampaignBtn',
    needLoyaltyCards: true,
  },
  {
    systemName: 'OnBoardIBeacon',
    componentName: 'OnBoardUniversalStep',
    label: 'iBeaconTitle',
    text: 'onBoarding.iBeaconText',
    link: 'AllCampaign',
    linkText: 'onBoarding.createCampaignBtn',
    needLoyaltyCards: true,
  },
  {
    systemName: 'OnBoardWebPushGoogle',
    componentName: 'OnBoardUniversalStep',
    label: 'webPushTitle',
    text: 'onBoarding.webPushText',
    link: 'AllCampaign',
    linkText: 'onBoarding.createCampaignBtn',
    needLoyaltyCards: true,
  },
  {
    systemName: 'OnBoardAutomations',
    componentName: 'OnBoardUniversalStep',
    label: 'automationTitle',
    text: 'onBoarding.automationText',
    link: 'AllAutomation',
    linkText: 'onBoarding.customizeBtn',
  },
];

export const StepsCollection: Array<Steps> = [
  {
    systemName: 'OnBoardStepSelectTimezone',
    componentName: 'OnBoardStepSelectTimezone',
    label: 'stepSelectTimezone',
  },
  {
    systemName: 'onBoardStepSelectLanguages',
    componentName: 'onBoardStepSelectLanguages',
    label: 'stepSelectLanguages',
  },
  {
    systemName: 'OnBoardDesignCard',
    componentName: 'OnBoardDesignCard',
    label: 'stepCustomizeCardDesign',
  },
  {
    systemName: 'OnBoardFormSettings',
    componentName: 'OnBoardFormSettings',
    label: 'stepCustomizeDistributionForm',
  },
  {
    systemName: 'OnBoardSharingOpt',
    componentName: 'OnBoardSharingOpt',
    label: 'stepCustomizeLimits',
  },
  {
    systemName: 'OnBoardDownloadCard',
    componentName: 'OnBoardDownloadCard',
    label: 'stepDownloadCard',
  },
  {
    systemName: 'OnBoardSendPush',
    componentName: 'OnBoardSendPush',
    label: 'stepSendFirstPush',
  },
  {
    systemName: 'OnBoardProcessUses',
    componentName: 'OnBoardProcessUses',
    label: 'stepCheckCardUsingProcess',
  },
  {
    systemName: 'OnBoardAddLinkSoc',
    componentName: 'OnBoardAddLinkSoc',
    label: 'stepAddLinkToLoyaltyProgramReg',
  },
  {
    systemName: 'OnBoardTablent',
    componentName: 'OnBoardTablent',
    label: 'stepSetupTabletents',
  },
  {
    systemName: 'OnBoardSendSms',
    componentName: 'OnBoardSendSms',
    label: 'stepSendSms',
  },
  {
    systemName: 'OnBoardMarketingPush',
    componentName: 'OnBoardMarketingPush',
    label: 'stepPushes',
  },
  {
    systemName: 'OnBoardAutomatization',
    componentName: 'OnBoardAutomatization',
    label: 'stepAutomatization',
  },
  {
    systemName: 'OnBoardGeo',
    componentName: 'OnBoardGeo',
    label: 'stepGeoPushes',
  },
  ...addLabel(loayltySettingsCollection, 'stepCustomizeLoyaltyProgram'),
  ...connectedSteps,
  ...standAloneSteps,
];
