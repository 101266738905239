import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import {
  AltegioIntegrationData,
  AltegioSynchronizationData,
} from '@/models/integrations/Altegio.ts';
import {
  altegioInitialData,
  altegioSyncInitialData,
} from '@/services/integrations/initialState.ts';
import AltegioService from '@/services/integrations/AltegioService.ts';
import { getStatusAltegio } from '@/helpers/Altegio.ts';
import { IntegrationStatusAltegio } from '@/components/modules/settings/integration/types.ts';
import pusher from '@/plugins/pusher.ts';

interface RootState {
  version: string;
}

interface AltegioStore {
  integrationData: AltegioIntegrationData;
  syncData: AltegioSynchronizationData;
  loading: boolean;
  blockProcess: boolean;
}

const mutations: MutationTree<AltegioStore> = {
  SET_INTEGRATION_DATA(state, payload) {
    state.integrationData = { ...state.integrationData, ...payload };
  },
  SET_SYNC_INTEGRATION_DATA(state, payload: AltegioSynchronizationData) {
    state.syncData = payload;
  },
  SET_LOADING(state, payload: boolean) {
    state.loading = payload;
  },

  SET_PROCESS(state, payload: boolean) {
    state.blockProcess = payload;
  },
};

const actions: ActionTree<AltegioStore, RootState> = {
  async getIntegrationData({ commit, dispatch }, setLoading = true) {
    commit('SET_LOADING', setLoading);
    const data = await AltegioService.getIntegrationData();
    commit('SET_INTEGRATION_DATA', data);
    await dispatch('integration/getSynchronizationData', '', { root: true });
    dispatch('integration/subscribeSocket', '', { root: true });
    commit('SET_LOADING', false);
  },
  async getSyncIntegrationData({ commit }) {
    const syncData = await AltegioService.getSynchronizationData();
    commit('SET_SYNC_INTEGRATION_DATA', syncData);
    return syncData
  },
  subscribeSocket({ commit, dispatch, rootGetters }) {
    const companyId = rootGetters['company/companyId']
    const channel = pusher.subscribe(`company_${companyId}`);
    channel.bind('synchronization', (data: AltegioSynchronizationData) => {
      commit('SET_SYNC_INTEGRATION_DATA', data);
      if (!data.synchronization) {
        dispatch('reloadIntegration');
      }
    });
  },
  async startSyncIntegrationData({ commit, dispatch }) {
    const syncData = await AltegioService.startSynchronization();
    commit('integration/SET_SYNCH_DATA', syncData, { root: true });
    dispatch('integration/subscribeSocket', '', { root: true });
    return syncData;
  },
  saveIntegrationData(
    { state, commit },
    payload: Partial<AltegioIntegrationData>
  ): Promise<AltegioIntegrationData> {
    commit('SET_PROCESS', true);
    const update = { ...state.integrationData, ...payload };
    return new Promise<AltegioIntegrationData>((resolve) => {
      AltegioService.saveIntegrationData(update)
        .then((res: any) => {
          commit('SET_INTEGRATION_DATA', res);
          resolve(res);
        })
        .finally(() => {
          commit('SET_PROCESS', false);
        });
    });
  },
  async connectAndSync(
    { dispatch },
    payload: Partial<AltegioIntegrationData>
  ) {
    await dispatch('startSyncIntegrationData');
    await dispatch('saveIntegrationData', payload);
    return Promise.resolve('Success');
  },
  async reloadIntegration({ dispatch }) {
    dispatch('getIntegrationData', false);
    dispatch('passTemplate/getTemplateObject', '', { root: true })
  },
  clearIntegrationData({ commit }) {
    commit('SET_INTEGRATION_DATA', altegioInitialData);
  },
};

const getters: GetterTree<AltegioStore, RootState> = {
  getStatus: (state) => getStatusAltegio(state.integrationData),
  isWarningStatusSync: (state, gettersThis) =>
    gettersThis.getStatus === IntegrationStatusAltegio.WARNING_SYNC &&
    !state.syncData.synchronization,
  isDangerStatusSync: (state, gettersThis) =>
    gettersThis.getStatus === IntegrationStatusAltegio.DANGER_SYNC &&
    !state.syncData.synchronization,
  isDangerStatus: (state, gettersThis) =>
    gettersThis.getStatus === IntegrationStatusAltegio.DANGER_INT &&
    !state.syncData.synchronization,
  isHas406: (state) => state.integrationData?.errors.some((e: any) => e.code === 406),
  isHas403: (state) => state.integrationData?.errors.some((e: any) => e.code === 403),
  isHas407: (state) => state.integrationData?.errors.some((e: any) => e.code === 407),
  isHas401: (state) => state.integrationData?.errors.some((e: any) => e.code === 401),
  isHas408: (state) => state.integrationData?.errors.some((e: any) => e.code === 408),
  isHas409: (state) => state.integrationData?.errors.some((e: any) => e.code === 409),
};
const state: AltegioStore = {
  integrationData: altegioInitialData,
  loading: true,
  blockProcess: false,
  syncData: altegioSyncInitialData,
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
} as Module<AltegioStore, RootState>;
