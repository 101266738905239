import Vue from 'vue'
import Router from 'vue-router'
import auth from '@/routes/auth/auth.ts';
import logsClients from '@/routes/logsClients.ts';
import { hubspotSetPath } from '@/plugins/analytics';
import registrationForm from '@/routes/registrationForm.ts';
import templateNotFoundRoute from '@/routes/templateNotFoundRoute.ts';
import downLoadingCardRoute from '@/routes/downLoadingCardRoute.ts';
import users from '@/routes/users.ts';
import cards from '@/routes/cards.ts';
import logoutRoute from '@/routes/auth/logoutRoute.ts';
import confines from '@/routes/confines/confines.ts';
import confinesPublic from '@/routes/confines/confinesPublic.ts';
import EmailPublic from '@/routes/settings/EmailPublic.ts';
import settings from '@/routes/settings/settings.ts';
import onboarding from '@/routes/onboarding.ts';
import marketplace from '@/routes/marketplace.ts';
import dashboard from '@/routes/dashboard.ts';
import importCards from '@/routes/import/cards.ts';
import settingsForm from '@/routes/settingsForm.ts';
import other from '@/routes/other.ts';
import promotionalMaterials from '@/routes/promotionalMaterials.ts';
import admin from '@/routes/admin/index.ts';
import mailings from '@/routes/campaign/index.ts';
import routerMiddleware from '@/middleware/RouterMiddleware.ts';
import automation from '@/routes/automation.ts';
import geoPushes from '@/routes/geoPushes.ts';
import customers from '@/routes/customers.ts';
import sales from '@/routes/sales.ts';
import products from '@/routes/products.ts';
import DesignRoutes from '@/routes/design/DesignRoutes.ts';
import feedbackRoutes from '@/routes/feedbackRoutes.ts';
import PasslinkRoute from '@/routes/PasslinkRoute.ts';
import billing from '@/routes/billing.ts';
import SubscriptionEnded from '@/routes/subscriptionEnded.ts';

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [

    {
      path: '',
      component: () => import('./layouts/main/PassteamMain.vue'),
      meta: {
        requiresAuth: true,
        checkSubscription: true,
        checkRegisterStep: true,
      },
      children: [
        {
          path: '/',
          name: 'CallBackPage',
          component: () => import('@/views/Callback.vue'),
        },
        {
          path: 'template-not-found',
          name: 'TemplateNotFound',
          component: () => import('@/views/TemplateNotFound.vue'),
        },

        /**
         * @deprecated
         */
        {
          path: '/pages/company-settings/:companyId',
          name: 'page-company-settings',
          redirect: { name: 'CompanySettings' },
        },

        /**
         * @deprecated
         */
        {
          path: '/pages/user-settings/id/:userId',
          name: 'page-user-settings',
          redirect: { name: 'ProfileSettings' },
        },
        /**
         * @deprecated
         */
        {
          path: '/pages/distribution/form-creation/:templateId',
          name: 'form-creation',
          redirect: { name: 'FormSettingsMain' },
        },
        /**
         * @deprecated
         */
        {
          path: 'pages/constructor',
          name: 'constructor',
          redirect: { name: 'DesignCard' },
        },
        /**
         * @deprecated
         */
        {
          path: '/pages/account-setup/:templateId',
          name: 'account-setup',
          redirect: { name: 'OnboardingNew' },
        },
        cards,
        confines,
        settings,
        users,
        onboarding,
        marketplace,
        dashboard,
        importCards,
        settingsForm,
        promotionalMaterials,
        other,
        admin,
        automation,
        geoPushes,
        mailings,
        customers,
        sales,
        products,
        DesignRoutes,
        PasslinkRoute,
        billing,
        SubscriptionEnded
      ],
    },
    {
      path: '',
      component: () => import('@/layouts/FullPage.vue'),
      meta: {
        publicPage: true
      },
      children: [
        {
          path: '/pages/form-registration/:templateId',
          name: 'form-registration',
          redirect: { name: 'registrationForm' },
        },
        {
          path: '/error-400',
          name: 'page-error-400',
          component: () => import('@/views/errors/Error400.vue'),
        },
        {
          path: '/error-403',
          name: 'page-error-403',
          component: () => import('@/views/errors/Error403.vue'),
        },
        {
          path: '/error-404',
          name: 'page-error-404',
          component: () => import('@/views/errors/Error404.vue'),
        },
        {
          path: '/error-500',
          name: 'page-error-500',
          component: () => import('@/views/errors/Error500.vue'),
        },
        {
          path: '/error-oops',
          name: 'page-error-oops',
          component: () => import('@/views/errors/ErrorOops.vue'),
        },
      ]
    },
    auth,
    logsClients,
    logoutRoute,
    downLoadingCardRoute,
    registrationForm,
    confinesPublic,
    EmailPublic,
    feedbackRoutes,
    {
      path: '/recovery/:cardId',
      name: 'RecoveryCard',
      component: () => import(/* webpackChunkName: "recovery" */ '@/views/RecoveryCard.vue'),
    },
    {
      path: '/ps/:passlinkId',
      name: 'PasslinkPage',
      component: () => import(/* webpackChunkName: "passlink" */ '@/views/PasslinkPage.vue'),
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/error-404'
    }
  ],
})

router.beforeEach(routerMiddleware)
router.afterEach(to => {
  hubspotSetPath(to.path)
})

export default router
