import ApiWrap from '@/services/ApiWrap.ts';
import { IBackendResponse } from '@/services/types.ts';
import { SharingOptions } from '@/models/confines/SharingOptions.ts';

type template = { templateId: string }
type savesharingOptions = {
  alreadyInstalledCard: SharingOptions['alreadyInstalledCard'];
  deletedCard: SharingOptions['deletedCard'];
  subscriptionEnd: SharingOptions['subscriptionEnd'];
}

class SharingService extends ApiWrap {
  /**
   * Получение настроек ограничения шаблона
   * Для любого пользователя
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/317685761/getSharingOptions
   */
  static async getSharingOptions(templateId: string): Promise<SharingOptions> {
    const { data } = await this.post<IBackendResponse<SharingOptions>, template>('/templates/getSharingOptions', { templateId }).then(res => res.data)
    return data
  }

  /**
   * Метод позволяет сохранять объект настроек ограничения
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/310542511/SaveSharingOptions
   * @param templateId
   * @param sharingOptions
   */
  static async saveSharingOptions(sharingOptions: SharingOptions): Promise<SharingOptions> {
    const { alreadyInstalledCard, deletedCard, subscriptionEnd } = sharingOptions
    const { data } = await this.post<IBackendResponse<SharingOptions>, savesharingOptions>('/templates/saveSharingOptions', {
       alreadyInstalledCard, deletedCard, subscriptionEnd
    })
      .then(res => res.data)
    return data
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/316145713/UploadSharingImage
   * Метод позволяет загружать изображение для настроек ограничения
   * @param imagefile
   */
  static async uploadSharingImage(imagefile: Blob): Promise<string> {
    const formData = new FormData();
    formData.append('alreadyInstalledCard', imagefile);
    const { data } = await this.post<IBackendResponse<SharingOptions>, FormData>('/templates/UploadSharingImage', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(res => res.data)
    return data
  }
}

export default SharingService
