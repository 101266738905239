const addTypeAndName = (
  item,
  type,
  isComponent = false,
  componentName = null
) => ({
  ...item,
  type,
  isComponent,
  componentName,
});

/* Дата */
const filterDateStandart = [
  { operator: 'eq', name: 'equal' },
  { operator: 'lt', name: 'before' },
  { operator: 'gt', name: 'after' },
].map((f) => addTypeAndName(f, 'date', true, 'datePicker'));


/* Дата и время  */
const filterDateTimeStandart = [
  { operator: 'eq', name: 'equal' },
  { operator: 'lt', name: 'before' },
  { operator: 'gt', name: 'after' },
].map((f) => addTypeAndName(f, 'date_time', true, 'datetimePicker'));

const filterDateStandartBetween = [
  { operator: 'between', name: 'between', type: 'date', isComponent: true, componentName: 'datePickerBetween', },
];

const filterDateTimeStandartBetween = [
  { operator: 'between', name: 'between', type: 'date_time', isComponent: true, componentName: 'datePickerBetween', },
];

const filterDatePreset = [
  { operator: 'today', name: 'today', type: 'date', isComponent: false, componentName: null },
  { operator: 'yesterday', name: 'yesterday', type: 'date', isComponent: false },
  { operator: 'thisWeek', name: 'thisWeek', type: 'date', isComponent: false },
  { operator: 'lastWeek', name: 'lastWeek', type: 'date', isComponent: false },
  { operator: 'thisMonth', name: 'thisMonth', type: 'date', isComponent: false },
  { operator: 'lastMonth', name: 'lastMonth', type: 'date', isComponent: false },
  { operator: 'thisYear', name: 'thisYear', type: 'date', isComponent: false },
  { operator: 'lastYear', name: 'lastYear', type: 'date', isComponent: false },
  { operator: 'lastDays', name: 'lastDays', type: 'date', isComponent: true, componentName: 'SelectDatePreset'},
  { operator: 'intervalDaysAgo', name: 'intervalDaysAgo', type: 'date', isComponent: true, componentName: 'numberBetween'},
]

const filterDatetimePreset = [
  { operator: 'today', name: 'today', type: 'date_time', isComponent: false, componentName: null },
  { operator: 'yesterday', name: 'yesterday', type: 'date_time', isComponent: false },
  { operator: 'thisWeek', name: 'thisWeek', type: 'date_time', isComponent: false },
  { operator: 'lastWeek', name: 'lastWeek', type: 'date_time', isComponent: false },
  { operator: 'thisMonth', name: 'thisMonth', type: 'date_time', isComponent: false },
  { operator: 'lastMonth', name: 'lastMonth', type: 'date_time', isComponent: false },
  { operator: 'thisYear', name: 'thisYear', type: 'date_time', isComponent: false },
  { operator: 'lastYear', name: 'lastYear', type: 'date_time', isComponent: false },
  { operator: 'lastDays', name: 'lastDays', type: 'date_time', isComponent: true, componentName: 'SelectDatePreset'},
  { operator: 'intervalDaysAgo', name: 'intervalDaysAgo', type: 'date_time', isComponent: true, componentName: 'numberBetween'},
]

const filterTimestampStandart = [
  { operator: 'eq', name: 'equal' },
  { operator: 'lt', name: 'before' },
  { operator: 'gt', name: 'after' },
].map((f) => addTypeAndName(f, 'timestamp', true, 'datePicker'));

const filterNumberStandart = [
  { operator: 'eq', name: 'equal' },
  { operator: 'ne', name: 'notEqual' },
  { operator: 'gt', name: 'greater' },
  { operator: 'gte', name: 'greaterOrEqual' },
  { operator: 'lt', name: 'less' },
  { operator: 'lte', name: 'lessOrEqual' },
].map((f) => addTypeAndName(f, 'number', true, 'numberFilter'));

const filterNumberBetween = [
  { operator: 'between', name: 'between', type: 'number', isComponent: true, componentName: 'numberBetween', },
];

const filterNumberMultiSelect = [
  { operator: 'in', name: 'includes', type: 'number', isComponent: true, componentName: 'multiselectNumber', isArray: true },
  { operator: 'nin', name: 'excludes', type: 'number', isComponent: true, componentName: 'multiselectNumber', isArray: true },
];

const filterNumberEmpty = [
  { operator: 'notEmpty', name: 'fill' },
  { operator: 'empty', name: 'notFill' },
].map((f) => addTypeAndName(f, 'number'));

const filterListArray = [
  { operator: 'notEmpty', name: 'fill' },
  { operator: 'empty', name: 'notFill' },
].map((f) => addTypeAndName(f, 'list'));

const filterListArrayMultiSelect = [
  { operator: 'in', name: 'includes', type: 'list', isComponent: true, componentName: 'multiselectList', isArray: true },
  { operator: 'nin', name: 'excludes', type: 'list', isComponent: true, componentName: 'multiselectList', isArray: true },
];

const filterTextMultiSelect = [
  { operator: 'in', name: 'includes', type: 'text', isComponent: true, componentName: 'multiselectText', isArray: true },
  { operator: 'nin', name: 'excludes', type: 'text', isComponent: true, componentName: 'multiselectText', isArray: true },
];

const filterTextInclude = [
  { operator: 'include', name: 'includes', type: 'text', isComponent: true, componentName: 'textFilter' },
  { operator: 'notInclude', name: 'noIncludes', type: 'text', isComponent: true, componentName: 'textFilter' },
];

const filterDevice = [
  { operator: 'gte', name: 'installed', type: 'number', },
  { operator: 'lte', name: 'notInstalled', type: 'number', },
];

const filterUtms = [
  { operator: 'notEmpty', name: 'fill', type: 'string', isComponent: false, componentName: null },
  { operator: 'empty', name: 'notFill', type: 'string', isComponent: false, componentName: null },
  { operator: 'eq', name: 'equal', type: 'string', isComponent: true, componentName: 'SelectUtms' },
];

const filterTextEmpty = [
  { operator: 'notEmpty', name: 'fill' },
  { operator: 'empty', name: 'notFill' },
].map((f) => addTypeAndName(f, 'text'));

const filterDateEmpty = [
  { operator: 'notEmpty', name: 'fill' },
  { operator: 'empty', name: 'notFill' },
].map((f) => addTypeAndName(f, 'date'));

const filterDatetimeEmpty = [
  { operator: 'notEmpty', name: 'fill' },
  { operator: 'empty', name: 'notFill' },
].map((f) => addTypeAndName(f, 'date_time'));

const filterTrueFalse = [
  { operator: 'eq', name: 'equal', type: 'bool', isComponent: true, componentName: 'BooleanSelectFilter' },
]

const filtersList = [
  ...filterListArray,
  ...filterListArrayMultiSelect,
  ...filterTimestampStandart,
  ...filterDateStandart,
  ...filterDateTimeStandart,
  ...filterDateStandartBetween,
  ...filterDateTimeStandartBetween,
  ...filterDatePreset,
  ...filterDatetimePreset,
  ...filterNumberMultiSelect,
  ...filterNumberStandart,
  ...filterNumberBetween,
  ...filterNumberEmpty,
  ...filterTextInclude,
  ...filterTextEmpty,
  ...filterTrueFalse,
  ...filterDateEmpty,
  ...filterDatetimeEmpty,
];

const componentFilterList = [
  'textFilter',
  'email',
  'numberFilter',
  'datePicker',
  'datetimePicker',
  'datePickerBetween',
  'numberBetween',
  'multiselectText',
  'multiselectNumber',
  'multiselectList',
  'BooleanSelectFilter',
  'SelectDatePreset',
  'SelectUtms'
];

const oneFilter = {
  variable: true,
  property: null,
  type: null,
  operator: null,
  value: '',
  secondValue: null,
  arrValue: [],
  isComponent: false,
  componentName: null,
  operatorsList: [],
  validSelect: null,
  validOperator: null,
  validValue: null,
  specialValue: null
};

const uniqueFilterDevice = ['countInstalledCards.wallet', 'countInstalledCards.gPay']

const checkOperatorDevice = (property) => uniqueFilterDevice.includes(property)

const getUniqueFilterDevice = () => filterDevice

const getUniqueFilterBoolean = (value) => ([{ operator: 'eq', name: value ? 'Да' : 'Нет', type: 'boolean' }])

const getOperatorsFromType = (type) => filtersList.filter((operator) => operator.type === type)

const findFromType = (type) => filtersList.find((operator) => operator.type === type);

const findFromOperator = (operator, filtersList) => filtersList.find((filter) => filter.operator === operator);

const findAngGetOperators = (type) => findFromType(type) ? getOperatorsFromType(type) : getOperatorsFromType('text')

const findPropertyAndGet = (select, property) => variable => select.find((c) => c.property === property)[variable]

const findAndSetComponent = (operator, filtersList) => {
  const findOperator = findFromOperator(operator, filtersList)
  return findOperator && checkComponentName(findOperator)
    ? findOperator.componentName
    : 'textFilter'
}

const findAndSetIsComponent = (operator, filtersList) => {
  const findOperator = findFromOperator(operator, filtersList)
  return findOperator && findOperator.hasOwnProperty('isComponent')
    ? findOperator.isComponent : false
}

const checkComponentName = findOperator => (findOperator.hasOwnProperty('componentName')
  && componentFilterList.includes(findOperator.componentName))

export {
  filtersList,
  oneFilter,
  componentFilterList,
  getOperatorsFromType,
  findFromType,
  findFromOperator,
  findAngGetOperators,
  findPropertyAndGet,
  checkOperatorDevice,
  getUniqueFilterDevice,
  findAndSetComponent,
  findAndSetIsComponent,
  getUniqueFilterBoolean,
  filterUtms
};
