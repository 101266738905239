import VeeValidate from 'vee-validate';
import validationMessagesEn from 'vee-validate/dist/locale/en';
import validationMessagesRu from 'vee-validate/dist/locale/ru';

export function useVeeValidate(Vue, i18n) {
  const registerVeeValidate = () => Vue.use(VeeValidate, {
    i18nRootKey: 'validations', // customize the root path for validation messages.
    i18n,
    dictionary: {
      en: validationMessagesEn,
      ru: validationMessagesRu
    },
    inject: true,
    fieldsBagName: 'veeFields'
  })

  return {
    registerVeeValidate
  }
}
