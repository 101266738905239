import { computed } from '@vue/composition-api';
import { useStore } from '@/composition/storeComposition.ts';
import { UserState } from '@/store/modules/user/userStore.ts';
import {
  ConfirmEmaiLParams,
  User,
  UserEdit,
  UserNotifications,
} from '@/models/User.ts';

export default function useUserStore() {
  const store = useStore();

  const userState = computed<UserState>(() => store.state.user);

  const loading = computed(() => userState.value.loadingUser);
  const loadingUserCompany = computed(() => userState.value.loadingUserCompany);
  const currentUser = computed(() => userState.value.currentUser);
  const currentUserCompanies = computed(
    () => userState.value.currentUserCompanies
  );
  const user = computed(() => userState.value.currentUser);

  const updateCurrentUser = (payload: Partial<User>) =>
    store.dispatch('user/updateCurrentUser', payload);

  const updateUserProfile = (payload: UserEdit) => {
    return store.dispatch('user/updateUserProfile', payload);
  };

  const confirmEmail = (params: ConfirmEmaiLParams) => {
    return store.dispatch('user/confirmEmail', params);
  };

  const updateNotifications = (params: UserNotifications) => {
    return store.dispatch('user/updateNotifications', params);
  };

  const rootUser = computed(() => store.getters['user/rootUser']);

  const Actions = {
    loadUser: () =>
      store.dispatch('user/getCurrentUser') as unknown as () => Promise<User>,
    logout: () => store.dispatch('user/logoutUser'),
    loadUserCompanies: () => store.dispatch('user/getCurrentUserCompanies'),
    reloadTwoAuth: () => store.dispatch('user/userTwoAuth'),
  };

  const Getters = {
    userHaveMoreOneCompany: computed(
      () => currentUserCompanies.value.length > 1
    ),
    userNotSelectedCompany: computed(
      () => !currentUser.value.selectedCompanyId
    ),
    userNotHasCompany: computed(() => currentUserCompanies.value.length === 0),
    userIsAuth: computed(() => userState.value.UserIsAuth),
  };

  return {
    rootUser,
    loading,
    currentUser,
    updateCurrentUser,
    user,
    confirmEmail,
    updateUserProfile,
    updateNotifications,
    currentUserCompanies,
    Actions,
    loadingUserCompany,
    Getters,
    userState,
  };
}
