import { axiosServer } from '@/http-common.ts';
import ApiWrap from '@/services/ApiWrap.ts';
import qs from 'qs';
import { IBackendResponse } from '@/services/types.ts';
import {
  Integration,
  IRequestGetIntegrations,
  IresponseGetIntegrations,
  ShortIntegration,
  SynchronizationData,
} from '@/models/integrations/Integration.ts';
import { IntegrationData } from '@/models/integrations/Integration.ts';

class IntegrationsService extends ApiWrap {
  /**
   * @deprecated
   * @param systemName
   */
  static async getIntegrationByName(
    systemName: string
  ): Promise<Integration | string> {
    const formData = new FormData();
    formData.append('systemName', systemName);
    const {
      data: { data },
    } = await axiosServer.post(
      '/integrations/getIntegrationBySystemName',
      formData
    );
    return data;
  }

  static async getIntegrationsNames(): Promise<Array<ShortIntegration>> {
    const {
      data: { data },
    } = await this.post<IBackendResponse<Array<ShortIntegration>>, undefined>(
      '/integrations/getIntegrationsNames'
    );
    return data;
  }

  /**
   * Возвращает список интеграций. Доступна пагинация, сортировка и поиск.
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/66879519/Get+Integrations
   *
   */
  static async getIntegrations(
    params: IRequestGetIntegrations
  ): Promise<IresponseGetIntegrations> {
    const {
      data: { data },
    } = await this.post<IBackendResponse<IresponseGetIntegrations>, string>(
      '/integrations/getIntegrations',
      qs.stringify(params)
    );
    return data;
  }

  /**
   * Сохранение интеграции
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/66912278/Save+Integration
   * @param integration
   * @param updateAll
   */
  static async saveIntegration(
    integration: Integration,
    updateAll: boolean
  ): Promise<Integration> {
    const { data } = await this.post<
      IBackendResponse<Integration>,
      { integration: Integration; updateAll: boolean }
    >(
      '/integrations/saveIntegration',
      { integration, updateAll },
      { errorSuppression: true }
    ).then((res) => res.data);
    return data;
  }

  static async getIntegrationData(companyId: string) {
    const {
      data: { data },
    } = await axiosServer.post('/companies/getIntegration', { companyId });
    return data;
  }

  static async getClientsGroups(accessToken: string) {
    const {
      data: { data },
    } = await axiosServer.post(
      '/integrations/getClientGroups',
      qs.stringify({ accessToken: accessToken })
    );
    return data;
  }

  static async getIntegrationSettings(): Promise<Integration> {
    const { data } = await this.get<IBackendResponse<Integration>>(
      '/onboarding/getIntegration'
    ).then((res) => res.data);
    return data;
  }

  /**
   * Возвращает данные о синхронизации пользователя
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/374079830/getSynchronizationData?moved=true
   */
  static async getSynchronizationData(): Promise<SynchronizationData> {
    const { data } = await this.get<IBackendResponse<SynchronizationData>>(
      '/integrations/getSynchronizationData'
    ).then((res) => res.data);
    return data;
  }

  /**
   * Позволяет получить интеграцию по выбранной компании
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/545161232/getCurrentIntegration
   */
  static async getCurrentIntegration(): Promise<Integration> {
    const { data } = await this.get<IBackendResponse<Integration>>(
      '/integrations/getCurrentIntegration'
    ).then((res) => res.data);
    return data;
  }

  /**
   * Позволяет получить интеграцию по id
   */
  static async getIntegrationBydId(id: string): Promise<Integration> {
    const { data } = await this.get<IBackendResponse<Integration>>(
      `/integrations/getIntegrationById/${id}`
    ).then((res) => res.data);
    return data;
  }

  /**
   * Позволяет удалять интeграцию по id
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/664928259/deleteIntegrationById
   * @param id
   */
  static async deleteIntegrationById(id: string): Promise<string> {
    const { data } = await this.delete<IBackendResponse<string>>(
      `/integrations/deleteIntegrationById/${id}`
    ).then((res) => res.data);
    return data;
  }

  /**
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/772898817/disableIntegration+for+all+integrations
   */
  static async disableIntegration() {
    const { data } = await this.post<IBackendResponse<string>, null>(
      '/integrations/disableIntegration'
    ).then((res) => res.data);
    return data;
  }

  /**
   * Метод позволяет мигрировать с одной интеграции на другую. 
   * https://passteam.atlassian.net/wiki/spaces/IN/pages/821329921/Migrate
   */
  static async migrate(newCrm: string) {
    const { data } = await this.post<IBackendResponse<string>>(
      '/integrations/migrate', { newCrm }
    ).then((res) => res.data);
    return data;
  }

  /**
   * Метод позволяет сохранять integrationData
   */
  static async saveIntegrationData(integrationData: IntegrationData) {
    const { data } = await this.post<IBackendResponse<IntegrationData>>(
      '/integrations/saveIntegrationData', integrationData
    ).then((res) => res.data);
    return data;
  }
}

export default IntegrationsService;
