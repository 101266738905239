// eslint-disable-next-line import/prefer-default-export
export enum IntegrationsNamesT {
  POSTER = 'Poster',
  YCLIENTS_NEW = 'YclientsNew',
  ALTEGIO = 'Altegio',
  RETAIL_CRM = 'retailCrm',
  IN_SALES = 'inSales',
  QUICK_RESTO = 'QuickResto',
  CFT = 'CFT',
  SQUARE = 'Square',
  MINDBOX = 'Mindbox',
  IIKO = 'iiko',
  YCLIENTS = 'Yclients',
  YCLIENTSV3 = 'YclientsV3',
  TERRASOFT = 'Terrasoft',
  CRM_1C = '1c',
  API_INTEGRATION = 'API',
  ORDER_INTEGRATION = 'OTHER_INTEGRATION',
  WITHOUT_CRM = 'withoutCrm',
  FUSION_POS = 'FusionPos',
}
