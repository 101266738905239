import { Company } from '@/models/company/Company.ts';

const company: Company = {
  errors: [],
  address: {
    administrativeAriaLevelOne: '',
    administrativeAriaLevelTwo: '',
    blockNumber: '',
    buildingNumber: '',
    country: '',
    locality: '',
    postalCode: '',
    roomNumber: '',
    route: '',
    streetNumber: '',
  },
  autoIncAdditionalDevices: false,
  billing: {
    countAdditionalDevices: 0,
    paymentPeriod: 'monthly',
    subscriptionEndDay: 0,
    totalSubscriptionPrice: '0.00',
    unsubscribeReason: '',
  },
  blockForm: false,
  cancelSubscription: false,
  countInstalledCards: {
    gPay: 0,
    total: 0,
    wallet: 0,
  },
  created: 0,
  domains: [],
  forbidCreateCard: false,
  forbidDownloadCard: false,
  forbidUpdateCard: false,
  hubSpotId: '',
  integration: '',
  integrationData: [],
  isSubscriptionActive: true,
  languages: [],
  multiLanguages: false,
  name: '',
  newCardsList: true,
  onboardingStep: 1,
  plan: {
    _id: '',
    name: '',
  },
  redeemedCoupons: [],
  registerForm: {
    api: false,
    countCustomers: 0,
    nameCrm: '',
    otherIntegration: false,
    selectLoyalty: false,
  },
  reserved: [],
  siteUrl: '',
  subscription: {
    status: '',
    id: '',
    billingCycleAnchor: 0,
    currentPeriodEnd: 0,
    currentPeriodStart: 0,
    customerId: '',
    quantity: 0,
    mobilePushExpiredDate: null,
    smsSenderNameExpiredDate: null,
    whiteLabelExpiredDate: null,
    yclientsIntegrationExpiredDate: null,
  },
  templates: [],
  timeZone: 'Europe/Moscow',
  updated: 0,
  users: [],
  _id: {
    $oid: '',
  },
  integrationError: false,
  countryCode: '',
  enableOnboarding: false,
  onboarding: {
    finish: false,
    steps: [],
  },
  migrationError: false,
  IQSMSInRegisterForm: false,
  teamId: '',
  useTeamId: false,
  synchronizationData: {
    synchronization: false,
    synchronizedClientCount: 0,
    notSynchronizedClientCount: 0,
    totalClient: 0,
    isAwait: false,
    migration: false,
  },
  tinkoffRecurrentPaymentsSettings: [],
};

// eslint-disable-next-line import/prefer-default-export
export { company };
