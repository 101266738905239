import { RouteConfig } from 'vue-router';

interface MetaObj {
  requiresAuth: boolean;
  rootAccess?: boolean;
}

export default {
  path: 'companies',
  component: {
    render: (h) => h('div', [h('router-view')]),
  },
  meta: {
    requiresAuth: true,
    rootAccess: true,
    defaultTitle: false,
  } as MetaObj,
  children: [
    {
      path: '/',
      alias: 'list',
      name: 'CompaniesList',
      component: () =>
        import(
          /* webpackChunkName: "companies" */ '@/views/companies/index.vue'
        ),
      meta: {
        pageTitle: 'companies.companiesList',
        defaultTitle: false,
      },
    },
    {
      path: 'list/:companyId',
      name: 'CompanyProfile',
      component: () =>
        import(
          /* webpackChunkName: "companies" */ '@/views/companies/CompanyId.vue'
        ),
      meta: {
        pageTitle: 'Common.CompanySettings',
        defaultTitle: false,
      },
      redirect: 'list/:companyId/main',
      children: [
        {
          path: 'main',
          name: 'CompanyProfileMain',
          component: () => import('@/views/companies/CompanyIdMain.vue'),
          meta: {
            pageTitle: 'Common.CompanySettings',
            defaultTitle: false,
          },
        },
        {
          path: 'onboarding',
          name: 'CompanyProfileOnboarding',
          component: () => import('@/views/companies/CompanyIdOnboarding.vue'),
          meta: {
            pageTitle: 'Common.CompanySettings',
            defaultTitle: false,
          },
        },
        {
          path: 'address',
          name: 'CompanyProfileAddress',
          component: () => import('@/views/companies/CompanyIdAddress.vue'),
          meta: {
            pageTitle: 'Common.CompanySettings',
            defaultTitle: false,
          },
        },
        {
          path: 'languages',
          name: 'CompanyProfileLanguages',
          component: () => import('@/views/companies/CompanyIdLanguages.vue'),
          meta: {
            pageTitle: 'Common.CompanySettings',
            defaultTitle: false,
          },
        },
        {
          path: 'billing',
          name: 'CompanyProfileBilling',
          component: () => import('@/views/companies/CompanyIdBilling.vue'),
          meta: {
            pageTitle: 'Common.CompanySettings',
            defaultTitle: false,
          },
        },
        {
          path: 'sms',
          name: 'CompanyProfileSms',
          component: () => import('@/views/companies/CompanyIdSms.vue'),
          meta: {
            pageTitle: 'Common.CompanySettings',
            defaultTitle: false,
          },
        },
        {
          path: 'whatsapp',
          name: 'CompanyProfileWhatsApp',
          component: () => import('@/views/companies/CompanyIdWhatsApp.vue'),
          meta: {
            pageTitle: 'Common.CompanySettings',
            defaultTitle: false,
          },
        },
        {
          path: 'other',
          name: 'CompanyProfileOther',
          component: () => import('@/views/companies/CompanyIdOther.vue'),
          meta: {
            pageTitle: 'Common.CompanySettings',
            defaultTitle: false,
          },
        },
        {
          path: 'elasticsearch',
          name: 'CompanyProfileElasticsearch',
          component: () => import('@/views/companies/CompanyElasticsearch.vue'),
          meta: {
            pageTitle: 'Common.CompanySettings',
            defaultTitle: false,
          },
        },
      ],
    },
    {
      path: 'add',
      name: 'AddCompany',
      component: () =>
        import(
          /* webpackChunkName: "companies" */ '@/views/companies/AddCompany.vue'
        ),
      meta: {
        pageTitle: 'companies.newCompany',
        defaultTitle: false,
      },
    },
  ],
} as RouteConfig;
