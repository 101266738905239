import { MongoOID } from '@/models/MongoTypes.ts';

class MongoClass {
  private idModel: MongoOID;

  constructor(_id: MongoOID = { $oid: '' }) {
    // eslint-disable-next-line no-underscore-dangle
    this.idModel = _id;
  }

  get id() {
    // eslint-disable-next-line no-underscore-dangle
    return this.idModel.$oid
  }
}

export default MongoClass
